import { Site } from "@efwwit/integration-hub-sdk/dist/nodejs/types/identity";
import { Core } from "../core";

//#region Interfaces.
export interface ProductDivision {
    product: string;
    department: string;
    divisions: string[];
}

export interface Domain {
    domain: string;
}

export interface SimpleTableItem {
    code: string;
}

export interface MetadataTableItem {
    description: string;
    code: string;
}
//#endregion

export class Metadata {
    public sites!: Site[];
    public productDivision!: ProductDivision[];
    public companyCode!: MetadataTableItem[];
    public costCenter!: MetadataTableItem[];
    public product!: MetadataTableItem[];
    public productMarket!: MetadataTableItem[];
    public salesMarket!: MetadataTableItem[];
    public accountTypes!: SimpleTableItem[];
    public integrationPlatforms!: SimpleTableItem[];
    public domains!: Domain[];

    private groupByCountryAndSort = (siteData: Site[]): Site[] => {
        const groupedByCountry: Map<string, Site[]> = new Map();

        let sortedSite: Site[];
        try {
            sortedSite = siteData?.sort((a, b) => {
                try {
                    return a.country.name.localeCompare(b.country.name);
                } catch (err) {
                    return siteData.length - 1;
                }
            })
        } catch (error) {
            sortedSite = [];
        }
        for (const site of sortedSite) {
            const countryName = site.country.name;

            if (!groupedByCountry.has(countryName)) {
                groupedByCountry.set(countryName, []);
            }
            groupedByCountry.get(countryName)?.push(site);
        }
        return Array.from(groupedByCountry.values()).flat();
    }


    private sortByCode = (metadataList: MetadataTableItem[] | SimpleTableItem[]): MetadataTableItem[] | SimpleTableItem[] => {
        return [...metadataList]?.sort((a, b) => a.code.localeCompare(b.code)) || [];
    }

    private sortDepartments = (pdList: ProductDivision[]) => {
        return [...pdList]?.sort((a, b) => a.product.localeCompare(b.product)) || [];
    }

    public retrieve = async (core: Core) => {
        this.sites = this.groupByCountryAndSort((await core.invokeIntegrationHubRequest('GET', `/metadata/organisation/sites`)).body as Site[]);
        this.productDivision = this.sortDepartments((await core.invokeIntegrationHubRequest('GET', `/metadata/organisation/product-division`)).body as ProductDivision[]);
        this.companyCode = this.sortByCode((await core.invokeIntegrationHubRequest('GET', `/metadata/finance/company-code`)).body as MetadataTableItem[]) as MetadataTableItem[];
        this.costCenter = this.sortByCode((await core.invokeIntegrationHubRequest('GET', `/metadata/finance/cost-center`)).body as MetadataTableItem[]) as MetadataTableItem[];
        this.product = this.sortByCode((await core.invokeIntegrationHubRequest('GET', `/metadata/finance/product`)).body as MetadataTableItem[]) as MetadataTableItem[];
        this.productMarket = this.sortByCode((await core.invokeIntegrationHubRequest('GET', `/metadata/finance/product-market`)).body as MetadataTableItem[]) as MetadataTableItem[];
        this.salesMarket = this.sortByCode((await core.invokeIntegrationHubRequest('GET', `/metadata/finance/sales-market`)).body as MetadataTableItem[]) as MetadataTableItem[];
        this.accountTypes = (await core.invokeIntegrationHubRequest('GET', `/metadata/organisation/account-types`)).body as SimpleTableItem[]
        this.integrationPlatforms = this.sortByCode((await core.invokeIntegrationHubRequest('GET', `/metadata/organisation/integration-platforms`)).body as SimpleTableItem[]) as SimpleTableItem[];
        this.domains = ((await core.invokeIntegrationHubRequest('GET', `/metadata/organisation/domains`)).body as Domain[]).sort((a, b) => a.domain.localeCompare(b.domain));
    }

    public static retrieveMetadata = async (core: Core): Promise<Metadata> => {
        const metadata = new Metadata();
        await metadata.retrieve(core);
        return metadata;
    }
}

