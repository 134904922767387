import * as React from 'react';
import { useColorScheme } from '@mui/joy/styles';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import { Tooltip } from '@mui/joy';
import { LightMode, NightsStay } from '@mui/icons-material';

export default function ColourSchemeToggle({
  onClick,
  sx,
  ...props
}: IconButtonProps) {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return (
      <IconButton
        size="sm"
        variant="outlined"
        color="neutral"
        {...props}
        sx={sx}
        disabled
      />
    );
  }

  const getToggleIcon = () => {
    switch (mode) {
      case "dark":
        return <LightMode />;
      case "light":
        return <NightsStay />;
      case "system":
        return <NightsStay />;
    }
  }
  return (
    <Tooltip title={`Switch to ${mode === "dark" ? "light" : "dark"} mode`} size='sm' sx={{ zIndex: 1000000, }}>
      <IconButton
        id="toggle-mode"
        size="sm"
        variant="outlined"
        color="neutral"
        {...props}
        onClick={(event) => {
          if (mode === 'light') {
            setMode('dark');
          } else {
            setMode('light');
          }
          onClick?.(event);
        }}
      >
        {getToggleIcon()}
      </IconButton>
    </Tooltip>
  );
}