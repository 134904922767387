import { FileUpload } from "@mui/icons-material";
import { Avatar, Box, Button, DialogTitle, Divider, Modal, ModalDialog } from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { useGlobalContext } from "../../Context";
import { ImageGetter } from "../../utilities/assets";


interface FileUploadModalProps {
    fileUploadModalOpen: boolean;
    setFileUploadModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAvatarImage: React.Dispatch<React.SetStateAction<string>>;
    image: string;
    userId: string;
}


export default function FileUploadModal({ fileUploadModalOpen, setFileUploadModalOpen, image, userId, setAvatarImage }: FileUploadModalProps) {
    const [avatar, setAvatar] = useState<string>(image);
    const [loading, setLoading] = useState<boolean>(false);
    const [graphImage, setGraphImage] = useState<string | ArrayBuffer | null>(null);
    const { core, setProfileImage } = useGlobalContext();
    const imageFile = useRef<File | null>(null);

    useEffect(() => { setAvatar(image) }, [image]); //get initial state after initial render

    useEffect(() => {
        const sendFile = async () => {
            if (core) {
                const imageGetter = new ImageGetter(core);
                await imageGetter.updateProfileImage(userId, graphImage)
                return
            }
        }
        if (graphImage) {
            sendFile()
                .catch(console.error);
        }
    }, [graphImage]);

    const prepareImageForGraph = () => {
        const file = imageFile.current;
        if (file) {
            try {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onloadend = () => {
                    setGraphImage(reader.result);
                }
            } catch(error) {
                console.error(error)
            }
        } else {
            console.error("No file found!");
        }
    }
        
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newImage = event.target?.files?.[0];
        if (newImage) {
            imageFile.current = newImage;
            setAvatar(URL.createObjectURL(newImage));
        }
    }

    const handleImageUpdate = async () => {
        setLoading(true);
        prepareImageForGraph();
        setLoading(false);
        setProfileImage(avatar);
    }

    return (
        <Modal open={fileUploadModalOpen} onClose={() => setFileUploadModalOpen(false)}>
            <ModalDialog variant="outlined" role="dialog">
                <DialogTitle id="fileUploadModalTitle">
                    Change your profile picture
                </DialogTitle>
                <Divider />
                <Avatar
                    size="lg"
                    src={avatar}
                    sx={{
                        '--Avatar-size': '150px',
                        margin: "auto"
                    }}
                >EF</Avatar>
                <Box id="file-upload-button"
                    sx={{
                        justifyContent: 'center',
                        display: 'flex',
                        gap: 2
                    }}>
                    <Button
                        component="label"
                        variant="outlined"
                        color="neutral"
                        startDecorator={<FileUpload />}
                    >
                        Upload a file
                        <input type="file" hidden accept="image/jpeg" onChange={handleOnChange} />
                    </Button>
                </Box>
                <Box
                    id="dialog-modal-buttons"
                    sx={{
                        justifyContent: 'center',
                        gap: 1,
                        display: 'flex'
                    }}
                >
                    <Button
                        variant="solid" color="neutral" loading={loading}
                        onClick={() => {
                            handleImageUpdate();
                            setAvatarImage(avatar);
                            setFileUploadModalOpen(false);
                        }}>Save</Button>
                    <Button
                        variant="plain" color="neutral"
                        onClick={() => {
                            setAvatar(image);
                            setAvatarImage(image);
                            setFileUploadModalOpen(false);
                        }}>Cancel</Button>
                </Box>
            </ModalDialog>
        </Modal>
    )
}