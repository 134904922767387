import { AccountInfo, IAARole } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';


export const openSidebar = () => {
  if (typeof document !== 'undefined') {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
  }
};

export const closeSidebar = () => {
  if (typeof document !== 'undefined') {
    document.documentElement.style.removeProperty('--SideNavigation-slideIn');
    document.body.style.removeProperty('overflow');
  }
};

export const toggleSidebar = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideNavigation-slideIn');
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
};

export const isLocked = (user: AccountInfo | null | undefined): boolean => {
  return !(
    user?.Basic?.integrationPlatform &&
    ["efia", "none", "opal_ef"].includes(user?.Basic?.integrationPlatform.toLowerCase())
  );
}

export const adminRoles: `${IAARole}`[] = ["globalAdmin", "userAdmin", "userSearch", "financeAdmin"];

export enum RoleSensitivity {
  /**
      * Global Administrators
  */
  globalAdmin = "high",
  /**
      * User Administrators
  */
  userAdmin = "medium",
  /**
      * Finance read only role.
  */
  financeAdmin = "finance",
  /**
      * Basic role allowing user search.
  */
  userSearch = "low",
  /**
      * Unprivileged users.
  */
  none = "none",
}

export interface UserRoles {
  sensitivityLevel: RoleSensitivity;
  canExpire: boolean;
  canExtend: boolean;
  canResetMFA: boolean;
  canSave: boolean;
  readOnlyRole: boolean;
}

export const getUserRoles = (rootUser: AccountInfo, directReports: AccountInfo[], targetAudience: AccountInfo[]): UserRoles => {
  let sensitivityLevel: RoleSensitivity = RoleSensitivity.none;
  let readOnlyRole = true;

  if (rootUser.RBAC?.iaaRole.includes("globalAdmin")) {
    sensitivityLevel = RoleSensitivity.globalAdmin;
    readOnlyRole = false;
  } else if (rootUser.RBAC?.iaaRole.includes("userAdmin")) {
    sensitivityLevel = RoleSensitivity.userAdmin;
    readOnlyRole = false;
  } else if (rootUser.RBAC?.iaaRole.includes("financeAdmin")) {
    sensitivityLevel = RoleSensitivity.financeAdmin;
  } else if (rootUser.RBAC?.iaaRole.includes("userSearch")) {
    sensitivityLevel = RoleSensitivity.userSearch;
  }
  let canExpire = true;
  let canExtend = true;
  let canResetMFA = true;
  let canSave = targetAudience.length > 1 ? false : true;


  for (const user of targetAudience) {
    if (user.userPrincipalName === rootUser.userPrincipalName) {
      canExpire = false;
      canExtend = false;
      canResetMFA = false;
      canSave = false;
      return {
        sensitivityLevel: RoleSensitivity.globalAdmin,
        canExpire,
        canExtend,
        canResetMFA,
        canSave,
        readOnlyRole: true
      }
    }
    if (directReports.filter(dr => dr.userPrincipalName === user.userPrincipalName).length > 0) {
      if (targetAudience.length === 1) {
        sensitivityLevel = RoleSensitivity.globalAdmin;
      }
      if (["efia", "none", "adp", "opal_ef"].includes((user.Basic?.integrationPlatform || "").toLowerCase())) {
        canExpire = canExpire && true;
        canExtend = canExtend && true;
        canResetMFA = canResetMFA && true;
        canSave = canSave && true;
      } else {
        if (rootUser.RBAC?.iaaRole.includes("globalAdmin")) {
          canExpire = canExpire && true;
          canExtend = canExtend && true;
          canResetMFA = canResetMFA && true;
          canSave = canSave && false;
        } else {
          canExpire = canExpire && true;
          canExtend = canExtend && false;
          canResetMFA = canResetMFA && true;
          canSave = canSave && false;
        }
      }
    } else {
      if (["efia", "none", "adp", "opal_ef"].includes((user.Basic?.integrationPlatform || "").toLowerCase())) {
        if (rootUser.RBAC?.iaaRole.includes("globalAdmin")) {
          canExpire = canExpire && true;
          canExtend = canExtend && true;
          canResetMFA = canResetMFA && true;
          canSave = canSave && true;
        } else if (rootUser.RBAC?.iaaRole.includes("userAdmin")) {
          canExpire = canExpire && true;
          canExtend = canExtend && true;
          canResetMFA = canResetMFA && true;
          canSave = canSave && true;
        } else if (rootUser.RBAC?.iaaRole.includes("financeAdmin")) {
          canExpire = canExpire && false;
          canExtend = canExtend && false;
          canResetMFA = canResetMFA && false;
          canSave = canSave && false;
        } else if (rootUser.RBAC?.iaaRole.includes("userSearch")) {
          canExpire = canExpire && false;
          canExtend = canExtend && false;
          canResetMFA = canResetMFA && false;
          canSave = canSave && false;
        } else {
          canExpire = canExpire && false;
          canExtend = canExtend && false;
          canResetMFA = canResetMFA && false;
          canSave = canSave && false;
        }
      } else {
        if (rootUser.RBAC?.iaaRole.includes("globalAdmin")) {
          canExpire = canExpire && true;
          canExtend = canExtend && true;
          canResetMFA = canResetMFA && true;
          canSave = canSave && false;
        } else if (rootUser.RBAC?.iaaRole.includes("userAdmin")) {
          canExpire = canExpire && true;
          canExtend = canExtend && false;
          canResetMFA = canResetMFA && true;
          canSave = canSave && false;
        } else if (rootUser.RBAC?.iaaRole.includes("financeAdmin")) {
          canExpire = canExpire && false;
          canExtend = canExtend && false;
          canResetMFA = canResetMFA && false;
          canSave = canSave && false;
        } else if (rootUser.RBAC?.iaaRole.includes("userSearch")) {
          canExpire = canExpire && false;
          canExtend = canExtend && false;
          canResetMFA = canResetMFA && false;
          canSave = canSave && false;
        } else {
          canExpire = canExpire && false;
          canExtend = canExtend && false;
          canResetMFA = canResetMFA && false;
          canSave = canSave && false;
        }
      }
    }
  }
  return {
    sensitivityLevel,
    canExpire,
    canExtend,
    canResetMFA,
    canSave,
    readOnlyRole
  }
}

export const isAdminForSearch = (user: AccountInfo): boolean => {
  const roles = user?.RBAC?.iaaRole;
  if (roles) {
    return roles?.some(role => adminRoles.includes(role)) || false;
  }
  return false;
}