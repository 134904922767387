import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';

import { FileUpload } from "@mui/icons-material";
import { Avatar, Box, IconButton, Skeleton, useTheme } from "@mui/joy";
import React, { useEffect, useState } from "react";
import FileUploadModal from "./FileUploadModal";

export interface FileUploadAvatarProps {
    image: string;
    profile: AccountInfo | undefined;
    children: string | JSX.Element;
}


export default function FileUploadAvatar({ image, profile, children }: FileUploadAvatarProps) {
    const [hover, setHover] = useState(false);
    const [avatarImage, setAvatarImage] = useState(image);
    const [fileUploadModalOpen, setFileUploadModalOpen] = useState<boolean>(false);
    const theme = useTheme();

    useEffect(() => { setAvatarImage(image) }, [image]); //get initial state after initial render

    const styles: { [key: string]: React.CSSProperties } = {
        uploadIcon: {
            color: 'secondary',
            fontSize: '40px'
        }
    };

    return (
        <Box>
            <IconButton
                component="span"
                style={{ display: 'flex', backgroundColor: theme.palette.mode === "dark" ? '#14202B' : 'white' }}
                onMouseEnter={() => { setHover(true) }}
                onMouseLeave={() => { setHover(false) }}
                onClick={() => {
                    setFileUploadModalOpen(true);
                    setHover(false);
                }}
            >
                {
                    hover && (
                        <div>
                            <Avatar
                                size="lg"
                                sx={{
                                    '--Avatar-size': '150px',
                                    margin: "auto"
                                }}
                            ><FileUpload
                                    style={styles.uploadIcon} />
                            </Avatar>{children}</div>)
                }
                {
                    !hover && (
                        <div>
                            <Avatar
                                size="lg"
                                src={avatarImage}
                                sx={{
                                    '--Avatar-size': '150px',
                                    margin: "auto"
                                }}
                            >EF<Skeleton loading={profile ? false : true} animation="wave" />
                            </Avatar>{children}</div>)
                }
            </IconButton>
            <FileUploadModal
                fileUploadModalOpen={fileUploadModalOpen}
                setFileUploadModalOpen={setFileUploadModalOpen}
                setAvatarImage={setAvatarImage}
                image={avatarImage}
                userId={profile ? profile.id : ""} />
        </Box>
    )
}