export const baseCreateUser = {
    personal: [
        {
            key: "Account Type",
            label: "Account Type",
            name: "accountType",
            value: " ",
            isLocked: false,
            type: "list"
        },
        {
            key: "Primary Domain",
            label: "Primary Domain",
            name: "primaryDomain",
            value: " ",
            isLocked: false,
            type: "list"
        },
        {
            key: "First Name",
            label: "First Name",
            name: "givenName",
            value: " ",
            isLocked: false,
            type: "free"
        },

        {
            key: "Last Name",
            label: "Last Name",
            name: "surname",
            value: " ",
            isLocked: false,
            type: "free"
        },
    ],
    contact: [
        {
            key: "Personal Phone",
            label: "Personal Phone",
            name: "personalPhone",
            value: " ",
            type: "free",
            isLocked: false,
        },
        {
            key: "Personal Email",
            label: "Personal Email",
            name: "personalEmail",
            value: " ",
            isLocked: false,
            type: "free"
        },
    ],
    work: [{
        key: "Job Title",
        label: "Job Title",
        name: "jobTitle",
        value: " ",
        isLocked: false,
        type: "free"
    },
    {
        key: "Department",
        label: "Department",
        name: "department",
        value: " ",
        isLocked: false,
        type: "list"
    },
    {
        key: "Manager",
        label: "Manager",
        name: "managerUpn",
        value: " ",
        isLocked: false,
        type: "list"
    },
    {
        key: "Office Location",
        label: "Office Location",
        name: "officeLocation",
        value: " ",
        isLocked: false,
        type: "list"
    },
    ],
    finance: [
        {
            key: "Product",
            label: "Product",
            name: "product",
            value: " ",
            isLocked: false,
            type: "list"
        },
        {
            key: "Product Market",
            label: "Product Market",
            name: "productMarket",
            value: " ",
            isLocked: false,
            type: "list"
        },
        {
            key: "Company",
            label: "Company",
            name: "company",
            value: " ",
            isLocked: false,
            type: "list"
        },
        {
            key: "Sales Market",
            label: "Sales Market",
            name: "salesMarket",
            value: " ",
            isLocked: false,
            type: "list"
        },
        {
            key: "Cost Center",
            label: "Cost Center",
            name: "costCenter",
            value: " ",
            isLocked: false,
            type: "list"
        }
    ],
}