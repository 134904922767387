import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { AlternateEmail, Contactless, Help, Work } from "@mui/icons-material";
import { Box, Chip, Divider, Grid, Skeleton } from "@mui/joy";
import { useHash } from "react-use";
import GenericFormInput from "./GenericFormInput";
import ListFormInput from "./ListFormInput";
import MenuFormInput from "./MenuFormInput";
import { FormInputProps } from "./constructs";

interface FormSectionProps {
    title: string;
    formItems?: any[];
    userProfile?: AccountInfo | null | undefined;
}

const getStartDecorator = (sectionName: string) => {
    switch (sectionName) {
        case "Contact":
            return <AlternateEmail />;
        case "Work":
            return <Work />
        case "Finance":
            return <Contactless />;
        default:
            return <Help />
    }
}

export default function FormSection({ title, formItems }: FormSectionProps) {
    const [hash] = useHash();

    const ctx = hash.split("/")[1];
    const getFormItem = (props: FormInputProps) => {
        ctx === "create"
        switch (props.type) {
            case "list":
                return <ListFormInput {...props}></ListFormInput>
            case "modal":
                return <MenuFormInput {...props}></MenuFormInput>
            default:
                return props.value ? <GenericFormInput {...props}></GenericFormInput> : ctx === "create" ? <GenericFormInput {...props}></GenericFormInput> : <Box><GenericFormInput {...props}></GenericFormInput><Skeleton loading={true} animation="wave"></Skeleton></Box>;
        }
    }

    return (

        <Box sx={{
            display: { xs: 'grid', sm: 'grid' },
            flexWrap: "wrap",
            gap: 2,
            pb: 3
        }}>

            <Divider role="presentation" sx={{ gridColumn: "1/-1" }}>
                <Chip size='lg' startDecorator={getStartDecorator(title)}>
                    {title}
                </Chip>
            </Divider>

            <Grid container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ flexGrow: 1 }}
            >
            </Grid>
            <Grid container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 4, sm: 6, md: 12 }}
                sx={{ flexGrow: 1 }}
            >
                {formItems?.map((item, index) => {
                    return (
                        <Grid xs={6} md={6} key={index}>
                            {getFormItem(item)}
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    );
}