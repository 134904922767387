import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { EmojiFoodBeverage, Groups, Hail, Help, Luggage, Person, PersonOff, PrecisionManufacturing, School, SearchSharp, WorkHistory } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box, Chip, Divider, Input, LinearProgress } from "@mui/joy";
import * as lookup from "country-code-lookup";
import { ReactElement, useEffect, useState } from "react";
import { useHash } from "react-use";
import { useGlobalContext } from "../../Context";
import FilterAndGroup from "../Toolbar/FilterAndGroupUsers";
import ProfileActions from "../Toolbar/ProfileActions";
import UserCard from "../UserCard";

interface SearchProps {
    inProgress: boolean;
}

interface TeamViewProps {
    users: AccountInfo[];
    overrideGrouping?: "type" | "status" | "country" | "department";
    overrideFilter?: "enabled" | "disabled" | "expiringSoon" | "none";
    children?: ReactElement;
    search?: SearchProps;
}

const getAccountTypeIcon = (accountType: string | null | undefined) => {
    switch (accountType) {
        case "STAFF":
            return <EmojiFoodBeverage />;
        case "TEACHER":
            return <School />
        case "EXTERNAL CONSULTANT":
            return <WorkHistory />;
        case "SERVICE ACCOUNT":
            return <PrecisionManufacturing />;
        case "TOUR DIRECTOR":
            return <Luggage />;
        default:
            return <Help />;
    }
}

const getStatusIcon = (status: string | null | undefined) => {
    switch (status) {
        case "Account Enabled":
            return <Person />;
        case "Account Disabled":
            return <PersonOff />;
        case "Expiring Soon":
            return <Hail />;
    }
}

const toTitleCase = (str: string) => {
    str = str.toLowerCase();
    return str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

type FilterOptions = "enabled" | "disabled" | "expiringSoon" | "none";

export default function UserViewer({ users, overrideGrouping, overrideFilter, children, search }: TeamViewProps) {
    const [filteredUsers, setFilteredUsers] = useState<AccountInfo[] | null>([]);

    const [filter, setFilter] = useState<string | null>("");
    const [grouping, setGrouping] = useState<"type" | "status" | "country" | "department">("type");
    const [toggleFilter, setToggleFilter] = useState<"enabled" | "disabled" | "expiringSoon" | "none">("none");

    const [validAccounts, setValidAccounts] = useState<AccountInfo[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<AccountInfo[]>([]);
    const { directReports } = useGlobalContext();

    // eslint-disable-next-line
    const [hash, setHash] = useHash();

    useEffect(() => {
        if (hash.includes("filter")) {
            setToggleFilter(hash.split("filter=")[1] as FilterOptions || "none")
        }
    }, [hash])

    useEffect(() => {
        if (hash.includes("people/team") && directReports) {
            setValidAccounts(directReports.sort((a, b) => a.displayName.localeCompare(b.displayName)));
        } else {
            setValidAccounts(users.filter(usr => ["STAFF", "TEACHER", "EXTERNAL CONSULTANT", "SERVICE ACCOUNT", "TOUR DIRECTOR"].includes(usr.Basic?.accountType || ""))?.sort((a, b) => a.displayName.localeCompare(b.displayName)));
        }
    }, [hash, directReports, users])

    const isExpiringSoon = (profile: AccountInfo) => {
        const diff = Math.ceil((new Date(profile?.Basic?.contractEnd || "").getTime() - new Date().getTime()) / (1000 * 3600 * 24));
        if ((diff > 0 && profile?.accountEnabled) && (diff <= 14)) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        let filteredAccounts = validAccounts;
        if (filter && filter.length > 0) {
            filteredAccounts = validAccounts.filter(user => (user.displayName.toLowerCase().includes(filter) || user.userPrincipalName.toLowerCase().includes(filter) || user.mail.toLowerCase().includes(filter)));
        }
        switch (toggleFilter) {
            case "enabled":
                filteredAccounts = filteredAccounts.filter(account => account.accountEnabled);
                break;
            case "disabled":
                filteredAccounts = filteredAccounts.filter(account => !account.accountEnabled);
                break;
            case "expiringSoon":
                filteredAccounts = filteredAccounts.filter(account => isExpiringSoon(account));
                break;
        }
        setFilteredUsers(filteredAccounts);
    }, [filter, grouping, toggleFilter, validAccounts]);

    useEffect(() => {
        if (overrideGrouping) {
            setGrouping(overrideGrouping);
        }
        if (overrideFilter) {
            setToggleFilter(overrideFilter);
        }
    }, [filter, overrideFilter, grouping, overrideGrouping]);

    let groupingArr = Array.from(new Set(validAccounts.map((usr) => {
        switch (grouping) {
            case "type":
                return usr.Basic?.accountType;
            case "status":
                return usr.accountEnabled ? isExpiringSoon(usr) ? "Expiring Soon" : "Account Enabled" : "Account Disabled";
            case "country":
                return usr.usageLocation;
            case "department":
                return usr.department;
        }
    })).values());

    // Make staff always first, if present.
    if (groupingArr.includes("STAFF")) {
        groupingArr.splice(groupingArr.indexOf("STAFF"), 1);
        groupingArr = ["STAFF", ...groupingArr];
    }

    return (
        <Box>
            {
                <Box display={"flex"} sx={{
                    justifyContent: {
                        xs: "space-between",
                    },
                    mb: 4,
                    mt: 0
                }}>
                    {children ? children : <Input
                        variant="soft"
                        placeholder="Search here..."
                        size="lg"
                        sx={{
                            width: {
                                xs: "80%",
                                lg: "40%"
                            }
                        }}
                        endDecorator={<SearchSharp />}
                        onChange={(e) => setFilter(e.target.value)}
                    ></Input>
                    }
                    {
                        selectedUsers.length > 0 ?
                            <ProfileActions isValid={true} multiSelect={true} accounts={selectedUsers} setAccounts={setSelectedUsers}></ProfileActions> :
                            <FilterAndGroup grouping={grouping} setGrouping={setGrouping} filter={toggleFilter} setFilter={setToggleFilter} />
                    }

                </Box>
            }
            {
                search && !(search?.inProgress) && users && users?.length > 0 &&
                <Box sx={{ pl: 1, mb: 2 }}>Your search returned <Chip>{users?.length || 0}</Chip> results.
                    {(toggleFilter && toggleFilter !== "none") &&
                        <Box display={"inline-block"} ml={1}>Your current filter will only show accounts that are <Chip color="primary" variant="solid">{toggleFilter === "enabled" ? "Active" : toggleFilter === "disabled" ? "Expired" : "Expiring Soon"}</Chip> Please change or clear your filters above to see all accounts.</Box>
                    }
                </Box>
            }
            {(toggleFilter && toggleFilter !== "none" && !search) &&
                <Box sx={{ pl: 1, mb: 2 }}>
                    Your current filter will only show accounts that are <Chip color="primary" variant="solid">{toggleFilter === "enabled" ? "Active" : toggleFilter === "disabled" ? "Expired" : "Expiring Soon"}</Chip> Please change or clear your filters above to see all accounts.
                </Box>
            }
            {
                search?.inProgress ? <LinearProgress color="neutral" thickness={2} sx={{ mt: 2, mb: 2 }} /> :
                    <Divider role="presentation" sx={{
                        mt: 2,
                        mb: 2
                    }}></Divider>
            }
            {filteredUsers && filteredUsers.length > 0 && groupingArr.map((groupItem) => {
                return (
                    <Box display={"flex"} mt={4}>
                        <AccordionGroup transition={"0.2s"} sx={{
                            p: 0,
                            m: 0
                        }}>
                            <Accordion defaultExpanded={true} sx={{
                                p: 0,
                                m: 0
                            }}>
                                <AccordionSummary key={`as_${groupingArr.indexOf(groupItem)}`} variant="plain">
                                    <Chip size='lg' startDecorator={
                                        grouping === "type" ? getAccountTypeIcon(groupItem) :
                                            grouping === "status" ? getStatusIcon(groupItem) :
                                                grouping === "department" ? <Groups /> :
                                                    grouping === "country" ? <img
                                                        loading="eager"
                                                        width="20"
                                                        srcSet={`https://flagcdn.com/w40/${groupItem?.toLowerCase()}.png 2x`}
                                                        src={`https://flagcdn.com/w20/${groupItem?.toLowerCase()}.png`}
                                                        alt={lookup.byIso(groupItem || "")?.country}
                                                    /> :
                                                        <Help />
                                    }>
                                        {`${groupItem && grouping === "type" ? toTitleCase(groupItem) : grouping === "country" ? lookup.byIso(groupItem || "")?.country : groupItem}`}
                                    </Chip>
                                </AccordionSummary>
                                <Box
                                    key={groupItem}
                                    sx={{
                                        padding: 0,
                                        display: 'grid',
                                        justifyContent: "space-between",
                                        gridTemplateColumns: {
                                            xs: '100%',
                                            sm: '50% 50%',
                                            lg: 'repeat(auto-fit, 32%) 32%',
                                        },

                                        mb: 4,
                                        '& > hr': {
                                            gridColumn: '1/-1',
                                        },
                                    }}
                                >
                                    {grouping === "type" && filteredUsers && filteredUsers.map((user: AccountInfo) => (
                                        user.Basic?.accountType === groupItem
                                        &&
                                        <AccordionDetails>
                                            <UserCard user={user} key={user.id} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
                                        </AccordionDetails>
                                    ))}
                                    {grouping === "country" && filteredUsers && filteredUsers.map((user: AccountInfo) => (
                                        user.usageLocation === groupItem
                                        &&
                                        <AccordionDetails>
                                            <UserCard user={user} key={user.id} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
                                        </AccordionDetails>
                                    ))}
                                    {grouping === "status" && filteredUsers && filteredUsers.map((user: AccountInfo) => (
                                        (user.accountEnabled ? isExpiringSoon(user) ? "Expiring Soon" : "Account Enabled" : "Account Disabled") === groupItem
                                        &&
                                        <AccordionDetails>
                                            <UserCard user={user} key={user.id} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
                                        </AccordionDetails>
                                    ))}
                                    {grouping === "department" && filteredUsers && filteredUsers.map((user: AccountInfo) => (
                                        user.department === groupItem
                                        &&
                                        <AccordionDetails>
                                            <UserCard user={user} key={user.id} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
                                        </AccordionDetails>
                                    ))}
                                </Box>
                            </Accordion>
                        </AccordionGroup>
                    </Box>
                )
            })}
        </Box >
    );

}