import { Close } from "@mui/icons-material";
import { Avatar, Box, TabList, Tabs, Theme } from "@mui/joy";
import Chip, { chipClasses } from '@mui/joy/Chip';
import Tab, { tabClasses } from "@mui/joy/Tab";
import { useContext, useEffect, useState } from "react";
import { useHash } from "react-use";
import { useGlobalContext } from "../../Context";
import { AccountTabsContext, SearchTabsContext } from "../../Context/TabContextProvider";
import { ImageGetter } from "../../utilities/assets";
import { TabAreaProps } from "./TabArea";

interface AccountTabsProps {
    children: React.ReactElement<any, string>[];
}

const tabTheme = (theme: Theme) => {
    return {
        '--Chip-minHeight': '20px',
        '--ListItem-minHeight': '48px',
        top: 'calc(-1 * (var(--main-paddingTop, 0px) - var(--Header-height, 0px)))',
        zIndex: 10,
        width: '100%',
        overflow: 'auto hidden',
        alignSelf: 'flex-start',
        scrollSnapType: 'inline',
        '&::after': {
            pointerEvents: 'none',
            display: { xs: 'block', sm: 'none' },
            content: '""',
            position: 'sticky',
            top: 0,
            width: 40,
            flex: 'none',
            zIndex: 1,
            right: 0,
            borderBottom: '1px solid transparent',
            backgroundClip: 'content-box',
        },
        '&::-webkit-scrollbar': {
            width: 0,
            display: 'none',
        },
        [`& .${tabClasses.root}`]: {
            '--focus-outline-offset': '-2px',
            scrollSnapAlign: 'start',
            bgcolor: 'transparent',
            flex: 'none',
            '&:hover': {
                bgcolor: 'transparent',
            },
            [`&.${tabClasses.selected}`]: {
                color: 'primary.plainColor',
                bgcolor: 'transparent',
                [`& .${chipClasses.root}`]: theme.variants.solid.primary,
            },
        },
    }
}

interface TabAvatarProps {
    id: string;
    name: string;
}

const excludedTabs = ["MyTeam", "MyAccount", "Search", "Loading", "MyApps"]; // Tabs which are excluded from being closed. 

function TabAvatar({ id, name }: TabAvatarProps) {
    const [hash] = useHash();
    const ctx = hash.split("#")[1].split("/")[1];

    const [profileImage, setProfileImage] = useState<string>();
    const { core, directReports } = useGlobalContext();
    const { tabs } = ctx === "people" ? useContext(AccountTabsContext) : useContext(SearchTabsContext);

    useEffect(() => {
        if (core) {
            new ImageGetter(core).getPicture(id, (directReports || []).filter(user => user.id === id).length > 0, false).then((image) => {
                setProfileImage(image);
            });
        }
    }, [tabs.length]);

    const getAvatarValue = () => {
        try {
            return profileImage ? null : `${name.split(" ")[0][0]} ${name.split(" ")[1][0]}`
        } catch (error) {
            return "EF"
        }
    }

    return (
        <Avatar
            size="sm"
            src={profileImage}
        >
            {getAvatarValue()}
        </Avatar>
    )
}

export default function AccountTabs({ children }: AccountTabsProps) {
    const [hash] = useHash();
    const ctx = hash.split("#")[1].split("/")[1];
    const { setCurrentTab, removeTab, currentTab, tabs } = ctx === "people" ? useContext(AccountTabsContext) : useContext(SearchTabsContext);

    return (
        <Tabs
            value={currentTab}
            sx={{ bgcolor: 'transparent' }}>
            <TabList
                sticky="top"
                variant="plain"
                sx={tabTheme}
            >
                {children.map((child, i) => {
                    const childProps = child.props as unknown as TabAreaProps;
                    return (
                        <div key={childProps.index} style={{ marginLeft: "0" }}
                            onClick={() => setCurrentTab(i)}
                            onAuxClick={(event) => {
                                if (event.button === 1) { // Closing tab if user clicks with centre mouse button.
                                    currentTab === i && tabs.length - 1 === i ? setCurrentTab(i - 1) : setCurrentTab(currentTab);
                                    removeTab(child.key as string);
                                }
                            }}>
                            <Tab value={childProps.index} key={childProps.index}>
                                {excludedTabs.includes(child.key as string) ? "" :
                                    <TabAvatar name={childProps.name} id={child.key || ""} />}
                                {
                                    excludedTabs.includes(child.key as string) ? childProps.name : <Box sx={{
                                        display: {
                                            xs: "none",
                                            sm: "block"
                                        }
                                    }}>{childProps.name}</Box>
                                }

                                {childProps.itemCount ?
                                    <Chip size="sm" variant="solid" color="primary" >
                                        {childProps.itemCount}{childProps.itemDescriptor ? " " + childProps.itemDescriptor : ""}
                                    </Chip> : ""}

                                {!excludedTabs.includes(child.key as string) &&
                                    <Close color="error"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            currentTab === i && tabs.length - 1 === i ? setCurrentTab(i - 1) : setCurrentTab(currentTab);
                                            removeTab(child.key as string);
                                        }} />}
                            </Tab>
                        </div>
                    )
                })}
            </TabList>
            <Box
                //#region Container Theme
                sx={{
                    height: 0,
                    position: 'sticky',
                    top: 'calc(48px - var(--main-paddingTop, 0px) + var(--Header-height, 0px) - (var(--_shadow-height) / 2))',
                    zIndex: 1,
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'relative',
                        zIndex: 1,
                        height: 'var(--_shadow-height)',
                    },
                }}
            //#endregion
            >
                {children}
            </Box>
        </Tabs >
    )
}