// React imports 
import { useEffect, useState } from "react";
// Component imports
import AvatarCard from "./AvatarCard";
import FormSection from "./FormSection";
// Utility imports
import { useGlobalContext } from "../../Context";
import { AccountProfileProps, FormSubmission, ProfileContent, dummyUser } from "./constructs";
// Joy imports
import { CopyAll, NewReleasesRounded, Visibility } from '@mui/icons-material';
import { Box, Button, Chip, Divider, Sheet, Snackbar, Typography } from "@mui/joy";

import { useHash } from 'react-use';
import ProfileActions from "../Toolbar/ProfileActions";
import { getUserRoles } from "../utils";
import { getProfileItems, verifyEmail, verifyPhoneNumber } from "./utils";

const convertWorkPhoneToArray = (obj: any): any => {
    if (Array.isArray(obj.workPhone)) {
        return obj;
    } else {
        const workPhone = obj.workPhone;
        obj.workPhone = obj.workPhone === "" ? undefined : [workPhone as unknown as string];
        return obj;
    }
}

export const validateForm = (form: FormSubmission) => {
    type FormElements = keyof typeof form;
    const errors: string[] = [];
    for (const a of Object.keys(form)) {
        const parsed = a as FormElements;
        switch (parsed) {
            case "personalPhone":
                form.accountType !== "EXTERNAL CONSULTANT" && !verifyPhoneNumber(form[parsed]) && errors.push(parsed)
                break;
            case "workPhone":
                form[parsed].length > 0 && !verifyPhoneNumber(form[parsed]) && errors.push(parsed);
                break;
            case "personalEmail":
                !verifyEmail(form[parsed]) && errors.push(parsed)
                break;
            default:
                form[parsed].length < 1 && errors.push(parsed)
                break;
        }
    }
    return Array.from(new Set(errors));
}

export default function AccountProfile({ userProfile, locked }: AccountProfileProps) {
    const { core, rootUser, directReports } = useGlobalContext();

    const [profileContent, setProfileContent] = useState<ProfileContent | null>(dummyUser);

    const [isSelf, setIsSelf] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [formFields, setFormFields] = useState<FormSubmission>();

    const [copyOpen, setCopyOpen] = useState<boolean>(false);

    const [hash, setHash] = useHash();

    useEffect(() => {
        if (rootUser && directReports && userProfile) {
            const userRoles = getUserRoles(rootUser, directReports, [userProfile]);
            const isDirectReport = directReports.filter(user => user.userPrincipalName === userProfile.userPrincipalName).length > 0;
            setProfileContent(getProfileItems(
                userProfile, (locked || (userRoles.readOnlyRole && !isDirectReport)), rootUser, isDirectReport ? true : false, userRoles.sensitivityLevel
            ));
        }
    }, [rootUser, userProfile]);

    useEffect(() => {
        const self = rootUser?.userPrincipalName === userProfile?.userPrincipalName;
        const rootPath = hash.replace("#", "").split("/")[1];

        setIsSelf(self);
        if (self && rootPath !== "search") {
            setHash(`#/people/me`);
        }
        const ctx = hash.split("#")[1].split("/");

        if (ctx.includes("team") || ctx.includes("me")) {
            if (!self) {
                setHash(`#/people/team/${userProfile?.id}`);
            }
        }
        else if (ctx.includes("search")) {
            if (ctx.includes("people") && !(ctx.includes("team"))) {
                return;
            } else {
                if (!self) {
                    setHash(`#/search/people/${userProfile?.id}`);
                }
            }
        } else if (ctx.includes("home")) {
            setHash("home");
        } else if (ctx.includes("security")) {
            setHash("security");
        } else {
            setHash(`#/people/me`);
        }
    }, []);

    const validateFormChanges = async (event: any) => {
        if (userProfile?.userPrincipalName && core) {
            const formData = new FormData(event);
            let formJson = Object.fromEntries(formData.entries()) as unknown as FormSubmission;

            const formErrors = validateForm(formJson);
            setIsValid(formErrors.length === 0);

            formJson = convertWorkPhoneToArray(formJson);
            setFormFields(formJson);
        }
    }

    return (
        <Box>
            {
                userProfile?.Protected?.forceLockout &&
                <Sheet color="danger" variant="soft" sx={{ p: 2, borderRadius: 10, mb: 1 }}>
                    <Typography startDecorator={<NewReleasesRounded />}>
                        This account has been placed under quarantine. While in quarantine accounts cannot be edited, enabled or extended.
                        For more information, please reach out to Information Security or raise a ticket.
                    </Typography>
                </Sheet>
            }
            {
                (!(userProfile?.Protected?.forceLockout)) &&
                <ProfileActions isValid={isValid} formData={formFields} accounts={userProfile ? [userProfile] : []}></ProfileActions>
            }
            <Box display={"flex"} sx={{
                justifyContent: {
                    xs: "space-between",
                },
                mb: 2,
                mt: 0
            }}>
            </Box>
            <Snackbar
                variant="soft"
                color="primary"
                open={copyOpen}
                onClose={() => setCopyOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                startDecorator={<CopyAll />}
                endDecorator={
                    <Button
                        onClick={() => setCopyOpen(false)}
                        size="sm"
                        variant="soft"
                        color="primary"
                    >
                        Dismiss
                    </Button>
                }
            >
                Copied to your clipboard.
            </Snackbar>
            <form
                id="profile-form"
                onChange={(event: any) => validateFormChanges(event.currentTarget)}
                onClick={(event: any) => {
                    try {
                        const genericInputValue = ((((event.target as HTMLElement)?.children[1] as HTMLElement)?.children[1] as any)?.value);
                        const complexInputValue = ((((event.target as HTMLElement)?.children[1] as HTMLElement)?.children[1] as HTMLElement)?.children[0] as any)?.value;
                        const clickValue: string | undefined = genericInputValue ? genericInputValue : complexInputValue ? complexInputValue : undefined;
                        if (clickValue) {
                            // This is where a locked input was clicked.
                            navigator.clipboard.writeText(clickValue);
                            setCopyOpen(true);
                            setTimeout(() => {
                                setCopyOpen(false);
                            }, 2000);
                        }
                    } catch (err) {
                        console.error(err);
                    }

                    const formT = event.target.form;
                    const formCT = event.currentTarget;
                    setTimeout(() => {
                        if (formT) {
                            validateFormChanges(formT);
                        }
                        if (formCT) {
                            validateFormChanges(formCT);
                        }
                    }, 100);
                }}
                onSubmit={(event: any) => {
                    event.preventDefault();
                    validateFormChanges(event.currentTarget);
                }}
            >
                <Box>
                    <Divider role="presentation" sx={{ gridColumn: "1/-1" }}>
                        <Chip size='lg' startDecorator={<Visibility />}>
                            Overview
                        </Chip>
                    </Divider>
                    <AvatarCard profile={userProfile} isSelf={isSelf}></AvatarCard>
                    {
                        profileContent?.contact && profileContent?.contact.length > 0 &&
                        <FormSection title="Contact" formItems={profileContent?.contact} />
                    }
                    {
                        profileContent?.work && profileContent?.work.length > 0 &&
                        <FormSection title="Work" formItems={profileContent?.work} />
                    }
                    {
                        profileContent?.finance && profileContent?.finance.length > 0 &&
                        <FormSection title="Finance" formItems={profileContent?.finance} />
                    }
                </Box>

                <Box
                    id='bottom-buttons'
                    sx={{
                        gridColumn: '1/-1',
                        justifyContent: 'flex-end',
                        display: 'flex',
                        gap: 1,
                        mt: 1,
                        flexFlow: {
                            sm: "row",
                            xs: "column"
                        }
                    }}
                >
                </Box>
            </form >
        </Box >
    )
}