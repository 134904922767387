import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import GlobalStyles from '@mui/joy/GlobalStyles';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';

import { Dashboard, DevicesOther, Equalizer, Flight, Forum, Microsoft, People, Person, PersonAdd, RecentActors, Search, Shield, Support } from '@mui/icons-material';
import { CircularProgress, ListItemDecorator, Skeleton } from '@mui/joy';
import { useEffect, useState } from 'react';
import { useHash } from 'react-use';
import { useGlobalContext } from '../../Context';
import ColourSchemeToggle from '../ColourSchemeToggle';
import { closeSidebar } from '../utils';
import DynamicSidebarList from './DynamicList';
import { SidebarHeading, SidebarListItem } from './constructs';

export default function Sidebar() {
    const { profile, profileImage, rootUser, securityAlerts } = useGlobalContext();
    const [selected, setSelected] = useState<string>();
    const [hash, setHash] = useHash();

    useEffect(() => {
        const rootPath = hash.replace("#", "").split("/")[1];
        closeSidebar();
        switch (rootPath) {
            case "people":
                setSelected("manage");
                break;
            case "home":
                setSelected("home");
                break;
            case "security":
                setSelected("security");
                break;
            case "search":
                setSelected("search");
                break;
            case "unauthorised":
                setSelected(undefined);
                break;
            case "create":
                setSelected("create");
                break;
            case "created":
                setSelected("create");
                break;
            case "feedback":
                setSelected(undefined);
                break;
            case "apps":
                setSelected("apps");
                break;
            case "devices":
                setSelected("devices");
                break;
            case "travel":
                setSelected("mapper");
                break;
            default:
                setSelected("home");
                break;
        }
    }, [hash]);


    const peopleList = (): SidebarListItem[] => {
        const peopleItemsList = [
            { id: 'manage', label: 'Me & My Team', path: "people", selected: selected, setSelected: setSelected, icon: <People/>},
        ];

        peopleItemsList.push({ id: 'search', label: 'Search', path: "search", selected: selected, setSelected: setSelected, icon: <Search/> })
        peopleItemsList.push({ id: 'create', label: 'Create', path: "create", selected: selected, setSelected: setSelected, icon:  <PersonAdd/>})
        return peopleItemsList;
    }

    const o365List = (): SidebarListItem[] => {
        const appItemsList = [
            { id: 'apps', label: 'Apps', path: "apps", selected: selected, setSelected: setSelected, icon: <Microsoft/> },
            { id: 'devices', label: 'Devices', path: "devices", selected: selected, setSelected: setSelected, icon: <DevicesOther/> },
            // { id: 'licensing', label: 'Licensing', path: "licensing", selected: selected, setSelected: setSelected },
        ];
        return appItemsList;
    }

    const travelList = (): SidebarListItem[] => {
        const appItemsList = [
            { id: 'mapper', label: 'Flight Mapper', path: "travel", selected: selected, setSelected: setSelected, icon: <RecentActors/> },
        ];
        return appItemsList;
    }

    const listData: SidebarHeading[] = [
        {
            label: 'Dashboard',
            icon: <Dashboard />,
            items: [
                { id: 'home', label: 'Overview', path: "home", selected: selected, setSelected: setSelected, icon: <Equalizer/> },
                { id: 'security', label: 'Account Security', path: "security", selected: selected, setSelected: setSelected, icon: <Shield/>, count: (securityAlerts && securityAlerts?.length > 0) ? securityAlerts?.length : undefined},
            ],
        },
        {
            label: 'People',
            icon: <Person />,
            items: peopleList()
        },
        {
            label: 'Microsoft 365',
            icon: <Microsoft />,
            items: o365List()
        },
    ];

    if ((rootUser?.RBAC?.iaaRole || []).includes("globalAdmin") || ((rootUser?.RBAC?.iaaRole || []) as any[]).includes("staffTravel")) {
        listData.push(
            {
                label: 'Staff Travel',
                icon: <Flight />,
                items: travelList()
            }
        )
    }

    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: {
                    xs: 'fixed',
                    md: 'sticky',
                },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 50,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 1.5,
                py: 3,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '280px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '280px',
                        },
                    },
                })}
            />

            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 1,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',

                    opacity: 'calc(var(--SideNavigation-slideIn, 0) - 0.2)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <List
                    size="sm"
                    sx={{
                        mt: 'auto',
                        flexGrow: 0,
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        '--List-gap': '8px',
                    }}
                >
                    <ListItem onClick={
                        () => {
                            setHash("/home")
                        }
                    }
                    sx={{
                        cursor: "pointer"
                    }}
                    >
                        {
                            <svg xmlns="http://www.w3.org/2000/svg" width={30} viewBox="0 0 24 24" fill="currentColor"><path d="m10.82 14.87-.58 2.75H2L4.41 6h8.24l-.56 2.7H7.53l-.34 1.66h4.45l-.56 2.74H6.62l-.36 1.76zM22 6l-.56 2.7H17.3l-.43 2.1h4l-.56 2.74h-4.02l-.84 4.08h-3.94L13.92 6z" /></svg>
                        }
                        <Typography level="title-lg">Identity & Access</Typography>
                    </ListItem>
                </List>
                <ColourSchemeToggle sx={{ ml: 'auto' }} />
            </Box>
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >

                    {/* Dynamically creating the side bar content */}

                    {listData.map((data, index) => (
                        <DynamicSidebarList key={index} {...data} />
                    ))}

                </List>

                <List
                    size="sm"
                    sx={{
                        mt: 'auto',
                        flexGrow: 0,
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        '--List-gap': '8px',
                        mb: 0,
                    }}
                >
                    <ListItem>
                        <ListItemButton>
                            <ListItemDecorator>
                                <Support sx={{
                                    fontSize: "25px"
                                }} />
                            </ListItemDecorator>
                            <ListItemButton sx={{ margin: 0, alignItems: "flex-end" }} onClick={() => {
                                window.open("https://help.ef.com/support/home", '_blank')?.focus();
                            }}>
                                <ListItemContent>
                                    Need help? Click here.
                                </ListItemContent>
                            </ListItemButton>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton>
                            <ListItemDecorator>
                                <Forum sx={{
                                    fontSize: "25px"
                                }} />
                            </ListItemDecorator>
                            <ListItemButton sx={{ margin: 0, alignItems: "flex-end" }} onClick={() => {
                                setHash("#/feedback");
                            }}>
                                <ListItemContent>
                                    Leave feedback.
                                </ListItemContent>
                            </ListItemButton>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
            <Divider />

            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <List
                    size="sm"
                    sx={{
                        mt: 'auto',
                        flexGrow: 0,
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        '--List-gap': '8px',
                    }}
                >
                    <ListItem>
                        {profileImage ?
                            <Avatar
                                variant="outlined"
                                size="sm"
                                src={profileImage}
                            />
                            : profile ?
                                <Avatar>
                                    {profile.givenName && profile.surname ? `${profile.givenName[0]}${profile.surname[0]}` : "EF"}
                                </Avatar>
                                :
                                <CircularProgress />
                        }
                        <Box sx={{ minWidth: 0, flex: 1 }}>
                            <Typography level="title-sm"><Skeleton loading={profile ? false : true}>{profile ? profile?.displayName : "Loading Something"}</Skeleton></Typography>
                            <Typography level="body-xs"><Skeleton loading={profile ? false : true}>{profile ? profile?.userPrincipalName : "loading.something@ef.com"}</Skeleton></Typography>
                        </Box>
                    </ListItem>
                </List>
            </Box>
        </Sheet>
    );
}