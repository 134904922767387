import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { SearchSharp } from "@mui/icons-material";
import { Box, Input } from "@mui/joy";
import { useEffect, useState } from "react";
import { useHash } from "react-use";
import { useGlobalContext } from "../../Context";
import { Core } from "../../utilities/core";
import { Profile } from "../../utilities/identity";
import UserViewer from "../Team/UserViewer";
import { isAdminForSearch } from "../utils";

const uuidRegex = /(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})/;

const userLookup = async (core: Core, name: string): Promise<AccountInfo[]> => {
    if (name.match(uuidRegex)) {
        const searchResult = await Profile.getUserByID(core, name);
        return searchResult;
    } else {
        const searchResult = await Profile.queryLookup(core, name);
        return searchResult;
    }
}

export default function SearchUsers() {
    const { core } = useGlobalContext();
    const { rootUser } = useGlobalContext(); // Logged In User.
    const [hash, setHash] = useHash();

    const [users, setUsers] = useState<AccountInfo[] | null>(null);

    const [searchQuery, setSearchQuery] = useState<string>(hash.split("?query=")[1] || "");
    const [searchActive, setSearchActive] = useState<boolean>(true);

    //#region Hashing 
    const updateHash = async (value: string) => {
        if (value.length > 0) {
            setHash(`#/search/?query=${value}`);
        } else {
            const splitUrl = hash.split("/");
            splitUrl.includes("people") ? null : setHash(`#/search`);
        }
    }
    useEffect(() => {
        const searchTimeout = setTimeout(() => {
            updateHash(searchQuery);
        }, 500);
        return () => clearTimeout(searchTimeout);
    }, [searchQuery]);

    useEffect(() => {
        const cachedQuery = localStorage.getItem(`search_query`);
        const cachedResult = localStorage.getItem("search_result");

        const ctx = hash.split("#")[1].split("/");
        if (ctx.includes("search") && !(searchQuery.length > 0) && cachedQuery && cachedResult) {
            setSearchQuery(cachedQuery);
            setUsers(JSON.parse(cachedResult));
            updateHash(cachedQuery);
        }

        if (core) {
            const ctx = hash.split("#")[1].split("/");
            const isAdmin = isAdminForSearch(rootUser as AccountInfo);
            if (!isAdmin) {
                setHash("#/search");
            } else {
                if (searchQuery) {
                    updateHash(searchQuery);
                } else if (ctx.includes("people")) {
                    setSearchActive(true);
                    return;
                }
                else {
                    setHash("#/search");
                }
            }
        }
    }, [core]);

    //#endregion
    useEffect(() => {
        setSearchActive(true);
        const query = hash.split("?query=")[1];
        query ? setSearchQuery(decodeURI(query)) : setSearchQuery("");
        if (core && query) {
            userLookup(core, decodeURI(query)).then((searchResult) => {
                setSearchActive(false);
                setUsers(searchResult);
                localStorage.setItem(`search_result`, JSON.stringify(searchResult));
                localStorage.setItem(`search_query`, decodeURI(query));
            });
        } else {
            setUsers([]);
            setSearchActive(false);
        }
    }, [hash, core]);

    return (
        <Box sx={{
            flex: 1,
            p: 0,
            maxWidth: 1200,
            width: '100%',
            mx: 'auto'
        }}>
            {
                core &&
                <UserViewer users={users || []} search={{
                    inProgress: searchActive
                }}>
                    <Input
                        placeholder="Search here..."
                        variant="soft"
                        size="lg"
                        sx={{
                            width: {
                                xs: "80%",
                                lg: "40%"
                            }
                        }}
                        value={searchQuery}
                        endDecorator={<SearchSharp />}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    ></Input>
                </UserViewer>
            }
        </Box>
    )
}