import { datadogRum } from '@datadog/browser-rum';
import { MoreVert, People } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import { useHash } from "react-use";
import { useGlobalContext } from "../../Context";
import { AccountTabsContext } from "../../Context/TabContextProvider";
import AccountProfile from "../AccountProfile";
import Team from "../Team";
import { toggleSidebar } from '../utils/index';
import AccountTabs from "./AccountTabs";
import Header from "./Header";
import TabArea from "./TabArea";

export default function Home() {
    // eslint-disable-next-line
    const [hash, setHash] = useHash();
    const { rootUser, profile, directReports } = useGlobalContext();
    const { addTab, setCurrentTab, tabs, removeTab } = useContext(AccountTabsContext);
    const hasParts = hash.replace("#", "").split("/");
    const openProfile = directReports?.filter(user => user.id === hasParts[3])[0];
    const [johnEnabled, setJohnEnabled] = useState<boolean>(false);

    // Adding dummy tab for loading 
    useEffect(() => {
        if (!rootUser) {
            addTab("My Account", "Loading", <AccountProfile userProfile={undefined} locked={true} />);
        }
    }, []);

    useEffect(() => {
        if (rootUser) {
            removeTab("Loading"); // Removing loading tab once user has been signed in.
            addTab("My Account", "MyAccount", <AccountProfile userProfile={rootUser} locked={true} />);
            setJohnEnabled(true);
            if (directReports && directReports.length > 0) {
                addTab("My Team", "MyTeam", <Team />, null, directReports.length);
            }
            if (hasParts[1] === "people" && hasParts[2] === "team" && hasParts.length === 4 && !hasParts[3]?.includes("filter")) {
                if (openProfile) {
                    addTab(openProfile.displayName, openProfile.id, null, openProfile);
                    setCurrentTab(2);
                }
            }
            if (profile && hasParts[1] === "people" && hasParts[2] === "team" && (hasParts.length === 3 || hasParts[3]?.includes("filter"))) {
                setCurrentTab(1);
            }
        }
    }, [rootUser]);

    useEffect(() => {
        if (johnEnabled && tabs.length === 0) {
            datadogRum.startView({
                name: "john",
                service: 'identity-&-access'
            })
        }
    }, [johnEnabled, tabs])

    return (
        <Box
            sx={{
                flex: 1,
                pl: 2,
                pr: 2,
                pt: 0,
                maxWidth: 1200,
                width: '100%',
                mx: 'auto',
            }}>
            <Header icon={<People />} title='Account Management'></Header>
            <Typography mb={2}>
                Here you'll see information about your account as well as that of your direct reports if you are a manager.
            </Typography>
            <IconButton
                sx={{
                    display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                    },
                    position: "absolute",
                    top: 0,
                    right: 0,
                    pt: 0.5,
                    m: 2,
                    zIndex: 5
                }}
                onClick={() => toggleSidebar()}
                color="neutral"
            >
                <MoreVert sx={{ color: "white" }} />
            </IconButton>
            {
                (!johnEnabled || tabs.length > 0) ? (
                    <AccountTabs>
                        {tabs ? tabs.map((tab, index) => (
                            <TabArea
                                index={index}
                                name={tab.name}
                                itemCount={tab.itemCount}
                                key={tab.key}
                            >
                                {tab.component}
                            </TabArea>
                        )) : []}
                    </AccountTabs>
                ) : (
                    johnEnabled && rootUser && profile && directReports && (
                        <Box sx={{
                            backgroundImage: 'url("travolta.gif")',
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100%",
                            width: "100%",
                            height: "100%"
                        }}>
                            <Typography>Well done, you've found our easter egg!</Typography>
                            <Button sx={{ mt: 2 }} onClick={() => {
                                setHash("/");
                            }}>Go back to reality</Button>
                        </Box>
                    )
                )
            }
        </Box >
    )
}