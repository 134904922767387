import { AutoAwesomeMosaic, CheckCircle, DoNotDisturb, FilterList, FilterListOff, Groups, Hail, Person, Public, RemoveCircle } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Dropdown, ListDivider, Menu, MenuButton, MenuItem, Tooltip } from "@mui/joy";
import { Dispatch, SetStateAction } from "react";

interface FilterAndGroupProps {
    grouping: string;
    setGrouping: Dispatch<SetStateAction<"type" | "status" | "country" | "department">>;
    filter: string;
    setFilter: Dispatch<SetStateAction<"enabled" | "disabled" | "expiringSoon" | "none">>;
}
export default function FilterAndGroup({ grouping, setGrouping, filter, setFilter }: FilterAndGroupProps) {
    return (
        <Box sx={{
            alignContent: "center",
            justifyContent: {
                lg: "space-between",
            },
            display: "flex"
        }}>
            <Dropdown>
                <MenuButton color="primary" variant="solid" sx={{
                    ml: 1,
                    display: {
                        xs: "flex",
                        lg: "none"
                    }
                }}>
                    <FilterList />
                </MenuButton>
                <Menu>
                    <Box margin={1}>Filter by</Box>
                    <ListDivider />
                    <MenuItem variant={filter === "enabled" ? "solid" : "plain"} onClick={() => { setFilter("enabled") }}><CheckCircle /> Enabled</MenuItem>
                    <MenuItem variant={filter === "disabled" ? "solid" : "plain"} onClick={() => { setFilter("disabled") }}><Hail /> Expiring Soon</MenuItem>
                    <MenuItem variant={filter === "disabled" ? "solid" : "plain"} onClick={() => { setFilter("disabled") }}><RemoveCircle /> Disabled</MenuItem>
                    <MenuItem variant={filter === "none" ? "solid" : "plain"} onClick={() => { setFilter("none") }}><FilterListOff /> No Filter</MenuItem>
                </Menu>
            </Dropdown>

            <Dropdown>
                <MenuButton color="primary" variant="solid" sx={{
                    ml: 1,
                    display: {
                        xs: "flex",
                        lg: "none"
                    }
                }}>
                    <AutoAwesomeMosaic/>
                </MenuButton>
                <Menu>
                    <Box margin={2} sx={{
                        display: "flex",
                        alignContent: "space-around"
                    }}>Group by</Box>
                    <ListDivider />
                    <MenuItem variant={grouping === "type" ? "solid" : "plain"} onClick={() => { setGrouping("type") }}><Person /> Account Type</MenuItem>
                    <MenuItem variant={grouping === "department" ? "solid" : "plain"} onClick={() => { setGrouping("department") }}><Groups /> Department</MenuItem>
                    <MenuItem variant={grouping === "status" ? "solid" : "plain"} onClick={() => { setGrouping("status") }}><DoNotDisturb /> Status</MenuItem>
                    <MenuItem variant={grouping === "country" ? "solid" : "plain"} onClick={() => { setGrouping("country") }}><Public /> Country</MenuItem>
                </Menu>
            </Dropdown>
            <Box display={"flex"} sx={{
                display: {
                    xs: "none",
                    lg: "flex"
                }
            }}>
                <ButtonGroup variant="soft" aria-label="tooltip button group" sx={{
                    "--ButtonGroup-separatorColor": "var(--joy-palette-primary)",
                    mr: 2
                }}>
                    <Button key={"btn_text"} disabled color="neutral">Filter by</Button>
                    <Tooltip arrow title="Enabled">
                        <Button key={"btn_status"} color={filter === "enabled" ? "primary" : "neutral"} onClick={() => { setFilter("enabled") }}><CheckCircle /></Button>
                    </Tooltip>
                    <Tooltip arrow title="Expiring Soon">
                        <Button key={"btn_status"} color={filter === "expiringSoon" ? "primary" : "neutral"} onClick={() => { setFilter("expiringSoon") }}><Hail /></Button>
                    </Tooltip>
                    <Tooltip arrow title="Disabled">
                        <Button key={"btn_status"} color={filter === "disabled" ? "primary" : "neutral"} onClick={() => { setFilter("disabled") }}><RemoveCircle /></Button>
                    </Tooltip>
                    <Tooltip arrow title="No Filter">
                        <Button key={"btn_status"} color={filter === "none" ? "primary" : "neutral"} onClick={() => { setFilter("none") }}><FilterListOff /></Button>
                    </Tooltip>
                </ButtonGroup>

                <ButtonGroup variant="soft" aria-label="tooltip button group" sx={{
                    "--ButtonGroup-separatorColor": "var(--joy-palette-primary)"
                }}>
                    <Button key={"btn_text"} disabled color="neutral">Group by</Button>
                    <Tooltip arrow title="Account Type">
                        <Button key={"btn_type"} color={grouping === "type" ? "primary" : "neutral"} onClick={() => { setGrouping("type") }}><Person /></Button>
                    </Tooltip>
                    <Tooltip arrow title="Department">
                        <Button key={"btn_department"} color={grouping === "department" ? "primary" : "neutral"} onClick={() => { setGrouping("department") }}><Groups /></Button>
                    </Tooltip>
                    <Tooltip arrow title="Status">
                        <Button key={"btn_status"} color={grouping === "status" ? "primary" : "neutral"} onClick={() => { setGrouping("status") }}><DoNotDisturb /></Button>
                    </Tooltip>
                    <Tooltip arrow title="Country">
                        <Button key={"btn_country"} color={grouping === "country" ? "primary" : "neutral"} onClick={() => { setGrouping("country") }}><Public /></Button>
                    </Tooltip>
                </ButtonGroup>
            </Box>
        </Box>
    )
}