import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { MoreVert, SearchRounded } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import { useHash } from "react-use";
import { useGlobalContext } from "../../Context";
import { SearchTabsContext } from "../../Context/TabContextProvider";
import { Core } from "../../utilities/core";
import { Profile } from "../../utilities/identity";
import AccountProfile from "../AccountProfile";
import AccountTabs from "../Home/AccountTabs";
import Header from "../Home/Header";
import TabArea from "../Home/TabArea";
import { UnauthorisedSearch } from "../Unauthorised";
import { isAdminForSearch, isLocked, toggleSidebar } from "../utils";
import SearchUsers from "./SearchUsers";


export default function Search() {
    const { addTab, tabs, setCurrentTab } = useContext(SearchTabsContext);
    const { rootUser, core } = useGlobalContext(); // Logged In User.
    const [hash] = useHash();
    const [isSearchAdmin, setIsSearchAdmin] = useState<boolean>(false)

    useEffect(() => {
        addTab("Search", "Search", <SearchUsers />);
        setIsSearchAdmin(isAdminForSearch(rootUser as AccountInfo));
        const splitUrl = hash.split("/");
        if (splitUrl.includes("people")) {
            const user = splitUrl[splitUrl.indexOf("people") + 1];
            if (core) {
                openAccountFromURL(user);
            }
        }
    }, [core]);

    // Function to retrieve account info for a given id or upn
    const getProfile = async (userId: string): Promise<AccountInfo> => {
        const profileInstance = new Profile(core as Core, undefined, userId);
        await profileInstance.retrieve();
        return profileInstance.profile;
    };

    const openAccountFromURL = (id: string) => {
        getProfile(id).then((userProfile) => {
            if ("id" in userProfile) {
                addTab(userProfile.displayName, userProfile.id, <AccountProfile locked={isLocked(userProfile)} userProfile={userProfile} />);
                setCurrentTab(1);
            } else {
                setTimeout(() => {
                    openAccountFromURL(id);
                }, 10);
            }
        });
    };



    return (
        isSearchAdmin || !rootUser ?
            <Box
                sx={{
                    flex: 1,
                    pl: 2,
                    pr: 2,
                    pt: 0,
                    maxWidth: 1200,
                    width: '100%',
                    mx: 'auto',
                }}>
                <Header icon={<SearchRounded />} title='Search' />
                <Typography mb={2}>
                    You can use this view to search for employee accounts and view them, depending on your role you will see more or less information shown.
                </Typography>
                <IconButton
                    sx={{
                        display: {
                            xs: "block",
                            sm: "block",
                            md: "none",
                            lg: "none",
                        },
                        position: "absolute",
                        top: 0,
                        right: 0,
                        pt: 0.5,
                        m: 2,
                        zIndex: 5
                    }}
                    onClick={() => toggleSidebar()}
                    color="neutral"
                >
                    <MoreVert sx={{ color: "white" }} />
                </IconButton>
                <AccountTabs>
                    {tabs ? tabs.map((tab, index) => (
                        <TabArea
                            index={index}
                            name={tab.name}
                            itemCount={tab.itemCount}
                            key={tab.key}
                        >
                            {tab.component}
                        </TabArea>
                    )) : []}
                </AccountTabs>
            </Box> :
            <Box
                sx={{
                    flex: 1,
                    pl: 2,
                    pr: 2,
                    pt: 0,
                    maxWidth: 1200,
                    width: '100%',
                    mx: 'auto',
                }}>
                <Header icon={<SearchRounded />} title='Search' />
                <IconButton
                    sx={{
                        display: {
                            xs: "block",
                            sm: "block",
                            md: "none",
                            lg: "none",
                        },
                        position: "absolute",
                        top: 0,
                        right: 0,
                        pt: 0.5,
                        m: 2,
                        zIndex: 5
                    }}
                    onClick={() => toggleSidebar()}
                    color="neutral"
                >
                    <MoreVert sx={{ color: "white" }} />
                </IconButton>
                <UnauthorisedSearch />
            </Box>
    )
}