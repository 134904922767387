import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { Avatar, Checkbox, Grid } from '@mui/joy';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useHash } from 'react-use';
import { useGlobalContext } from '../../Context';
import { AccountTabsContext, SearchTabsContext } from '../../Context/TabContextProvider';
import { ImageGetter } from '../../utilities/assets';
import { getExpiryDateChip } from '../LifecycleChip';

type UserCardProps = {
    user: AccountInfo;
    selectedUsers: AccountInfo[];
    setSelectedUsers: Dispatch<SetStateAction<AccountInfo[]>>;
}

export default function UserCard({ user, selectedUsers, setSelectedUsers }: UserCardProps) {
    // eslint-disable-next-line
    const [hash, setHash] = useHash();
    const { core, directReports } = useGlobalContext();
    const [profileImage, setProfileImage] = useState<string | null>(null);
    const [selected, setSelected] = useState<boolean>(false);

    const ctx = hash.split("#")[1].split("/")[1];
    const { addTab, setCurrentTab, tabs, findTabIndexByKey } = ctx === "people" ? useContext(AccountTabsContext) : useContext(SearchTabsContext);

    useEffect(() => {
        getProfileImage();
    }, [user, core]);

    useEffect(() => {
        const currentlySelected = [...selectedUsers];
        if (!selected && currentlySelected.includes(user)) {
            currentlySelected.splice(currentlySelected.indexOf(user), 1)
        } else if (selected && !currentlySelected.includes(user)) {
            currentlySelected.push(user);
        }
        setSelectedUsers(currentlySelected);
    }, [selected]);

    useEffect(() => {
        if ((selectedUsers.filter(selectedUser => selectedUser.id === user.id)).length === 0) {
            setSelected(false);
        }
    }, [selectedUsers])

    const getProfileImage = async () => {
        if (user && core) {
            new ImageGetter(core).getPicture(user.id, (directReports || []).filter(profile => profile.id === user.id).length > 0, false).then((image) => {
                setProfileImage(image);
            });
        }
    }
    return (
        <Grid
            key={user.id}
            paddingTop={2}
            spacing={2}
        >
            <Card
                size='md'
                sx={{
                    width: "100%",
                    transition: "transform .2s",
                    ":hover": {
                        transform: "scale(1.03)"
                    },
                    cursor: "pointer"
                }}
                variant='soft'
            >
                <Checkbox checked={selected} onChange={
                    () => {
                        selected ? setSelected(false) : setSelected(true);
                    }
                }></Checkbox>
                <CardContent sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    ":hover": {
                        cursor: "pointer"
                    }
                }}
                    onClick={() => {
                        setHash(ctx === "people" ? `#/people/team/${user.id}` : `#/search/people/${user.id}`);
                        const tabIndex = findTabIndexByKey(tabs, user.id);
                        if (tabIndex === -1) {
                            addTab(
                                user.displayName,
                                user.id,
                                null,
                                user
                            );
                            setCurrentTab(tabs.length);
                        } else {
                            setCurrentTab(tabIndex);
                        }
                    }}>
                    {profileImage ?
                        <Avatar size='lg' src={profileImage} sx={{ '--Avatar-size': '8rem' }} />
                        : <Avatar color='danger' sx={{ '--Avatar-size': '8rem' }} >
                            {user.givenName && user.surname ? `${user.givenName[0]}${user.surname[0]}` : "EF"}
                        </Avatar>}
                    {
                        getExpiryDateChip(user)
                    }
                    <Typography noWrap level="title-lg">
                        {user.displayName}
                    </Typography>
                    <Typography level="body-sm" noWrap sx={{ maxWidth: '28ch' }}>
                        {user.userPrincipalName}
                    </Typography>

                    <br />
                    <Typography noWrap={true} level="body-sm" sx={{ maxWidth: '30ch' }}>
                        {user.jobTitle ? user.jobTitle : "No Job Title"}
                    </Typography>
                    <Typography level="body-sm" noWrap sx={{ maxWidth: '24ch', minWidth: "24ch", maxHeight: "20ch", minHeight: "5ch" }}>
                        {user.department ? user.department : "No Department"}
                    </Typography>
                </CardContent>
            </Card>
        </Grid >
    )
}