import { BugReport, Check, FeedbackRounded, PlaylistAddCheckCircleRounded, ViewCarousel } from "@mui/icons-material";
import { Box, Button, Divider, FormLabel, Input, Radio, RadioGroup, Sheet, Snackbar, Textarea, Typography, radioClasses } from "@mui/joy";
import { useState } from "react";
import { useGlobalContext } from "../../Context";
import Header from "../Home/Header";

export default function Feedback() {
    const valueMapping = {
        "General Feedback": "feedback",
        "Bug Report": "bug",
        "Feature Request": "enhancement"
    } as const;

    type ValueType = keyof typeof valueMapping;

    const [value, setValue] = useState<ValueType>();
    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>();
    const { core, rootUser } = useGlobalContext();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);


    const createGithubIssue = () => {
        if (title && description && value) {
            setLoading(true);
            core?.invokeIntegrationHubRequest("POST", "/system/github/identity-and-access/issues", {
                "title": title,
                "body": `${description} \nSubmitted by ${rootUser?.userPrincipalName}.`,
                "labels": ["from user", valueMapping[value]]
                // eslint-disable-next-line
            }).then((response) => {
                setOpen(true);
                setTitle("");
                setDescription("");
                setLoading(false);
            });

        }
    }

    return (
        <Box
            sx={{
                flex: 1,
                pl: 2,
                pr: 2,
                pt: 0,
                maxWidth: 1200,
                width: '100%',
                mx: 'auto',
            }}>

            <Header icon={<FeedbackRounded />} title="Leave us feedback" />

            <Box>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    createGithubIssue();
                }}>
                    <Typography mt={1} level="body-md">
                        We would love to hear from you, please let us know if you have any feedback, wish to request a new feature or want to log a bug report.
                    </Typography>
                    <Box mt={4}>
                        <Divider role="presentation" sx={{
                            mt: 2,
                            mb: 2,
                            gridColumn: "1/-1"
                        }} />
                        <Typography mb={1.5}>Please select the type of feedback you wish to leave.</Typography>
                        <RadioGroup
                            onChange={(ev) => {
                                setValue(ev.target.value as ValueType)
                            }}
                            aria-label="platform"
                            defaultValue="Website"
                            overlay
                            name="platform"
                            sx={{
                                flexDirection: 'row',
                                gap: 2,
                                [`& .${radioClasses.checked}`]: {
                                    [`& .${radioClasses.action}`]: {
                                        inset: -1,
                                        border: '3px solid',
                                        borderColor: 'primary.500',
                                    },
                                },
                                [`& .${radioClasses.radio}`]: {
                                    display: 'contents',
                                    '& > svg': {
                                        zIndex: 2,
                                        position: 'absolute',
                                        top: '-8px',
                                        right: '-8px',
                                        bgcolor: 'background.surface',
                                        borderRadius: '50%',
                                    },
                                },
                            }}>
                            {['General Feedback', 'Feature Request', 'Bug Report'].map(
                                (item) => (

                                    <Sheet
                                        key={item}
                                        variant="outlined"
                                        sx={{
                                            borderRadius: 'md',
                                            boxShadow: 'sm',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            gap: 1.5,
                                            p: 1,
                                            minWidth: 120,
                                        }}

                                    >
                                        {item === "General Feedback" ? <FeedbackRounded /> : item === "Feature Request" ? <ViewCarousel /> : <BugReport />}
                                        <Radio id={item} value={item} checkedIcon={<Check ></Check>} />
                                        <FormLabel>{item}</FormLabel>
                                    </Sheet>
                                ),
                            )}
                        </RadioGroup>
                        <Divider key={0} role="presentation" sx={{
                            mt: 2,
                            mb: 2,
                            gridColumn: "1/-1"
                        }} />
                        <FormLabel>
                            <Typography level="title-lg" mb={1.5} mt={1.5}>Title</Typography>
                        </FormLabel>
                        <Input value={title} required onChange={(ev) => setTitle(ev.target.value)} />
                        <Typography level="title-lg" mb={1.5} mt={1.5}>Description</Typography>
                        <Textarea value={description} required minRows={4} onChange={(e) => {
                            setDescription(e.currentTarget.value);
                        }} />
                        <Typography mt={2} color="danger" level="body-md">
                            Please do not use this form for support requests, any account specific issues as well as requests for access will be discarded, please use the help button for this instead.
                        </Typography>
                        <Box
                            id='bottom-buttons'
                            sx={{
                                gridColumn: '1/-1',
                                justifyContent: 'flex-end',
                                display: 'flex',
                                gap: 1,
                                paddingTop: 1
                            }}
                        >

                            <Button
                                loading={loading}
                                type="submit"
                                sx={{
                                    borderRadius: "0px",
                                }} size="sm" >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </form>
                {open &&
                    <Snackbar
                        autoHideDuration={10000}

                        variant="soft"
                        color="success"
                        open={open}
                        onClose={() => setOpen(false)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        startDecorator={<PlaylistAddCheckCircleRounded />}
                        endDecorator={
                            <Button
                                onClick={() => setOpen(false)}
                                size="sm"
                                variant="soft"
                                color="success"
                            >
                                Dismiss
                            </Button>
                        }
                    >
                        {value} submitted, thank you for your feedback!
                    </Snackbar>}
            </Box>
        </Box >
    )
}