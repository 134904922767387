import { Check } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalDialog, Typography } from "@mui/joy";
import { useEffectOnce, useHash } from "react-use";

export function CompletedUser() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hash, setHash] = useHash();


    useEffectOnce(() => {
        setHash("#/created");
    });


    return (
        <Modal open={true} >
            <ModalDialog color='primary' role="dialog" >
                <DialogTitle >
                    <Check />
                    User Successfully Created
                </DialogTitle>
                <Divider />
                <DialogContent>

                    <Typography
                        pt={3}
                        level="body-md"
                    >
                        Do you wish to create another account?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setHash("#/create/people")}
                        variant="plain" color="primary">
                        Create Another Account
                    </Button>
                    <Button variant="plain" color="neutral" onClick={() => setHash(`#/people/me`)} >
                        Done
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal >
    )
}