import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { createContext, useState } from 'react';
import AccountProfile from '../components/AccountProfile';
import { isLocked } from '../components/utils';

interface Tab {
    name: string;
    key: string;
    itemCount: number;
    component: JSX.Element;
}


export type TabContext = {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    tabs: Tab[];
    setTabs: (tabs: Tab[]) => void;
    addTab: (name: string, key: string, element?: any, user?: AccountInfo | null, count?: number) => void;
    removeTab: (tab: string) => void;
    findTabIndexByKey: (tabs: Tab[], key: string) => number;
}


export const AccountTabsContext = createContext<TabContext>({
    currentTab: 0,
    // eslint-disable-next-line
    setCurrentTab: (tab: number) => { },
    tabs: [],
    // eslint-disable-next-line
    setTabs: (tabs: Tab[]) => { },
    // eslint-disable-next-line
    addTab: (name: string, key: string, element?: any, user?: AccountInfo | null, count?: number) => { },
    // eslint-disable-next-line
    removeTab: (tab: string) => { },
    // eslint-disable-next-line
    findTabIndexByKey: (tabs: Tab[], key: string) => 0
});

type ContextProps = {
    children?: string | JSX.Element | JSX.Element[];
}

export function AccountTabsProvider({ children }: ContextProps) {
    const [tabsList, setTabsList] = useState<any[]>([]);
    const [currentTab, setCurrentTab] = useState(0);

    const addTab = (name: string, key: string, element?: any, user?: AccountInfo | null, count?: number) => {
        const newTab = {
            name,
            itemCount: count ? count : 0,
            key,
            component: element ? element : <AccountProfile userProfile={user} locked={isLocked(user)} />
        };
        setTabsList((prevTabs) => {
            if (prevTabs.some((tab) => tab.key === key)) {
                return [...prevTabs]
            } else {
                return [...prevTabs, newTab]
            }
        });
    };

    const closeTab = (keyToRemove: string) => {
        setTabsList((prevTabs) => prevTabs.filter(tab => tab.key !== keyToRemove));
    };

    const updateCurrentTab = (tab: number) => {
        setCurrentTab(tab);
    }

    const findTabIndexByKey = (tabs: Tab[], key: string): number => {
        return tabs.findIndex(tab => tab.key === key);
    }

    return (
        <AccountTabsContext.Provider value={{
            addTab, currentTab, removeTab: closeTab, setCurrentTab: updateCurrentTab, tabs: tabsList, setTabs: setTabsList, findTabIndexByKey
        }}>
            {children}
        </AccountTabsContext.Provider>
    );
}



export const SearchTabsContext = createContext<TabContext>({
    currentTab: 0,
    // eslint-disable-next-line
    setCurrentTab: (tab: number) => { },
    tabs: [],
    // eslint-disable-next-line
    setTabs: (tabs: Tab[]) => { },
    // eslint-disable-next-line
    addTab: (name: string, key: string, element?: any, user?: AccountInfo | null, count?: number) => { },
    // eslint-disable-next-line
    removeTab: (tab: string) => { },
    // eslint-disable-next-line
    findTabIndexByKey: (tabs: Tab[], key: string) => 0
});


export function SearchTabsProvider({ children }: ContextProps) {
    const [tabsList, setTabsList] = useState<any[]>([]);
    const [currentTab, setCurrentTab] = useState(0);

    const addTab = (name: string, key: string, element?: any, user?: AccountInfo | null, count?: number) => {
        const newTab = {
            name,
            itemCount: count ? count : 0,
            key,
            component: element ? element : <AccountProfile userProfile={user} locked={isLocked(user)} />
        };
        setTabsList((prevTabs) => {
            if (prevTabs.some((tab) => tab.key === key)) {
                return [...prevTabs]
            } else {
                return [...prevTabs, newTab]
            }
        });
    };

    const closeTab = (keyToRemove: string) => {
        setTabsList((prevTabs) => prevTabs.filter(tab => tab.key !== keyToRemove));
    };

    const updateCurrentTab = (tab: number) => {
        setCurrentTab(tab);
    }

    const findTabIndexByKey = (tabs: Tab[], key: string): number => {
        return tabs.findIndex(tab => tab.key === key);
    }

    return (
        <SearchTabsContext.Provider value={{
            addTab, currentTab, removeTab: closeTab, setCurrentTab: updateCurrentTab, tabs: tabsList, setTabs: setTabsList, findTabIndexByKey
        }}>
            {children}
        </SearchTabsContext.Provider>
    );
}