import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { Add, Delete, Edit } from '@mui/icons-material';
import { Box, Button, DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalDialog, Snackbar, Stack } from "@mui/joy";
import { useState } from "react";
import { useGlobalContext } from "../../Context";
import { Profile } from "../../utilities/identity";
import DialogModal from "./DialogModal";
import EmailModal from "./EmailModal";
import { EmailFormProps, SnackBarState } from './constructs';

interface ProxyModalProps {
    proxyModalOpen: boolean;
    setProxyModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    userProfile: AccountInfo | null;
}

export type EmailType = "primary" | "secondary";
export type ModalType = "create" | "edit";


const getSecondaryEmails = (smtpAddresses: string[]): string[] => {
    const cleanSecondaryEmails: string[] = [];
    if (smtpAddresses.length > 1) {
        for (const address of smtpAddresses) {
            const emailArray = address.split(':');
            if (emailArray[0] === "SMTP") {
                continue
            } else {
                cleanSecondaryEmails.push(emailArray[1]);
            }
        }
    }
    return cleanSecondaryEmails;
}

export default function ProxyModal({ proxyModalOpen, setProxyModalOpen, userProfile }: ProxyModalProps) {
    const { core } = useGlobalContext();

    const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false);
    const [primaryEmail, setPrimaryEmail] = useState<string>(userProfile ? userProfile.mail : "");
    const [secondaryEmails, setSecondaryEmails] = useState<string[]>(getSecondaryEmails(userProfile ? userProfile.proxyAddresses : []));
    const [focusedEmail, setFocusedEmail] = useState<string>(primaryEmail);
    const [focusedEmailType, setFocusedEmailType] = useState<EmailType>("primary");
    const [modalType, setModalType] = useState<ModalType>("edit");
    const [dialogModalOpen, setDialogModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    //#region Snackbar options
    const [snackbarState, setSnackbarState] = useState<SnackBarState>({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        content: "",
        success: false
    });

    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    //#endregion

    const buildEmailUpdatePayload = ({ secondaryEmails, username }: EmailFormProps) => {
        const requiredDomains = secondaryEmails
            .filter(email => email.split('@')[0] === username)
            .map(email => email.split('@')[1]); // Extract domains matching the user's domain

        const additionalSMTPs = secondaryEmails.filter(email => email.split("@")[0] !== username);

        return {
            requiredDomains: requiredDomains.length > 0 ? requiredDomains : undefined,
            additionalSMTPs: additionalSMTPs.length > 0 ? additionalSMTPs : undefined,
            primaryDomain: primaryEmail.split("@")[1],
            extension_fa88af559d18423d9b5e41b82d8a46a1_lastChanged: new Date().toISOString()
        };
    }

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        if (userProfile && core) {
            setLoading(true)
            event.stopPropagation(); // Stops this form from submitting the profile form which its inside of.
            event.preventDefault();
            const updateEmailPayload = buildEmailUpdatePayload({ primaryDomain: primaryEmail, secondaryEmails, "username": `${userProfile?.userPrincipalName.split("@")[0]}` });
            const { statusCode } = await Profile.updateAccount(core, userProfile?.userPrincipalName, updateEmailPayload);
            setLoading(false);
            setProxyModalOpen(false);
            setSnackbarState({
                ...snackbarState,
                open: true,
                content: statusCode === 200 ? "Successfully updated users emails." : "Failed to update users emails.",
                success: statusCode === 200
            });
        }
    }

    const handleButtonClick = (focusedEmail: string | null, focusedEmailType: "primary" | "secondary", emailModalOpen: boolean, modalType?: "edit" | "create") => {
        setModalType(modalType || "create");
        setFocusedEmail(focusedEmail || "");
        setFocusedEmailType(focusedEmailType);
        setEmailModalOpen(emailModalOpen);
    }

    return (
        <>

            <Modal open={proxyModalOpen}>
                <ModalDialog size="lg" sx={{ width: '35%' }}>
                    <DialogTitle>Email Addresses</DialogTitle>
                    <DialogContent sx={{ fontSize: 16 }}>Create or update email addresses.</DialogContent>
                    <form
                        id="email-addresses-form"
                        onSubmit={(event) => handleFormSubmit(event)}>
                        {userProfile &&
                            <Stack spacing={2}>
                                <FormControl>
                                    <FormLabel>Primary</FormLabel>
                                    <Input
                                        required
                                        key={0}
                                        value={primaryEmail}
                                        endDecorator={
                                            <Button
                                                variant="plain"
                                                size="sm"
                                                onClick={() => {
                                                    handleButtonClick(primaryEmail, "primary", true, "edit");
                                                }}
                                                endDecorator={<Edit />}
                                            ></Button>
                                        } />
                                </FormControl>
                                {secondaryEmails.length > 0 &&
                                    <FormControl>
                                        <FormLabel>Secondary</FormLabel>
                                        <Stack spacing={1}>
                                            {secondaryEmails.map((address) => {
                                                return (
                                                    <Input
                                                        key={secondaryEmails.indexOf(address) + 1}
                                                        value={address}
                                                        endDecorator={
                                                            <Box>
                                                                <Button
                                                                    variant="plain"
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        handleButtonClick(address, "secondary", true, "edit");
                                                                    }}
                                                                    endDecorator={<Edit />}></Button>
                                                                {address.split('@')[1] !== 'efcom.onmicrosoft.com' &&
                                                                    <Button
                                                                        variant="plain"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            setFocusedEmail(address);
                                                                            setFocusedEmailType("secondary");
                                                                            setDialogModalOpen(true);
                                                                        }}
                                                                        endDecorator={<Delete />}
                                                                    ></Button>}
                                                            </Box>
                                                        }
                                                    />)
                                            })}
                                        </Stack>
                                    </FormControl>
                                }
                                <EmailModal
                                    emailModalOpen={emailModalOpen}
                                    setEmailModalOpen={setEmailModalOpen}
                                    email={focusedEmail}
                                    type={focusedEmailType}
                                    primaryEmail={primaryEmail}
                                    setPrimaryEmail={setPrimaryEmail}
                                    secondaryEmails={secondaryEmails}
                                    setSecondaryEmails={setSecondaryEmails}
                                    modalType={modalType}
                                ></EmailModal>
                                <DialogModal
                                    dialogModalOpen={dialogModalOpen}
                                    setDialogModalOpen={setDialogModalOpen}
                                    email={focusedEmail}
                                    secondaryEmails={secondaryEmails}
                                    setSecondaryEmails={setSecondaryEmails}
                                    setEmailModalOpen={setEmailModalOpen}
                                ></DialogModal>
                                <Box
                                    id="proxy-modal-buttons"
                                    sx={{
                                        justifyContent: 'flex-end',
                                        gap: 1,
                                        display: 'flex',
                                        marginTop: 3
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        variant="soft"
                                        size="sm"
                                        startDecorator={<Add />}
                                        disabled={loading}
                                        onClick={() => {
                                            handleButtonClick(focusedEmail, "secondary", true, "create"); //does not support username creation
                                        }}
                                    >Add</Button>
                                    <Button
                                        color="neutral"
                                        variant="soft"
                                        size="sm"
                                        disabled={loading}
                                        onClick={() => setProxyModalOpen(false)}
                                    >Cancel</Button>
                                    <Button
                                        type="submit"
                                        form="email-addresses-form"
                                        size="sm"
                                        loading={loading}
                                    >Submit</Button>
                                </Box>
                            </Stack>}
                    </form>
                </ModalDialog>
            </Modal >
            <Snackbar
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                variant="solid"
                color={snackbarState.success ? "success" : "danger"}
                onClose={handleSnackbarClose}
                autoHideDuration={2500}
            >
                {snackbarState.content}
            </Snackbar>
        </>
    )
}