import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Box, Button, DialogContent, DialogTitle, Divider, Modal, ModalDialog } from "@mui/joy";


interface DialogModalProps {
    dialogModalOpen: boolean;
    setDialogModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    secondaryEmails: string[];
    setSecondaryEmails: React.Dispatch<React.SetStateAction<string[]>>;
    setEmailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    email: string;
}


export default function DialogModal({ dialogModalOpen, setDialogModalOpen, secondaryEmails, setSecondaryEmails, email, setEmailModalOpen }: DialogModalProps) {
    return (
        <Modal open={dialogModalOpen} onClose={() => setDialogModalOpen(false)}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <WarningRoundedIcon />
                    Confirmation
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {`Are you sure you want to delete ${email}?`}
                </DialogContent>
                <Box
                    id="dialog-modal-buttons"
                    sx={{
                        justifyContent: 'flex-end',
                        gap: 1,
                        display: 'flex'
                    }}
                >
                    <Button
                        variant="solid" color="primary"
                        onClick={() => {
                            secondaryEmails = secondaryEmails.filter(e => e !== email);
                            setSecondaryEmails(secondaryEmails);
                            setEmailModalOpen(false);
                            setDialogModalOpen(false);
                        }}>Yes</Button>
                    <Button
                        variant="plain" color="neutral"
                        onClick={() => {
                            setDialogModalOpen(false);
                        }}>No</Button>
                </Box>
            </ModalDialog>
        </Modal>
    )
}