import { Avatar, Card, CardContent, Chip, Grid, Tooltip, Typography } from "@mui/joy";
import { Android, Apple, CorporateFare, Lock, Microsoft, Person } from "@mui/icons-material";
import DeviceModal from "./DeviceModal";
import { useEffect, useState } from "react";
import { randomColors, useGlobalContext } from "../../Context";
import { Device } from "../../Context/constructs";

const getLastActiveDayCount = (device: Device) => {
    const currentDate = new Date();
    const lastSeen = new Date(device.approximateLastSignInDateTime);

    const diff = currentDate.getTime() - lastSeen.getTime();
    const diffDays = Math.abs(Math.round(diff / (1000 * 3600 * 24)));
    return diffDays;
}

export const getExpiryDateChip = (device: Device) => {
    const active = getLastActiveDayCount(device) < 30

    return (
        <Chip
            size="lg"
            variant="soft"
            color={active ? "success" : "danger"}
            sx={{
                width: "100%",
                mt: -4,
                mb: 1,
                pt: "5px",
                pb: "5px",
                border: '2px solid',
                borderColor: 'background.surface',
            }}
        >
            {active ? "Active" : "Inactive"}
        </Chip>
    )
}

interface DeviceCardProps {
    device: Device;
    index: number;
}

export interface BitLockerResponse {
    "@odata.context": string
    value: BitLockerKey[]
}

export interface BitLockerKey {
    id: string
    createdDateTime: string
    volumeType: string
    deviceId: string
}


export default function DeviceCard({ device, index }: DeviceCardProps) {
    const { core } = useGlobalContext();

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [bitLockerKeys, setBitLockerKeys] = useState<BitLockerKey[]>();

    useEffect(() => {
        if (core) {
            core?.invokeGraphRequest("GET", `/v1.0/informationProtection/bitlocker/recoveryKeys?$filter=deviceId eq '${device.deviceId}'`).then(resp => {
                setBitLockerKeys((resp.body as BitLockerResponse).value);
            })
        }
    }, [core, device]);
    return (
        <Grid
            key={device.displayName}
            paddingTop={2}
            spacing={2}
        >
            <DeviceModal open={modalOpen} setOpen={setModalOpen} device={device} bitLocker={bitLockerKeys}></DeviceModal>
            <Card
                size='md'
                sx={{
                    width: "100%",
                    transition: "transform .2s",
                    ":hover": {
                        transform: "scale(1.03)"
                    }
                }}
                variant='soft'
            >
                <CardContent sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    ":hover": {
                        cursor: "pointer"
                    }
                }}
                    onClick={() => {
                        setModalOpen(true);
                    }}
                >
                    <Avatar sx={{ mt: 2, '--Avatar-size': '8rem', backgroundColor: randomColors[index] }}>
                        {`${device.displayName.split(" ")[0][0].toUpperCase()}${device.displayName.split(" ").length > 1 ? device.displayName.split(" ")[device.displayName.split(" ").length - 1][0].toUpperCase() : device.displayName.split(" ")[0][1].toUpperCase()}`}
                    </Avatar>
                    {
                        getExpiryDateChip(device)
                    }
                    <Typography noWrap level="title-lg">
                        {device.displayName}
                    </Typography>
                    <Typography level="body-xs" noWrap sx={{ maxWidth: '40ch' }}>
                        {/* {device.deviceId} */}
                    </Typography>

                    <Typography noWrap={true} level="body-sm" sx={{ maxWidth: '30ch' }}>
                        Last seen {new Date(device.approximateLastSignInDateTime).toISOString()}<br />
                        {getLastActiveDayCount(device)} day{getLastActiveDayCount(device) > 1 && "s"} ago
                    </Typography>
                    <br />
                    <Typography level="body-sm" noWrap sx={{ maxWidth: '40ch', minWidth: "24ch", maxHeight: "20ch", minHeight: "5ch" }}>
                        {
                            device.deviceOwnership === "Company" &&
                            <Tooltip arrow title="This device is owned and managed by EF.">
                                <Chip sx={{ mr: 1 }} variant="solid" startDecorator={<CorporateFare />}>Corporate</Chip>
                            </Tooltip>
                        }
                        {
                            device.deviceOwnership !== "Company" &&
                            <Tooltip arrow title="This device is owned by you but is using EF applications.">
                                <Chip sx={{ mr: 1 }} variant="solid" startDecorator={<Person />}>Personal</Chip>
                            </Tooltip>
                        }
                        {
                            device.operatingSystem === "Android" &&
                            <Tooltip arrow title="This is an Android device.">
                                <Chip sx={{ mr: 1}} variant="solid" startDecorator={<Android />}>Android</Chip>
                            </Tooltip>
                        }
                        {
                            device.operatingSystem === "Windows" &&
                            <Tooltip arrow title="This is an Windows device.">
                                <Chip sx={{ mr: 1}} variant="solid" startDecorator={<Microsoft />}>Windows</Chip>
                            </Tooltip>
                        }
                        {
                            (device.operatingSystem === "IPhone" || device.operatingSystem === "MacMDM" || device.operatingSystem === "Mac") &&
                            <Tooltip arrow title="This is an Apple device, either iOS or Mac.">
                                <Chip sx={{ mr: 1 }} variant="solid" startDecorator={<Apple />}>Apple</Chip>
                            </Tooltip>
                        }
                        {
                            (bitLockerKeys) &&
                            <Tooltip arrow title={bitLockerKeys?.length > 0 ? `This device has ${bitLockerKeys?.length} BitLocker key associated with it.` : "This device has no BitLocker keys."}>
                                <Chip sx={{ mr: 1 }} variant="solid" startDecorator={<Lock />}>{bitLockerKeys?.length}</Chip>
                            </Tooltip>
                        }
                    </Typography>
                </CardContent>
            </Card>
        </Grid >
    )
}