import { Autocomplete, Button, DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalDialog, Stack } from "@mui/joy";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../Context";
import { Profile } from "../../utilities/identity";
import { EmailType, ModalType } from "./ProxyModal";
import WarningModal from "./WarningModal";


interface EmailModalProps {
    emailModalOpen: boolean;
    setEmailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    primaryEmail: string;
    setPrimaryEmail: React.Dispatch<React.SetStateAction<string>>;
    secondaryEmails: string[];
    setSecondaryEmails: React.Dispatch<React.SetStateAction<string[]>>;
    email: string;
    type: EmailType;
    modalType: ModalType;
}

interface EmailSplit {
    prefix: string | null;
    firstName: string;
    lastName: string;
    suffix: string | null;
    domain: string;
}

const joinEmail = (emailSplit: EmailSplit): string => {
    const joinEmail = `${emailSplit.prefix ? emailSplit.prefix : ""}\
    ${emailSplit.prefix ? "." : ""}${emailSplit.firstName}.${emailSplit.lastName}\
    ${emailSplit.suffix ? "." : ""}${emailSplit.suffix ? emailSplit.suffix : ""}@\
    ${emailSplit.domain ? emailSplit.domain : 'efcom.onmicrosoft.com'}`;
    return joinEmail.replace(/\s/g, '').toLowerCase();
}

const createNewEmail = (event: React.FormEvent<HTMLFormElement>, sourceEmail: EmailSplit): string => {
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    const newEmailSplit = {
        prefix: formJson.prefix ? formJson.prefix : sourceEmail.prefix,
        firstName: formJson.firstName ? formJson.firstName : sourceEmail.firstName,
        lastName: formJson.lastName ? formJson.lastName : sourceEmail.lastName,
        suffix: formJson.suffix ? formJson.suffix : sourceEmail.suffix,
        domain: formJson.domain ? formJson.domain : sourceEmail.domain
    }
    const newEmail = joinEmail(newEmailSplit);
    return newEmail;
}

const splitEmail = (email: string): EmailSplit => {
    const split: EmailSplit = { prefix: null, firstName: "", lastName: "", suffix: null, domain: "" };
    split.domain = email.split('@')[1];
    const username = email.split('@')[0];
    const userNameSplit: string[] = username.split('.');
    switch (userNameSplit.length) {
        case 2:
            split.firstName = userNameSplit[0];
            split.lastName = userNameSplit[1];
            break;
        case 3:
            if (["svc", "td", "ext"].includes(userNameSplit[0])) {
                split.prefix = userNameSplit[0];
                split.firstName = userNameSplit[1];
                split.lastName = userNameSplit[2];
                break;
            } else {
                split.firstName = userNameSplit[0];
                split.lastName = userNameSplit[1];
                split.suffix = userNameSplit[2];
                break;
            }
        case 4:
            split.prefix = userNameSplit[0];
            split.firstName = userNameSplit[1];
            split.lastName = userNameSplit[2];
            split.suffix = userNameSplit[3];
            break;
    }
    return split;
}


export default function EmailModal({ emailModalOpen, setEmailModalOpen, primaryEmail, setPrimaryEmail, secondaryEmails, setSecondaryEmails, email, type, modalType }: EmailModalProps) {
    const { metadata, core } = useGlobalContext();
    const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
    const [modifiedEmail, setModifiedEmail] = useState<string>(email);
    const [isUsed, setIsUsed] = useState<{ email: string, used: boolean }>({ email: email, used: true });
    const [loading, setLoading] = useState(false);
    const dialogTitle = `${modalType === "edit" ? "Edit" : "Add"} an Email Address`;
    const emailSplit = splitEmail(email);

    const processNewEmail = () => {
        switch (type) {
            case "primary":
                setPrimaryEmail(modifiedEmail);
                break;
            case "secondary":
                if (modalType === "edit") {
                    const emailIndex = secondaryEmails.indexOf(email);
                    secondaryEmails.splice(emailIndex, 1, modifiedEmail);
                    setSecondaryEmails(secondaryEmails);
                } else {
                    setSecondaryEmails([...secondaryEmails, modifiedEmail]);
                }
                break;
        }
    }

    useEffect(() => {
        const verifyEmail = async () => {
            if (core) {
                setLoading(true);
                const isUsedEmail = await Profile.isEmailUsed(core, modifiedEmail);
                setIsUsed({ email: modifiedEmail, used: isUsedEmail });
                setLoading(false);
                return
            }
        }
        if (modifiedEmail !== email) {
            verifyEmail()
                .catch(console.error)
        }
    }, [modifiedEmail]);


    useEffect(() => {
        if (modifiedEmail !== email) {
            if (!loading) {
                if (!isUsed.used) {
                    if (type === "secondary" && (secondaryEmails.includes(modifiedEmail) || modifiedEmail === primaryEmail)) {
                        setWarningModalOpen(true);
                    } else {
                        processNewEmail();
                        setEmailModalOpen(false);
                    }
                } else {
                    setWarningModalOpen(true);
                }
            }
        }
    }, [isUsed]);


    return (
        <Modal open={emailModalOpen} onClose={() => setEmailModalOpen(false)}>
            <ModalDialog>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>Enter first name, last name and domain.</DialogContent>
                <form
                    id="email-form"
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        const newEmail = createNewEmail(event, emailSplit);
                        setModifiedEmail(newEmail);
                    }}
                >
                    <WarningModal
                        warningModalOpen={warningModalOpen}
                        setWarningModalOpen={setWarningModalOpen}
                        email={modifiedEmail}
                    ></WarningModal>
                    <Stack spacing={2}>
                        {emailSplit.prefix &&
                            <FormControl>
                                <FormLabel>Prefix</FormLabel>
                                <Input
                                    name="prefix"
                                    disabled={true}
                                    value={emailSplit.prefix}
                                />
                            </FormControl>
                        }
                        <FormControl>
                            <FormLabel>First Name</FormLabel>
                            <Input
                                autoFocus={modalType === "create"}
                                required
                                name="firstName"
                                disabled={true}
                                value={emailSplit.firstName}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Last Name</FormLabel>
                            <Input
                                required
                                name="lastName"
                                disabled={true}
                                value={emailSplit.lastName}
                            />
                        </FormControl>
                        {emailSplit.suffix &&
                            <FormControl>
                                <FormLabel>Suffix</FormLabel>
                                <Input
                                    name="suffix"
                                    disabled={true}
                                    value={emailSplit.suffix}
                                />
                            </FormControl>
                        }
                        <FormControl>
                            <FormLabel>Domain</FormLabel>
                            {
                                metadata &&
                                <Autocomplete
                                    name="domain"
                                    defaultValue={modalType === "edit" ? emailSplit.domain : undefined}
                                    required
                                    disabled={modalType === "edit" ? emailSplit.domain === "efcom.onmicrosoft.com" : false}
                                    options={metadata?.domains.map(item => item.domain)}
                                />
                            }
                        </FormControl>
                        <Button
                            type="submit"
                            loading={loading}
                            form="email-form"
                        >Save</Button>
                        <Button
                            color="neutral"
                            variant="soft"
                            onClick={() => setEmailModalOpen(false)}
                        >Cancel</Button>
                    </Stack>
                </form>
            </ModalDialog>
        </Modal>
    )
}