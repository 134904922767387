import { useEffect, useState } from 'react';
import { useHash } from 'react-use';
import { useGlobalContext } from '../../Context';
import { Box, Chip, Divider, Dropdown, IconButton, Input, LinearProgress, ListDivider, Menu, MenuButton, TabList, TabPanel, Tabs, Theme, Typography, chipClasses } from '@mui/joy';
import Header from '../Home/Header';
import { FilterList, Microsoft, MoreVert, SearchSharp } from '@mui/icons-material';
import { toggleSidebar } from '../utils';
import Tab, { tabClasses } from "@mui/joy/Tab";
import AppCard from './AppCard';
import { AppRegistration } from '../../Context/constructs';

const tabTheme = (theme: Theme) => {
    return {
        '--Chip-minHeight': '20px',
        '--ListItem-minHeight': '48px',
        top: 'calc(-1 * (var(--main-paddingTop, 0px) - var(--Header-height, 0px)))',
        zIndex: 10,
        width: '100%',
        overflow: 'auto hidden',
        alignSelf: 'flex-start',
        scrollSnapType: 'inline',
        '&::after': {
            pointerEvents: 'none',
            display: { xs: 'block', sm: 'none' },
            content: '""',
            position: 'sticky',
            top: 0,
            width: 40,
            flex: 'none',
            zIndex: 1,
            right: 0,
            borderBottom: '1px solid transparent',
            backgroundClip: 'content-box',
        },
        '&::-webkit-scrollbar': {
            width: 0,
            display: 'none',
        },
        [`& .${tabClasses.root}`]: {
            '--focus-outline-offset': '-2px',
            scrollSnapAlign: 'start',
            bgcolor: 'transparent',
            flex: 'none',
            '&:hover': {
                bgcolor: 'transparent',
            },
            [`&.${tabClasses.selected}`]: {
                color: 'primary.plainColor',
                bgcolor: 'transparent',
                [`& .${chipClasses.root}`]: theme.variants.solid.primary,
            },
        },
    }
}

export interface AppRegistrationWithColor extends AppRegistration {
    color: string;
}

export default function Apps() {
    // eslint-disable-next-line
    const [hash, setHash] = useHash();
    const { appList } = useGlobalContext();
    const [filterList, setFilterList] = useState<AppRegistration[]>();
    const [filter, setFilter] = useState<string>("");


    useEffect(() => {
        setHash("#/apps");
    }, []);

    useEffect(() => {
        if (appList) {
            setFilterList(appList);
        }
    }, [appList])

    useEffect(() => {
        if (appList) {
            if (filter.length > 0) {
                setFilterList(appList?.filter(app => app.displayName.toLowerCase().includes(filter.toLowerCase()) || app.appId.includes(filter)));
            } else {
                setFilterList(appList);
            }
        }
    }, [filter])

    return (
        <Box
            sx={{
                flex: 1,
                pl: 2,
                pr: 2,
                pt: 0,
                maxWidth: 1200,
                width: '100%',
                mx: 'auto',
            }}>
            <Header icon={<Microsoft/>} title={'Application Management'}></Header>
            <Typography mb={2}>
                Here you'll see a list of all Entra applications you are the owner of together with additional information to help you identify them.
            </Typography>
            <IconButton
                sx={{
                    display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                    },
                    position: "absolute",
                    top: 0,
                    right: 0,
                    pt: 0.5,
                    m: 2,
                    zIndex: 5
                }}
                onClick={() => toggleSidebar()}
                color="neutral"
            >
                <MoreVert sx={{ color: "white" }} />
            </IconButton>
            <Tabs
                value={0}
                sx={{ bgcolor: 'transparent' }}>
                <TabList
                    //#region Tab Theme
                    sticky="top"
                    variant="plain"
                    sx={tabTheme}
                //#endregion
                >
                    <Tab value={0} key={0}>
                        <Box>My Apps <sup style={{ color: "#bf4526" }}>Beta</sup></Box>
                    </Tab>
                </TabList>

                <Box
                    //#region Container Theme
                    sx={{
                        height: 0,
                        position: 'sticky',
                        top: 'calc(48px - var(--main-paddingTop, 0px) + var(--Header-height, 0px) - (var(--_shadow-height) / 2))',
                        zIndex: 1,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'relative',
                            zIndex: 1,
                            height: 'var(--_shadow-height)',
                        },
                    }}
                //#endregion
                >
                    <TabPanel
                        sx={{
                            padding: 0
                        }}
                        key={0}
                        value={0}>
                        <Box
                            //#region Page Layout
                            sx={{
                                pt: 3,
                                pb: 10,
                                display: 'grid',
                                gridTemplateColumns: {
                                    xs: '100%',
                                    sm: '100%'
                                },
                                columnGap: { xs: 2, sm: 3, md: 4 },
                                rowGap: { xs: 2, sm: 1 },
                                '& > hr': {
                                    gridColumn: '1/-1',
                                },
                            }}
                        //#endregion
                        >
                            <Box display={"flex"} sx={{
                                justifyContent: {
                                    xs: "space-between",
                                },
                                mb: 3,
                                mt: 0
                            }}>
                                <Input
                                    variant="soft"
                                    placeholder="Search here..."
                                    size="lg"
                                    sx={{
                                        width: {
                                            xs: "80%",
                                            lg: "40%"
                                        }
                                    }}
                                    endDecorator={<SearchSharp />}
                                    onChange={(e) => setFilter(e.target.value)}
                                ></Input>
                                <Dropdown>
                                    <MenuButton disabled color="primary" variant="solid" sx={{
                                        ml: 1,
                                        display: {
                                            xs: "flex",
                                            lg: "flex"
                                        }
                                    }}>
                                        <FilterList />
                                    </MenuButton>
                                    <Menu>
                                        <ListDivider />
                                    </Menu>
                                </Dropdown>
                            </Box>
                            {filterList ? <Divider role="presentation" /> : <LinearProgress color="neutral" thickness={2} />}
                            {filterList && <Box mt={2}>You are the owner of <Chip>{appList?.length}</Chip> app registrations.</Box>}
                            <Box
                                key={0}
                                sx={{
                                    padding: 0,
                                    display: 'grid',
                                    justifyContent: "space-between",
                                    gridTemplateColumns: {
                                        xs: '100%',
                                        sm: '50% 50%',
                                        lg: 'repeat(auto-fit, 32%) 32%',
                                    },

                                    mb: 4,
                                    '& > hr': {
                                        gridColumn: '1/-1',
                                    },
                                }}
                            >
                                {
                                    filterList && filterList.map(app => {
                                        return (
                                            <AppCard app={app} index={filterList.indexOf(app)}></AppCard>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    </TabPanel>
                </Box>
            </Tabs>

        </Box >
    )
}