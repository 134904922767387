import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { Core } from "../core";

let imageGetter: ImageGetter;
const avatars: { [key: string]: string } = {};

export class ImageGetter {
    private core?: Core;

    constructor(core?: Core) {
        if (imageGetter && this.core) {
            return imageGetter;
        } else {
            this.core = core;
            // eslint-disable-next-line
            imageGetter = this;
        }
    }

    public getPicture = async (uuid: string, isDirectReport: boolean, forceReload: boolean): Promise<string> => {
        if (avatars[uuid]) {
            return avatars[uuid];
        } else {
            const localAvatar = localStorage.getItem(`avatar_${uuid}`);
            if (localAvatar !== null && !forceReload) {
                const base64Response = await fetch(JSON.parse(localAvatar).blobBase64 || "");
                const blob = await base64Response.blob();
                const objUrl = URL.createObjectURL(blob);
                avatars[uuid] = objUrl;
                return objUrl;
            } else if (this.core) {
                const avatar = await this.core.invokeGraphBlobRequest(`/v1.0/users/${uuid}/photos/240x240/$value`) || "";
                if (avatar) {
                    const blob = await fetch(avatar).then(r => r.blob());
                    const blobBase64 = await this.blobToBase64(blob) as string;
                    try {
                        if (isDirectReport) {
                            localStorage.setItem(`avatar_${uuid}`, JSON.stringify({
                                blobBase64,
                                lastLoaded: new Date()
                            }));
                        }
                    } catch (e: any) {
                        // eslint-disable-next-line
                        console.log("Local storage is full, skipping save");
                    }
                }
                avatars[uuid] = avatar;
                return avatar;
            } else {
                return "";
            }
        }
    }

    private blobToBase64 = (blob: Blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    public updateProfileImage = async (userId: string, image: string | ArrayBuffer | null): Promise<void> => {
        try {
            if (this.core) {
                const resp = await this.core.invokeGraphFileRequest('PUT', `/v1.0/users/${userId}/photo/$value`, image);
                if (resp.statusCode !== 200) {
                    throw new Error(`${resp.body}`);
                }
                this.getPicture(userId, true, true);
            }
        } catch (error) {
            console.error(`Unable to update profile image ${JSON.stringify(error)}`);
        }
    }
}

export class TeamAvatars {
    public teamAvatars!: { [key: string]: string };
    public members!: AccountInfo[]
    private core: Core;

    private constructor(core: Core, directReports: AccountInfo[]) {
        this.core = core;
        this.members = directReports;
    }

    public retrieve = async () => {
        const avatars: { [key: string]: string } = {}
        for (const member of this.members) {
            avatars[`${member.userPrincipalName}`] = await new ImageGetter(this.core).getPicture(member.id, true, true);
        }
        this.teamAvatars = avatars;
    }

    public static retrieveTeamAvatars = async (core: Core, directReports: AccountInfo[]) => {
        const avatars = new TeamAvatars(core, directReports);
        await avatars.retrieve();
        return avatars.teamAvatars;
    }
}