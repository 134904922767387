import { AccountInfo, InteractionStatus, PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Box, CssVarsProvider, GlobalStyles, LinearProgress, Modal, ModalDialog, Typography } from '@mui/joy';
import CssBaseline from '@mui/joy/CssBaseline';
import { useEffect, useRef, useState } from 'react';
import ContextProvider from "../src/Context";
// import { identityTheme } from './components/Theme';
import { datadogRum } from '@datadog/browser-rum';
import { extendTheme } from '@mui/joy/styles';
import { useHash } from 'react-use';
import { AccountTabsProvider, SearchTabsProvider } from './Context/TabContextProvider';
import Apps from './components/AppRegistrations';
import CreateUser from './components/CreateUser';
import { CompletedUser } from './components/CreateUser/CompletedUser';
import Devices from './components/Devices';
import Feedback from './components/Feedback';
import Home from "./components/Home";
import Search from './components/Search';
import SecurityDashboard from './components/Security';
import Sidebar from './components/Sidebar';
import FlightMapper from './components/StaffTravel';
import Welcome from './components/Welcome';
import { env } from './env';

datadogRum.init({
  applicationId: '688723b9-66ac-4aa3-8ef1-c171649e17bb',
  clientToken: 'pub6cdfac2885c0939da877ebaa0d07413a',
  site: 'datadoghq.eu',
  service: 'identity-&-access',
  env: env.NODE_ENV || "development",
  version: env.APP_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  trackViewsManually: true,
  defaultPrivacyLevel: 'allow',
  allowedTracingUrls: ["https://integration-hub.ef.com"]
});

const theme = extendTheme({
  fontFamily: {
    body: "EFCircularWeb Book Web",
    display: "EFCircularWeb Medium Web"
  },
  colorSchemes: {
    dark: {
      palette: {
        neutral: {
          solidBg: "#003C64",
          softBg: "#0e1923",
          softDisabledBg: "#0e1923",
          softDisabledColor: "#FFFFFF"
        },
        background: {
          body: "#15202B",
          surface: "#0e1923",
          backdrop: "#15202B00",
          level1: "#15202B",
          popup: "#0e1923",
        },
        danger: {
          300: '#8C1D18',
          400: '#913b37',
          500: '#91312d',
          600: '#601410',
          700: '#8C1D18',
          800: '#69130f',
        },
        primary: {
          500: '#003C64',
          600: '#1B62B5',
        },
      },
    },
  },
});

interface IdentityApplicationProps {
  instance: PublicClientApplication;
}
const IdentityApplication = ({ instance }: IdentityApplicationProps) => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [activePage, setActivePage] = useState(<></>);

  const applicationRef = useRef<any>();

  const [hash, setHash] = useHash();
  const [prevHash, setPrevHash] = useState<string>("");
  const [account, setAccount] = useState<AccountInfo>();

  const clearSearchCache = () => {
    localStorage.setItem(`search_result`, JSON.stringify([]));
    localStorage.setItem(`search_query`, decodeURI(""));
  }

  useEffect(() => {
    if (instance.getAllAccounts().length > 0) {
      setAccount(instance.getAllAccounts()[0]);
    } else if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.clearCache().then(() => {
        instance.loginRedirect();
      });
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    if (account) {
      // This log is intentional.
      console.log(JSON.stringify(
        {
          env: env.NODE_ENV || "development",
          version: env.APP_VERSION,
          host: window.location.hostname,
          user_id: account.username,
        }
      ))
      datadogRum.setUser({
        id: account.username,
        name: account.name,
      });
    }
  }, [account])

  useEffect(() => {
    const rootPath = hash.replace("#", "").split("/")[1];
    if (rootPath === "search" && !prevHash.includes("search")) {
      datadogRum.startView({
        name: hash,
        service: 'identity-&-access'
      });
    } else {
      datadogRum.startView({
        name: hash,
        service: 'identity-&-access'
      });
    }
    if (account) {
      switch (rootPath) {
        case "home":
          clearSearchCache();
          setActivePage(<Welcome account={instance.getActiveAccount() as AccountInfo} />);
          break;
        case "security":
          clearSearchCache();
          setActivePage(<SecurityDashboard />);
          break;
        case "people":
          clearSearchCache();
          setActivePage(
            <AccountTabsProvider>
              <Home />
            </AccountTabsProvider>
          );
          break;
        case "search":
          setActivePage(
            <SearchTabsProvider>
              <Search />
            </SearchTabsProvider>
          );
          break;
        case "create":
          clearSearchCache();
          setActivePage(<CreateUser />);
          break;
        case "created":
          setActivePage(<CompletedUser />);
          break
        case "feedback":
          clearSearchCache();
          setActivePage(<Feedback />);
          break;
        case "apps":
          clearSearchCache();
          setActivePage(<Apps></Apps>);
          break;
        case "devices":
          clearSearchCache();
          setActivePage(<Devices></Devices>);
          break;
        case "travel":
          clearSearchCache();
          setActivePage(<FlightMapper></FlightMapper>);
          break;
        default:
          clearSearchCache();
          setActivePage(<Welcome account={instance.getActiveAccount() as AccountInfo} />);
          setHash("home")
          break;
      }
    }
    setPrevHash(hash);
  }, [hash, account]);

  return (
    <CssVarsProvider
      disableTransitionOnChange
      disableNestedContext
      defaultMode="system"
      modeStorageKey="displayMode"
      theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={(theme) => ({
          '[data-feather], .feather': {
            color: `var(--Icon-color, ${theme.vars.palette.text.icon})`,
            margin: 'var(--Icon-margin)',
            fontSize: `var(--Icon-fontSize, ${theme.vars.fontSize.xl})`,
            width: '1em',
            height: '1em',
          },
        })}
      />
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <AuthenticatedTemplate>
          <ContextProvider account={account} pca={instance as PublicClientApplication} >
            <Sidebar />
            <Box
              ref={applicationRef}
              component="main"
              className="MainContent"
              sx={{
                pt: {
                  md: 3,
                },
                pb: {
                  xs: 2,
                  sm: 2,
                  md: 3,
                },
                flex: 1,
                display: 'flex',
                flexDirection: {
                  xs: "column"
                },
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                overflow: 'auto',
              }}
            >
              {activePage}
            </Box>
          </ContextProvider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Modal open={account ? false : true}>
            <ModalDialog>
              <Typography>Please wait while we authenticate you...</Typography>
              <LinearProgress />
            </ModalDialog>
          </Modal>
        </UnauthenticatedTemplate>
      </Box>
    </CssVarsProvider>
  )
}

interface AppProps {
  msalInstance: PublicClientApplication;
}

export default function App({ msalInstance }: AppProps) {
  return (
    <MsalProvider instance={msalInstance}>
      <IdentityApplication instance={msalInstance} />
    </MsalProvider>
  )
}