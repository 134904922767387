import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { useEffect } from 'react';
import { useHash } from 'react-use';
import UserViewer from './UserViewer';

export default function Team() {
    // eslint-disable-next-line
    const [hash, setHash] = useHash();

    useEffect(() => {
        setHash("#/people/team");
    }, []);

    return (
        <UserViewer users={[] as AccountInfo[]} />
    );
}