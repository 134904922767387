import { Avatar, Card, CardContent, Chip, Grid, Tooltip, Typography } from "@mui/joy";
import { Https, Key, People } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { randomColors, useGlobalContext } from "../../Context";
import { AppRegistration } from "../../Context/constructs";

export const getExpiryDateChip = (app: AppRegistration) => {
    let hasValidSecrets = false;
    const currentDate = new Date();

    const hasKeys = app.keyCredentials.length > 0 || app.passwordCredentials.length > 0;
    if (hasKeys) {
        for (const key of app.keyCredentials) {
            const expiry = new Date(key.endDateTime);
            if (currentDate < expiry) {
                hasValidSecrets = true;
                break;
            }
        }
        if (!hasValidSecrets) {
            for (const key of app.passwordCredentials) {
                const expiry = new Date(key.endDateTime);
                if (currentDate < expiry) {
                    hasValidSecrets = true;
                    break;
                }
            }
        }
    }

    return (
        <Chip
            size="lg"
            variant="soft"
            color={hasKeys ? hasValidSecrets ? "success" : "danger" : "primary"}
            sx={{
                width: "100%",
                mt: -4,
                mb: 1,
                pt: "5px",
                pb: "5px",
                border: '2px solid',
                borderColor: 'background.surface',
            }}
        >
            {hasKeys ? hasValidSecrets ? "Active Secrets" : "Expired Secrets" : "No Secrets"}
        </Chip>
    )
}

interface AppCardProps {
    app: AppRegistration;
    index: number;
}

interface AppLogoResponse {
    "@odata.context": string;
    "@odata.null"?: boolean;
    value?: string;
}

export default function AppCard({ app, index }: AppCardProps) {
    const { core } = useGlobalContext();
    const [avatar, setAvatar] = useState<string | null | undefined>();
    useEffect(() => {
        core?.invokeIntegrationHubRequest("GET", `/identity/apps/${app.id}/logo`).then(resp => {
            if ((resp.body as AppLogoResponse).value) {
                setAvatar((resp.body as AppLogoResponse).value);
            }
        });
    }, [app]);

    return (
        <Grid
            key={app.appId}
            paddingTop={2}
            spacing={2}
        >
            <Card
                size='md'
                sx={{
                    width: "100%",
                    transition: "transform .2s",
                    ":hover": {
                        transform: "scale(1.03)"
                    }
                }}
                variant='soft'
            >
                <CardContent sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    ":hover": {
                        cursor: "pointer"
                    }
                }}
                    onClick={() => {
                        window.open(`https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Credentials/appId/${app.appId}/isMSAApp~/false`, "_blank")
                    }}
                >
                    <Avatar sx={{ mt:2, '--Avatar-size': '8rem', backgroundColor: randomColors[index] }} src={avatar ? avatar : ""}>
                        {`${app.displayName.split(" ")[0][0].toUpperCase()}${app.displayName.split(" ").length > 1 ? app.displayName.split(" ")[app.displayName.split(" ").length - 1][0].toUpperCase() : app.displayName.split(" ")[0][1].toUpperCase()}`}
                    </Avatar>
                    {
                        getExpiryDateChip(app)
                    }
                    <Typography noWrap level="title-lg" sx={{ maxWidth: '25ch' }}>
                        {app.displayName}
                    </Typography>
                    <Typography level="body-xs" noWrap sx={{ maxWidth: '40ch' }}>
                        {/* {app.appId} */}
                    </Typography>

                    <Typography noWrap={true} level="body-sm" sx={{ maxWidth: '30ch' }}>
                        Created {new Date(app.createdDateTime).toISOString()}
                    </Typography>
                    <br />
                    <Typography level="body-sm" noWrap sx={{ maxWidth: '24ch', minWidth: "24ch", maxHeight: "20ch", minHeight: "5ch" }}>
                        <Tooltip arrow title="Certificates">
                            <Chip sx={{ mr: 2 }} startDecorator={<Https />} variant="solid">{app.keyCredentials.length}</Chip>
                        </Tooltip>
                        <Tooltip arrow title="Client Secrets">
                            <Chip sx={{ mr: 2 }} startDecorator={<Key />} variant="solid">{app.passwordCredentials.length}</Chip>
                        </Tooltip>
                        <Tooltip arrow title="Owners">
                            <Chip startDecorator={<People />} variant="solid">{app.owners.length}</Chip>
                        </Tooltip>
                    </Typography>
                </CardContent>
            </Card>
        </Grid >
    )
}