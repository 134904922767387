import * as msal from "@azure/msal-browser";
import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { DevicesOther, EmojiFoodBeverage, Equalizer, Favorite, Hail, Microsoft, MoreVert, NewReleases, Pending, People, Person, PersonOff } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box, Card, CardContent, CircularProgress, IconButton, Skeleton, Tab, TabList, TabPanel, Tabs, Theme, Typography, chipClasses, tabClasses } from "@mui/joy";
import { useEffect, useState } from "react";
import { useHash } from "react-use";
import { useGlobalContext } from "../../Context";
import Header from "../Home/Header";
import { toggleSidebar } from "../utils";


interface WelcomeProps {
    account: msal.AccountInfo | undefined;
}

interface DirectReportStatistics {
    valid: number;
    expired: number;
    expiringSoon: number;
    validPercent: number;
    soonPercent: number;
    expiredPercent: number;
}

export const getDirectReportStatistics = (profiles: AccountInfo[] | undefined | null): DirectReportStatistics => {
    let expiringSoon = 0;
    let expired = 0;
    let valid = 0;
    let validPercent = 0;
    let soonPercent = 0;
    let expiredPercent = 0;

    for (const profile of (profiles || [])) {
        const diff = Math.ceil((new Date(profile?.Basic?.contractEnd || "").getTime() - new Date().getTime()) / (1000 * 3600 * 24));
        if (diff > 14 && profile?.accountEnabled) {
            valid++;
        } else if (diff > 0 && profile?.accountEnabled) {
            expiringSoon++;
        } else if (diff <= 0 && profile?.accountEnabled) {
            expired++;
        } else {
            expired++;
        }
    }
    validPercent = valid * 100 / (profiles?.length || 1);
    soonPercent = expiringSoon * 100 / (profiles?.length || 1);
    expiredPercent = expired * 100 / (profiles?.length || 1);
    return {
        valid,
        expired,
        expiringSoon,
        validPercent,
        soonPercent,
        expiredPercent
    }
}

const tabTheme = (theme: Theme) => {
    return {
        '--Chip-minHeight': '20px',
        '--ListItem-minHeight': '48px',
        top: 'calc(-1 * (var(--main-paddingTop, 0px) - var(--Header-height, 0px)))',
        zIndex: 10,
        width: '100%',
        overflow: 'auto hidden',
        alignSelf: 'flex-start',
        scrollSnapType: 'inline',
        '&::after': {
            pointerEvents: 'none',
            display: { xs: 'block', sm: 'none' },
            content: '""',
            position: 'sticky',
            top: 0,
            width: 40,
            flex: 'none',
            zIndex: 1,
            right: 0,
            borderBottom: '1px solid transparent',
            backgroundClip: 'content-box',
        },
        '&::-webkit-scrollbar': {
            width: 0,
            display: 'none',
        },
        [`& .${tabClasses.root}`]: {
            '--focus-outline-offset': '-2px',
            scrollSnapAlign: 'start',
            bgcolor: 'transparent',
            flex: 'none',
            '&:hover': {
                bgcolor: 'transparent',
            },
            [`&.${tabClasses.selected}`]: {
                color: 'primary.plainColor',
                bgcolor: 'transparent',
                [`& .${chipClasses.root}`]: theme.variants.solid.primary,
            },
        },
    }
}

export default function Welcome({ account }: WelcomeProps) {
    const [name, setName] = useState<string>("");
    const { profile, rootUser, directReports, securityAlerts, deviceList, appList } = useGlobalContext();
    const [directReportStatistics, setDirectReportStatistics] = useState<DirectReportStatistics>();

    // eslint-disable-next-line
    const [hash, setHash] = useHash();

    useEffect(() => {
        if (account) {
            setName(account.name || "");
        }
        else if (profile) {
            setName(profile?.displayName || "");
        }
    }, [account, name, profile]);

    useEffect(() => {
        setDirectReportStatistics(getDirectReportStatistics(directReports || []));
    }, [directReports]);

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour > 0 && hour < 12) {
            return "Good morning";
        } else if (hour > 11 && hour < 18) {
            return "Good afternoon";
        } else {
            return "Good evening";
        }
    }

    return (
        <Box
            sx={{
                flex: 1,
                pl: 2,
                pr: 2,
                pt: 0,
                maxWidth: 1200,
                width: '100%',
                mx: 'auto'
            }}>
            <Box sx={{
                width: {
                    xs: "90%"
                }
            }}>
                <Header icon={<Equalizer />} title={"Overview"}></Header>
                <Typography mb={2}>{`${getGreeting()} ${name.split(" ")[0] || ""}!`} Here you'll see a comprehensive view of your account, devices, your direct reports and much more.</Typography>
            </Box>
            <IconButton
                sx={{
                    display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                    },
                    position: "absolute",
                    top: 0,
                    right: 0,
                    pt: 0.5,
                    m: 2,
                    zIndex: 5
                }}
                onClick={() => toggleSidebar()}
            >
                <MoreVert sx={{ color: "white" }} />
            </IconButton>
            <Tabs
                value={0}
                sx={{ bgcolor: 'transparent' }}>
                <TabList
                    sticky="top"
                    variant="plain"
                    sx={tabTheme}
                >
                    <Tab value={0} key={0}>
                        <Box>Overview <sup style={{ color: "#bf4526" }}>Beta</sup></Box>
                    </Tab>
                </TabList>
                <TabPanel sx={{
                    padding: 0
                }}
                    value={0}>
                    <Box
                        //#region Page Layout
                        sx={{
                            pt: 3,
                            pb: 10,
                            display: 'grid',
                            gridTemplateColumns: {
                                xs: '100%',
                                sm: '100%'
                            },
                            columnGap: { xs: 2, sm: 3, md: 4 },
                            rowGap: { xs: 2, sm: 1 },
                            '& > hr': {
                                gridColumn: '1/-1',
                            },
                        }}
                    //#endregion
                    >
                        <AccordionGroup transition={"0.2s"} sx={{
                            p: 0,
                            m: 0
                        }}>
                            <Accordion defaultExpanded={true} sx={{
                                p: 0,
                                m: 0
                            }}>
                                <AccordionSummary key={`as_drs`} variant="plain">
                                    <Typography level="h4" startDecorator={<Person />}>Me</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>These indicators will tell you the overall health of your account and if there is anything that requires your attention.</Typography>
                                    <Box
                                        key={"drs"}
                                        sx={{
                                            padding: 0,
                                            display: 'grid',
                                            justifyContent: "space-between",
                                            gridTemplateColumns: {
                                                xs: '100%',
                                                sm: '50% 50%',
                                                lg: 'repeat(auto-fit, 32%) 32%',
                                            },

                                            mb: 4,
                                            '& > hr': {
                                                gridColumn: '1/-1',
                                            },
                                        }}
                                    >

                                        {securityAlerts && rootUser && getDirectReportStatistics([rootUser]).valid == 1 && securityAlerts.length === 0 &&
                                            <Card onClick={() => {
                                                setHash("/people/me")
                                            }} variant="solid" color="success" invertedColors sx={{
                                                width: "100%",
                                                transition: "transform .2s",
                                                ":hover": {
                                                    transform: "scale(1.03)"
                                                },
                                                mt: 2,
                                                cursor: "pointer"
                                            }}>
                                                <CardContent orientation="horizontal">
                                                    <CircularProgress size="lg" determinate value={100}>
                                                        <Favorite />
                                                    </CircularProgress>
                                                    <CardContent>
                                                        <Typography level="h4">Healthy</Typography>
                                                        <Typography level="body-md">No actions needed.</Typography>
                                                    </CardContent>
                                                </CardContent>
                                            </Card>
                                        }

                                        {
                                            !securityAlerts &&
                                            <Card variant="soft" color="neutral" invertedColors sx={{
                                                width: "100%",
                                                transition: "transform .2s",
                                                ":hover": {
                                                    transform: "scale(1.03)"
                                                },
                                                mt: 2
                                            }}>
                                                <CardContent orientation="horizontal">
                                                    <CircularProgress size="lg"><Pending /></CircularProgress>
                                                    <CardContent>
                                                        <Typography level="h4">
                                                            <Skeleton animation="wave">Placeholder event</Skeleton>
                                                        </Typography>
                                                        <Typography level="body-md"><Skeleton animation="wave">Placeholder location</Skeleton></Typography>
                                                    </CardContent>
                                                </CardContent>
                                            </Card>

                                        }
                                        {securityAlerts && securityAlerts.length > 0 &&
                                            <Card onClick={() => {
                                                setHash("/security")
                                            }} variant="solid" color="danger" invertedColors sx={{
                                                width: "100%",
                                                transition: "transform .2s",
                                                ":hover": {
                                                    transform: "scale(1.03)"
                                                },
                                                mt: 2,
                                                cursor: "pointer"
                                            }}>
                                                <CardContent orientation="horizontal">
                                                    <CircularProgress size="lg" determinate value={100}>
                                                        <NewReleases />
                                                    </CircularProgress>
                                                    <CardContent>
                                                        <Typography level="h4">Issues Detected</Typography>
                                                        <Typography level="body-md">Action required, click here.</Typography>
                                                    </CardContent>
                                                </CardContent>
                                            </Card>
                                        }
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            {(directReports || []).length > 0 && <Accordion defaultExpanded={true} sx={{
                                p: 0,
                                m: 0
                            }}>
                                <AccordionSummary key={`as_drs`} variant="plain">
                                    <Typography level="h4" startDecorator={<People />}>My Team</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>These indicators will tell you the overall health of the accounts you manage, if you spot something that doesn't look right, click on the tile and we'll take you to the right page.</Typography>
                                    <Box
                                        key={"drs"}
                                        sx={{
                                            padding: 0,
                                            display: 'grid',
                                            justifyContent: "space-between",
                                            gridTemplateColumns: {
                                                xs: '100%',
                                                sm: '50% 50%',
                                                lg: 'repeat(auto-fit, 32%) 32%',
                                            },

                                            mb: 4,
                                            '& > hr': {
                                                gridColumn: '1/-1',
                                            },
                                        }}
                                    >
                                        {
                                            !directReportStatistics &&
                                            <Card variant="soft" color="neutral" invertedColors sx={{
                                                width: "100%",
                                                transition: "transform .2s",
                                                ":hover": {
                                                    transform: "scale(1.03)"
                                                },
                                                mt: 2
                                            }}>
                                                <CardContent orientation="horizontal">
                                                    <CircularProgress size="lg"><Pending /></CircularProgress>
                                                    <CardContent>
                                                        <Typography level="h4">
                                                            <Skeleton animation="wave">So, all good!</Skeleton>
                                                        </Typography>
                                                        <Typography level="body-md"><Skeleton animation="wave">No issues detected.</Skeleton></Typography>
                                                    </CardContent>
                                                </CardContent>
                                            </Card>

                                        }

                                        {directReportStatistics &&
                                            <Card onClick={() => {
                                                setHash("/people/team/?filter=enabled")
                                            }} variant="solid" color="success" invertedColors sx={{
                                                width: "100%",
                                                transition: "transform .2s",
                                                ":hover": {
                                                    transform: "scale(1.03)"
                                                },
                                                mt: 2,
                                                cursor: "pointer"
                                            }}>
                                                <CardContent orientation="horizontal">
                                                    <CircularProgress size="lg" determinate value={directReportStatistics?.validPercent}>
                                                        <EmojiFoodBeverage />
                                                    </CircularProgress>
                                                    <CardContent>
                                                        <Typography level="h4">{directReportStatistics?.valid}/{directReports?.length}</Typography>
                                                        <Typography level="body-md">Active</Typography>
                                                    </CardContent>
                                                </CardContent>
                                            </Card>
                                        }
                                        {directReportStatistics && (directReportStatistics?.expiringSoon || 0) > 0 &&
                                            <Card onClick={() => {
                                                setHash("/people/team/?filter=expiringSoon")
                                            }} variant="solid" color="warning" invertedColors sx={{
                                                width: "100%",
                                                transition: "transform .2s",
                                                ":hover": {
                                                    transform: "scale(1.03)"
                                                },
                                                mt: 2,
                                                cursor: "pointer"
                                            }}>
                                                <CardContent orientation="horizontal">
                                                    <CircularProgress size="lg" determinate value={directReportStatistics?.soonPercent}>
                                                        <Hail />
                                                    </CircularProgress>
                                                    <CardContent>
                                                        <Typography level="h4">{directReportStatistics?.expiringSoon}/{directReports?.length}</Typography>
                                                        <Typography level="body-md">Expiring Soon</Typography>
                                                    </CardContent>
                                                </CardContent>
                                            </Card>
                                        }
                                        {directReportStatistics && (directReportStatistics?.expired || 0) > 0 &&
                                            <Card onClick={() => {
                                                setHash("/people/team/?filter=disabled")
                                            }} variant="solid" color="danger" invertedColors sx={{
                                                width: "100%",
                                                transition: "transform .2s",
                                                ":hover": {
                                                    transform: "scale(1.03)"
                                                },
                                                mt: 2,
                                                cursor: "pointer"
                                            }}>
                                                <CardContent orientation="horizontal">
                                                    <CircularProgress size="lg" determinate value={directReportStatistics?.expiredPercent}>
                                                        <PersonOff />
                                                    </CircularProgress>
                                                    <CardContent>
                                                        <Typography level="h4">{directReportStatistics?.expired}/{directReports?.length}</Typography>
                                                        <Typography level="body-md">Expired</Typography>
                                                    </CardContent>
                                                </CardContent>
                                            </Card>
                                        }
                                    </Box>
                                </AccordionDetails>
                            </Accordion>}
                            {
                                ((appList || []).length > 0 || (deviceList || []).length > 0) &&
                                <Accordion defaultExpanded={true} sx={{
                                    p: 0,
                                    m: 0
                                }}>
                                    <AccordionSummary key={`as_drs`} variant="plain">
                                        <Typography level="h4" startDecorator={<DevicesOther />}>Apps & Devices</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>An overview of the Apps and devices you are an owner of and if any of them require your attention.</Typography>
                                        <Box
                                            key={"drs"}
                                            sx={{
                                                padding: 0,
                                                display: 'grid',
                                                justifyContent: "space-between",
                                                gridTemplateColumns: {
                                                    xs: '100%',
                                                    sm: '50% 50%',
                                                    lg: 'repeat(auto-fit, 32%) 32%',
                                                },

                                                mb: 4,
                                                '& > hr': {
                                                    gridColumn: '1/-1',
                                                },
                                            }}
                                        >
                                            {
                                                !deviceList &&
                                                <Card variant="soft" color="neutral" invertedColors sx={{
                                                    width: "100%",
                                                    transition: "transform .2s",
                                                    ":hover": {
                                                        transform: "scale(1.03)"
                                                    },
                                                    mt: 2
                                                }}>
                                                    <CardContent orientation="horizontal">
                                                        <CircularProgress size="lg"><Pending /></CircularProgress>
                                                        <CardContent>
                                                            <Typography level="h4">
                                                                <Skeleton animation="wave">So, all good!</Skeleton>
                                                            </Typography>
                                                            <Typography level="body-md"><Skeleton animation="wave">No issues detected.</Skeleton></Typography>
                                                        </CardContent>
                                                    </CardContent>
                                                </Card>

                                            }
                                            {
                                                !appList &&
                                                <Card variant="soft" color="neutral" invertedColors sx={{
                                                    width: "100%",
                                                    transition: "transform .2s",
                                                    ":hover": {
                                                        transform: "scale(1.03)"
                                                    },
                                                    mt: 2
                                                }}>
                                                    <CardContent orientation="horizontal">
                                                        <CircularProgress size="lg"><Pending /></CircularProgress>
                                                        <CardContent>
                                                            <Typography level="h4">
                                                                <Skeleton animation="wave">So, all good!</Skeleton>
                                                            </Typography>
                                                            <Typography level="body-md"><Skeleton animation="wave">No issues detected.</Skeleton></Typography>
                                                        </CardContent>
                                                    </CardContent>
                                                </Card>

                                            }

                                            {deviceList &&
                                                <Card color="primary" onClick={() => {
                                                    setHash("/devices")
                                                }} variant="solid" invertedColors sx={{
                                                    width: "100%",
                                                    transition: "transform .2s",
                                                    ":hover": {
                                                        transform: "scale(1.03)"
                                                    },
                                                    mt: 2,
                                                    cursor: "pointer"
                                                }}>
                                                    <CardContent orientation="horizontal">
                                                        <CircularProgress size="lg" determinate value={100}>
                                                            <DevicesOther />
                                                        </CircularProgress>
                                                        <CardContent>
                                                            <Typography level="h4">{deviceList.length}</Typography>
                                                            <Typography level="body-md">Devices</Typography>
                                                        </CardContent>
                                                    </CardContent>
                                                </Card>
                                            }
                                            {appList &&
                                                <Card color="primary" onClick={() => {
                                                    setHash("/apps")
                                                }} variant="solid" invertedColors sx={{
                                                    width: "100%",
                                                    transition: "transform .2s",
                                                    ":hover": {
                                                        transform: "scale(1.03)"
                                                    },
                                                    mt: 2,
                                                    cursor: "pointer"
                                                }}>
                                                    <CardContent orientation="horizontal">
                                                        <CircularProgress size="lg" determinate value={100}>
                                                            <Microsoft />
                                                        </CircularProgress>
                                                        <CardContent>
                                                            <Typography level="h4">{appList.length}</Typography>
                                                            <Typography level="body-md">Apps you Manage</Typography>
                                                        </CardContent>
                                                    </CardContent>
                                                </Card>
                                            }
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            }
                        </AccordionGroup>
                    </Box>
                </TabPanel>
            </Tabs>
        </Box>
    )
}