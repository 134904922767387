import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import * as pn from "libphonenumber-js";
import { RoleSensitivity } from "../utils";

export const verifyEmail = (personalEmail: string): boolean => {
    const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const validEmail = emailRegex.test(personalEmail);
    return validEmail;
}

export const verifyPhoneNumber = (personalPhone: string): boolean => {
    return pn.isValidPhoneNumber(personalPhone);
}

export type ProfileItemSensitivity = "high" | "medium" | "finance" | "low";
export type ProfileAdminRoles = "globalAdmin" | "userAdmin" | "financeAdmin" | "userSearch";

export const formInputMapping = {
    "Product": "product",
    "Product Market": "productMarket",
    "Company": "companyCode",
    "Sales Market": "salesMarket",
    "Cost Center": "costCenter",
    "Office Location": "sites",
    "Department": "productDivision",
    "Personal Phone": "personalPhone",
    "Personal Email": "personalEmail",
    "Work Phone": "workPhone",
    "Job Title": "jobTitle"
} as const;

type FormLabels = keyof typeof formInputMapping;
export type FormValues = (typeof formInputMapping)[keyof typeof formInputMapping];

export const getFormLabel = (value: FormValues) => {
    //console.log(value);
    return Object.keys(formInputMapping).find((key) => formInputMapping[key as FormLabels] === value);
}

export const getProfileItems = (
    userProfile: AccountInfo | null | undefined,
    locked: boolean,
    rootUser: AccountInfo | null | undefined,
    isDirectReport: boolean,
    sensitivityLevel: RoleSensitivity
) => {
    const getLegacyString = () => {
        if (userProfile && userProfile.onPremisesExtensionAttributes && userProfile.onPremisesExtensionAttributes.extensionAttribute6) {
            const parts = userProfile.onPremisesExtensionAttributes.extensionAttribute6.split(",");
            if (parts.length > 0) {
                return `${parts[1]}.${parts[2]}.${parts[3]}.${parts[4]}.${parts[5]}.${parts[6]}`
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    const isADPEditable = () => {
        return locked ? userProfile?.Basic?.integrationPlatform === "adp" ? false : true : false;
    }

    //#region Profile Element Mapping
    const baseItems = {
        work: [
            {
                key: "Job Title",
                label: "Job Title",
                name: "jobTitle",
                value: userProfile?.jobTitle,
                isLocked: locked,
                type: "free",
                sensitivity: "low"
            },
            {
                key: "Department",
                label: "Department",
                name: "department",
                value: userProfile?.department,
                isLocked: locked,
                type: "list",
                sensitivity: "low"
            },
            {
                key: "Manager",
                label: "Manager",
                name: "managerUpn",
                value: isDirectReport ? rootUser?.userPrincipalName : userProfile?.manager?.userPrincipalName || "",
                isLocked: isADPEditable(),
                type: "list",
                sensitivity: "low"
            },
            {
                key: "Office Location",
                label: "Office Location",
                name: "officeLocation",
                value: userProfile?.officeLocation,
                isLocked: locked,
                type: "list",
                sensitivity: "low"
            },
            {
                key: "Account Expires",
                label: "Account Expires",
                name: "contractEnd",
                value: userProfile?.Basic?.contractEnd?.split("T")[0],
                isLocked: true,
                type: "free",
                sensitivity: "medium"
            },
            {
                key: "HR System",
                label: "HR System",
                name: "hrSystem",
                value: userProfile?.Basic?.integrationPlatform,
                isLocked: true,
                type: "free",
                sensitivity: "medium"
            }
        ],
        finance: [
            {
                key: "Product",
                label: "Product",
                name: "product",
                value: userProfile?.Finance?.product,
                isLocked: locked,
                type: "list",
                sensitivity: "finance"
            },
            {
                key: "Product Market",
                label: "Product Market",
                name: "productMarket",
                value: userProfile?.Finance?.productMarket,
                isLocked: locked,
                type: "list",
                sensitivity: "finance"
            },
            {
                key: "Company",
                label: "Company",
                name: "company",
                value: userProfile?.Finance?.company,
                isLocked: locked,
                type: "list",
                sensitivity: "finance"
            },
            {
                key: "Sales Market",
                label: "Sales Market",
                name: "salesMarket",
                value: userProfile?.Finance?.salesMarket,
                isLocked: locked,
                type: "list",
                sensitivity: "finance"
            },
            {
                key: "Cost Center",
                label: "Cost Center",
                name: "costCenter",
                value: userProfile?.Finance?.costCenter,
                isLocked: locked,
                type: "list",
                sensitivity: "finance"
            },
            {
                key: "Cost String",
                label: "Cost String",
                name: "costString",
                value: userProfile ? `${userProfile?.Finance?.company}.0.${userProfile?.Finance?.product}.${userProfile?.Finance?.salesMarket}.${userProfile?.Finance?.productMarket}.${userProfile?.Finance?.costCenter}.0.0.0` : null,
                isLocked: true,
                type: "free",
                sensitivity: "finance"
            },
            {
                key: "Legacy String",
                label: "Legacy String",
                name: "legacyString",
                value: userProfile ? getLegacyString() : null,
                isLocked: true,
                type: "free",
                sensitivity: "finance"
            }
        ],
        contact: [
            {
                key: "Personal Phone",
                label: "Personal Phone",
                name: "personalPhone",
                value: userProfile?.Protected?.personalPhone,
                type: "tel",
                isLocked: locked,
                sensitivity: "high"
            },
            {
                key: "Work Phone",
                label: "Work Phone",
                name: "workPhone",
                value: userProfile?.Basic?.workPhone ? userProfile?.Basic?.workPhone[0] : "",
                isLocked: locked,
                type: "tel",
                sensitivity: "low"
            },
            {
                key: "Personal Email",
                label: "Personal Email",
                name: "personalEmail",
                value: userProfile?.Protected?.personalEmail,
                isLocked: locked,
                type: "email",
                sensitivity: "high"
            },
            {
                key: "Work Email",
                label: "Work Email",
                name: "workEmail",
                value: userProfile?.mail,
                isLocked: isADPEditable(),
                type: "modal",
                userProfile: userProfile,
                sensitivity: "low"
            }
        ]
    }
    //#endregion

    if (userProfile) {
        switch (sensitivityLevel) {
            case "high":
                return baseItems;
            case "finance":
                return {
                    work: baseItems.work.filter(item => ["medium", "finance", "low"].includes(item.sensitivity)),
                    contact: baseItems.contact.filter(item => ["medium", "finance", "low"].includes(item.sensitivity)),
                    finance: baseItems.finance.filter(item => ["medium", "finance", "low"].includes(item.sensitivity))
                }
            case "medium":
                return {
                    work: baseItems.work.filter(item => ["medium", "finance", "low"].includes(item.sensitivity)),
                    contact: baseItems.contact.filter(item => ["medium", "finance", "low"].includes(item.sensitivity)),
                    finance: baseItems.finance.filter(item => ["medium", "finance", "low"].includes(item.sensitivity))
                }
            case "low":
                return {
                    work: baseItems.work.filter(item => ["low"].includes(item.sensitivity)),
                    contact: baseItems.contact.filter(item => ["low"].includes(item.sensitivity)),
                    finance: baseItems.finance.filter(item => ["low"].includes(item.sensitivity))
                }
            default:
                return {
                    work: baseItems.work.filter(item => ["low"].includes(item.sensitivity)),
                    contact: baseItems.contact.filter(item => ["low"].includes(item.sensitivity)),
                    finance: baseItems.finance.filter(item => ["low"].includes(item.sensitivity))
                }
        }
    } else {
        return {};
    }
}