import React from "react";
import { TabPanel, Box } from "@mui/joy";


export interface TabAreaProps {
    name: string;
    itemCount: number;
    itemDescriptor?: string;
    index: number;
    children: React.ReactElement<any, string> | React.ReactElement<any, string>[]
}

export default function TabArea({ index, children }: TabAreaProps) {
    return (
        <TabPanel
            sx={{
                padding: 0,
            }}
            key={index}
            value={index}>
            <Box
                //#region Page Layout
                sx={{
                    pt: 3,
                    pb: 10,
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: '100%',
                        sm: '100%'
                    },
                    columnGap: { xs: 2, sm: 3, md: 4 },
                    rowGap: { xs: 2, sm: 1 },
                    '& > hr': {
                        gridColumn: '1/-1',
                    },
                }}
            //#endregion
            >{children}</Box>

        </TabPanel>
    )
}