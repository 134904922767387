import { AccountInfo as MsalAccountInfo, PublicClientApplication } from "@azure/msal-browser";
import { datadogRum } from '@datadog/browser-rum';
import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { createContext, useContext, useEffect, useState } from "react";
import { ImageGetter } from "../utilities/assets";
import { Core } from "../utilities/core";
import { Profile } from "../utilities/identity";
import { Metadata } from "../utilities/metadata";
import { AppQueryResponse, AppRegistration, Device, DeviceQueryResponse, SecurityAlert, SecurityAlertResponse } from "./constructs";

type ContextProps = {
    pca: PublicClientApplication;
    children?: string | JSX.Element | JSX.Element[];
    account: any;
}

export type GlobalContent = {
    core: Core | null;
    setCore: (c: Core) => void;

    metadata: Metadata | null;
    setMetadata: (md: Metadata) => void;

    profile: AccountInfo | null;
    setProfile: (prof: AccountInfo) => void;

    profileImage: string | null;
    setProfileImage: (image: string) => void;

    rootUser: AccountInfo | null;
    setRootUser: (user: AccountInfo) => void;

    directReports: AccountInfo[] | null;
    setDirectReports: (dR: AccountInfo[]) => void;

    securityAlerts: SecurityAlert[] | null;
    setSecurityAlerts: (alerts: SecurityAlert[]) => void;

    deviceList: Device[] | null | undefined;
    setDeviceList: (device: Device[]) => void;

    appList: AppRegistration[] | null | undefined;
    setAppList: (alerts: AppRegistration[]) => void;
}

export const CoreContext = createContext<GlobalContent>({
    core: null,
    // eslint-disable-next-line
    setCore: () => { },

    metadata: null,
    // eslint-disable-next-line
    setMetadata: () => { },

    profile: null,
    // eslint-disable-next-line
    setProfile: () => { },

    profileImage: null,
    // eslint-disable-next-line
    setProfileImage: () => { },

    rootUser: null,
    // eslint-disable-next-line
    setRootUser: () => { },

    directReports: null,
    // eslint-disable-next-line
    setDirectReports: () => { },

    securityAlerts: null,
    // eslint-disable-next-line
    setSecurityAlerts: () => { },

    deviceList: null,
    // eslint-disable-next-line
    setDeviceList: () => { },

    appList: null,
    // eslint-disable-next-line
    setAppList: () => { },
})

export const useGlobalContext = () => useContext(CoreContext);

const randomColor = (seed: number) => {
    return `hsla(${~~(36 * seed)}, 70%,  72%, 0.8)`
}

export const randomColors: string[] = [];
for (let i = 0; i < 1000; i++) {
    randomColors.push(randomColor(i));
}

export default function ContextProvider({ pca, children, account }: ContextProps) {
    const [core, setCore] = useState<Core | null>(null);
    const [metadata, setMetadata] = useState<Metadata | null>(null);
    const [profile, setProfile] = useState<AccountInfo | null>(null);
    const [profileImage, setProfileImage] = useState<string | null>(null);
    const [rootUser, setRootUser] = useState<AccountInfo | null>(null);
    const [directReports, setDirectReports] = useState<AccountInfo[] | null>(null);

    const [securityAlerts, setSecurityAlerts] = useState<SecurityAlert[] | null>(null);
    const [deviceList, setDeviceList] = useState<Device[]>();
    const [appList, setAppList] = useState<AppRegistration[]>();

    useEffect(() => {
        init();
    }, [pca, account]);

    useEffect(() => {
        // Profile and direct reports.
        if (profile && directReports) {
            datadogRum.setUserProperty("roles", profile.RBAC?.iaaRole || "none");
            datadogRum.setUserProperty("directReportCount", directReports.length || "0");

            localStorage.setItem("profile", JSON.stringify(profile));
            localStorage.setItem("directReports", JSON.stringify(directReports));
            localStorage.setItem("profileLastUpdated", new Date().toISOString());
        }
    }, [profile, directReports]);

    useEffect(() => {
        // Security Alerts.
        if (securityAlerts) {
            datadogRum.setUserProperty("securityAlertCount", securityAlerts.length || "0");
            localStorage.setItem("securityAlerts", JSON.stringify(securityAlerts));
        }
    }, [securityAlerts]);

    useEffect(() => {
        // Apps.
        if (appList && appList.length > 0) {
            datadogRum.setUserProperty("appCount", appList.length || "0");
            localStorage.setItem("appList", JSON.stringify(appList));
        }
    }, [appList]);

    useEffect(() => {
        // Devices.
        if (deviceList && deviceList.length > 0) {
            datadogRum.setUserProperty("deviceCount", deviceList.length || "0");
            localStorage.setItem("deviceList", JSON.stringify(deviceList));
        }
    }, [deviceList]);

    useEffect(() => {
        // Metadata.
        if (metadata) {
            localStorage.setItem("metadata", JSON.stringify(metadata));
            localStorage.setItem("metadataLastUpdated", new Date().toISOString())
        }
    }, [metadata]);

    const init = async () => {
        // Cleanup avatars that are older than 7 days.
        const avatarAssets = Object.keys(localStorage).filter(item => item.startsWith("avatar_"));
        for (const avatarAsset of avatarAssets) {
            const asset = localStorage.getItem(avatarAsset);
            if (asset) {
                const assetJSON = JSON.parse(asset);
                if (Math.abs((new Date().getTime() - new Date(assetJSON.lastLoaded).getTime()) / (1000 * 3600 * 24)) > 5) {
                    localStorage.removeItem(avatarAsset);
                }
            }
        }
        // Continue with loading.
        if (account) {
            // Load all required data from storage first if it's available.
            const localProfile = localStorage.getItem("profile");
            if (localProfile) {
                if ((JSON.parse(localProfile) as unknown as AccountInfo).customSecurityAttributes) {
                    localStorage.clear();
                    location.reload();
                    return;
                }
                setProfile(JSON.parse(localProfile) as unknown as AccountInfo);
                setRootUser(JSON.parse(localProfile));
                setDirectReports(JSON.parse(localStorage.getItem("directReports") || "[]"));

                const imageGetter = new ImageGetter();
                const profileImage = await imageGetter.getPicture(JSON.parse(localProfile).id, true, false);
                setProfileImage(profileImage as unknown as string);
            }
            const localMetadata = localStorage.getItem("metadata");
            if (localMetadata) {
                setMetadata(JSON.parse(localMetadata));
            }

            const localSecurityAlerts = localStorage.getItem("securityAlerts");
            if (localSecurityAlerts) {
                setSecurityAlerts(JSON.parse(localSecurityAlerts));
            }

            const localApps = localStorage.getItem("appList");
            if (localApps) {
                setAppList(JSON.parse(localApps));
            }

            const localDevices = localStorage.getItem("deviceList");
            if (localDevices) {
                setDeviceList(JSON.parse(localDevices));
            }

            const core = await new Core().initialiseCore(pca, account as MsalAccountInfo);
            setCore(core);

            // Load everything again from the API to keep local data updated.
            const profile = await Profile.retrieveProfile(core, account as MsalAccountInfo);
            setProfile(profile as unknown as AccountInfo);
            setRootUser(profile);

            const metadata = await Metadata.retrieveMetadata(core);
            setMetadata(metadata);

            const directReports = await Profile.retrieveDirectReports(core, account as MsalAccountInfo);
            const validDirectReports = directReports?.filter(usr => ["STAFF", "TEACHER", "EXTERNAL CONSULTANT", "SERVICE ACCOUNT", "TOUR DIRECTOR"].includes(usr.Basic?.accountType || ""))
            setDirectReports(validDirectReports);

            const imageGetter = new ImageGetter(core);
            const profileImage = await imageGetter.getPicture(profile.id, true, true);
            setProfileImage(profileImage as unknown as string);

            // Load security alerts.
            core.invokeIntegrationHubRequest("GET", `/identity/users/${profile.id}/alerts`).then((resp) => {
                const alerts = (((resp.body) as SecurityAlertResponse).value).filter(alert => alert.status !== "resolved");
                setSecurityAlerts(alerts);
            });

            // Load devices and apps.
            Profile.getOwnedDevices(core, profile.id).then(resp => {
                if (resp.body) {
                    const deviceList = (resp.body as DeviceQueryResponse).value.sort((a, b) => a.displayName.localeCompare(b.displayName));
                    setDeviceList(deviceList.map(device => {
                        return (device);
                    }));
                }
            });

            Profile.getOwnedApps(core, profile.id).then(resp => {
                if (resp.body) {
                    const appList = (resp.body as AppQueryResponse).value.sort((a, b) => a.displayName.localeCompare(b.displayName));
                    setAppList(appList.map(app => {
                        return (app);
                    }));
                }
            });
        }
    }

    return (
        <CoreContext.Provider value={{
            core, setCore,
            metadata, setMetadata,
            profile, setProfile,
            profileImage, setProfileImage,
            rootUser, setRootUser,
            directReports, setDirectReports,
            securityAlerts, setSecurityAlerts,
            appList, setAppList,
            deviceList, setDeviceList
        }}>
            {children}
        </CoreContext.Provider>
    )
}