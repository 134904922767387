import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';


import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useEffect } from 'react';
import { useHash } from 'react-use';
import Toggler from "./ListToggler";
import { SidebarHeading } from './constructs';
import { Chip, extendTheme } from '@mui/joy';

import { ping } from 'ldrs'
ping.register()

const theme = extendTheme({
  fontFamily: {
      body: "EFCircularWeb Book Web",
      display: "EFCircularWeb Medium Web"
  },
  colorSchemes: {
      dark: {
          palette: {
              neutral: {
                  solidBg: "#003C64",
                  softBg: "#0e1923",
                  softDisabledBg: "#0e1923",
                  softDisabledColor: "#FFFFFF"
              },
              background: {
                  body: "#15202B",
                  surface: "#0e1923",
                  backdrop: "#15202B00",
                  level1: "#15202B",
                  popup: "#0e1923",
              },
              danger: {
                  300: '#8C1D18',
                  400: '#913b37',
                  500: '#91312d',
                  600: '#601410',
                  700: '#8C1D18',
                  800: '#69130f',
              },
              primary: {
                  500: '#003C64',
                  600: '#1B62B5',
              },
          },
      },
  },
});

const SidebarItem = ({ id, label, path, selected, setSelected, icon, count }: any) => {
  const [hash, setHash] = useHash();

  useEffect(() => {
    const rootPath = hash.replace("#", "").split("/")[1];
    if (rootPath === path) {
      setSelected(id);
    }
  }, [hash]);

  return (
    <ListItem sx={{ mt: 0.5 }} key={id}>
      <ListItemButton
        id={id}
        selected={selected === id}
        onClick={() => {
          setHash(`#/${path}`);
          setSelected(id);
        }}>
        {icon}
        <Typography level="body-sm" variant='plain'>{label}</Typography>
        {
          (count && count > 0) &&
          <Chip endDecorator={
            <l-ping
              size="20"
              speed="1.5"
              color={theme.palette.neutral.solidColor}
            ></l-ping>
          } variant='solid' color='danger'>{count}</Chip>
        }
      </ListItemButton>
    </ListItem>
  )
}

export default function DynamicSidebarList({ icon, items, label }: SidebarHeading) {
  return (
    <ListItem nested>
      <Toggler
        defaultExpanded
        renderToggle={({ open, setOpen }) => (
          <ListItemButton onClick={() => setOpen(!open)}>
            {icon}
            <ListItemContent>
              <Typography level="title-sm">{label}</Typography>
            </ListItemContent>
            <KeyboardArrowDownIcon
              sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
            />
          </ListItemButton>
        )}
      >
        <List sx={{ gap: 0.5 }}>
          {items.map((item) => (
            <SidebarItem key={item.id} id={item.id} label={item.label} path={item.path} selected={item.selected} setSelected={item.setSelected} icon={item.icon} count={item.count} />
          ))}
        </List>
      </Toggler>
    </ListItem>
  );
}
