import { Fingerprint, Lock, MoreTime } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemContent, ListItemDecorator, Modal, ModalDialog, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../Context";
import { Device } from "../../Context/constructs";
import { BitLockerKey } from "./DeviceCard";

interface DeviceModalProps {
    device: Device;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    bitLocker?: BitLockerKey[]
}

interface BitLockerKeyWithDetails extends BitLockerKey {
    key?: string
}

export default function DeviceModal({ device, open, setOpen, bitLocker }: DeviceModalProps) {
    const { core } = useGlobalContext();
    const [keyDetails, setKeyDetails] = useState<BitLockerKeyWithDetails[]>([]);

    useEffect(() => {
        let keys: any[] = [];
        if (core && bitLocker) {
            for (const key of bitLocker) {
                core.invokeGraphRequest("GET", `/v1.0/informationProtection/bitlocker/recoveryKeys/${key.id}?$select=key`).then((resp: any) => {
                    keys = (Array.from(new Set([
                        ...(keys as BitLockerKeyWithDetails[]),
                        (resp.body as BitLockerKeyWithDetails)
                    ])));
                    setKeyDetails(keys);
                });
            }
        }
    }, [core, bitLocker]);

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <ModalDialog maxWidth="md" minWidth="md">
                <DialogTitle>
                    {device.displayName}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    Here you'll see some additional information about the device you have selected, as well as being able to perform a few basic actions.
                </DialogContent>
                <DialogContent>
                    <Typography>Details</Typography>
                    <Divider />
                    <List>
                        <ListItem>
                            <ListItemDecorator>
                                <Fingerprint />
                            </ListItemDecorator>
                            <ListItemContent>
                                <Typography level="title-sm">Device ID</Typography>
                                <Typography level="body-sm" noWrap>
                                    {device.deviceId}
                                </Typography>
                            </ListItemContent>
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>
                                <MoreTime />
                            </ListItemDecorator>
                            <ListItemContent>
                                <Typography level="title-sm">Last sign-in date</Typography>
                                <Typography level="body-sm" noWrap>
                                    {device.approximateLastSignInDateTime}
                                </Typography>
                            </ListItemContent>
                        </ListItem>
                    </List>
                    <Typography mt={2}>BitLocker</Typography>
                    <Divider />
                    <List>
                        {
                            keyDetails.length > 0 ? keyDetails?.map(key => {
                                return (
                                    <ListItem>
                                        <ListItemDecorator>
                                            <Lock />
                                        </ListItemDecorator>
                                        <ListItemContent>
                                            <Typography level="title-sm">Recovery Key</Typography>
                                            <Typography level="body-sm" noWrap>
                                                {key.key}
                                            </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                )
                            }) : "There are no BitLocker keys associated with this device."
                        }
                    </List>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => {
                        window.open(`https://portal.azure.com/#view/Microsoft_AAD_Devices/DeviceDetailsMenuBlade/~/Properties/objectId/${device.id}/deviceId/`, "_blank")
                    }}>
                        View on Azure Portal
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}