import { GraphicEq, MapOutlined, MapRounded, MoreTime, MoreVert, NewReleases, Pending, Person, Shield } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box, Button, Card, CardContent, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemContent, ListItemDecorator, Modal, ModalDialog, Sheet, Skeleton, Tab, TabList, TabPanel, Tabs, Theme, Typography, chipClasses, extendTheme, tabClasses } from "@mui/joy";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../Context";
import Header from "../Home/Header";
import { toggleSidebar } from "../utils";
import { useHash } from "react-use";

import { Map, Marker, ZoomControl } from 'pigeon-maps';
import { osm } from 'pigeon-maps/providers';
import { SecurityAlert } from "../../Context/constructs";

import { quantum, ripples } from 'ldrs'

quantum.register();
ripples.register();


const theme = extendTheme({
    fontFamily: {
        body: "EFCircularWeb Book Web",
        display: "EFCircularWeb Medium Web"
    },
    colorSchemes: {
        dark: {
            palette: {
                neutral: {
                    solidBg: "#003C64",
                    softBg: "#0e1923",
                    softDisabledBg: "#0e1923",
                    softDisabledColor: "#FFFFFF"
                },
                background: {
                    body: "#15202B",
                    surface: "#0e1923",
                    backdrop: "#15202B00",
                    level1: "#15202B",
                    popup: "#0e1923",
                },
                danger: {
                    300: '#8C1D18',
                    400: '#913b37',
                    500: '#91312d',
                    600: '#601410',
                    700: '#8C1D18',
                    800: '#69130f',
                },
                primary: {
                    500: '#003C64',
                    600: '#1B62B5',
                },
            },
        },
    },
});

const tabTheme = (theme: Theme) => {
    return {
        '--Chip-minHeight': '20px',
        '--ListItem-minHeight': '48px',
        top: 'calc(-1 * (var(--main-paddingTop, 0px) - var(--Header-height, 0px)))',
        zIndex: 10,
        width: '100%',
        overflow: 'auto hidden',
        alignSelf: 'flex-start',
        scrollSnapType: 'inline',
        '&::after': {
            pointerEvents: 'none',
            display: { xs: 'block', sm: 'none' },
            content: '""',
            position: 'sticky',
            top: 0,
            width: 40,
            flex: 'none',
            zIndex: 1,
            right: 0,
            borderBottom: '1px solid transparent',
            backgroundClip: 'content-box',
        },
        '&::-webkit-scrollbar': {
            width: 0,
            display: 'none',
        },
        [`& .${tabClasses.root}`]: {
            '--focus-outline-offset': '-2px',
            scrollSnapAlign: 'start',
            bgcolor: 'transparent',
            flex: 'none',
            '&:hover': {
                bgcolor: 'transparent',
            },
            [`&.${tabClasses.selected}`]: {
                color: 'primary.plainColor',
                bgcolor: 'transparent',
                [`& .${chipClasses.root}`]: theme.variants.solid.primary,
            },
        },
    }
}

interface GeoIpResponse {
    range: number[]
    country: string
    region: string
    eu: string
    timezone: string
    city: string
    ll: number[]
    metro: number
    area: number
}

interface BedrockResponse {
    message: string;
}

interface AlertCardProps {
    alert: SecurityAlert;
}
const AlertCard = ({ alert }: AlertCardProps) => {
    const [open, setOpen] = useState<boolean>(false);
    // const [currentPosition, setCurrentPosition] = useState<GeolocationCoordinates>();
    const [problemPosition, setProblemPosition] = useState<GeoIpResponse>();
    const { core } = useGlobalContext();

    const [queryStarted, setQueryStarted] = useState<boolean>(false);
    const [bedrockResponse, setBedrockResponse] = useState<string>();

    const states = alert.userStates.sort((a, b) => { return (new Date(b.logonDateTime).getTime() - new Date(a.logonDateTime).getTime()) }).reverse();
    const problemState = states[states.length - 1];

    useEffect(() => {
        if (problemState.logonIp) {
            core?.invokeIntegrationHubRequest("GET", `/system/geoip/${problemState.logonIp}`).then(resp => {
                setProblemPosition((resp.body as GeoIpResponse));
            });
        }
    }, [alert, core]);

    useEffect(() => {
        const cachedResp = sessionStorage.getItem(`insights_${alert.id}`);
        if (cachedResp) {
            setBedrockResponse(cachedResp);
        } else {
            if (open && !bedrockResponse && !queryStarted) {
                setQueryStarted(true);
                core?.invokeIntegrationHubRequest("POST", `/system/bedrock/explain-alert`, alert, {
                    "Content-Type": "application/json"
                }).then(resp => {
                    if ((resp.body as BedrockResponse).message) {
                        sessionStorage.setItem(`insights_${alert.id}`, (resp.body as BedrockResponse).message);
                        setBedrockResponse((resp.body as BedrockResponse).message);
                    }
                });
            }
        }
    }, [open]);

    return (
        <>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog maxWidth="md" minWidth="md">
                    <DialogTitle>
                        {alert.title}
                    </DialogTitle>
                    <Divider />
                    <Typography startDecorator={<MapOutlined />} level="title-md">Map</Typography>
                    <DialogContent>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: {
                                    xs: '100%',
                                    sm: '100%'
                                },
                                columnGap: { xs: 2, sm: 3, md: 4 },
                                rowGap: { xs: 2, sm: 1 },
                                '& > hr': {
                                    gridColumn: '1/-1',
                                },
                            }}
                        >
                            {
                                problemState.logonIp && problemPosition ?
                                    <Map
                                        provider={osm}
                                        height={300}
                                        defaultCenter={
                                            [
                                                problemPosition.ll[0], problemPosition.ll[1]
                                            ]
                                        }
                                        defaultZoom={10}
                                    >
                                        <ZoomControl />
                                        {/* <Marker  color="green" anchor={
                                            [currentPosition?.latitude || 0, currentPosition?.longitude || 0]
                                        }></Marker> */}
                                        <Marker color="red" anchor={
                                            [
                                                problemPosition.ll[0], problemPosition.ll[1]
                                            ]
                                        }></Marker>
                                    </Map> :
                                    <Sheet color="primary" variant="soft" sx={{ p: 2, borderRadius: 5, mb: 1 }}>
                                        <Typography>There is currently no location data for this event.</Typography>
                                    </Sheet>
                            }
                        </Box>

                    </DialogContent>
                    <Divider sx={{ mt: 2 }} />
                    <Typography startDecorator={<GraphicEq />} level="title-md">Event Details</Typography>
                    <DialogContent>
                        <Box display={"flex"}>
                            <Box width={"50%"}>
                                <List>
                                    <ListItem>
                                        <ListItemDecorator>
                                            <NewReleases />
                                        </ListItemDecorator>
                                        <ListItemContent>
                                            <Typography level="title-sm">Severity</Typography>
                                            <Typography level="body-sm" noWrap>
                                                {alert.severity.toUpperCase()}
                                            </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemDecorator>
                                            <MapRounded />
                                        </ListItemDecorator>
                                        <ListItemContent>
                                            <Typography level="title-sm">Location</Typography>
                                            <Typography level="body-sm" noWrap>
                                                {problemState.logonDateTime ? `${problemPosition?.city}, ${problemPosition?.country}` : "No location recorded."}
                                            </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                </List>
                            </Box>
                            <Box width={"50%"}>
                                <List>
                                    <ListItem>
                                        <ListItemDecorator>
                                            <MoreTime />
                                        </ListItemDecorator>
                                        <ListItemContent>
                                            <Typography level="title-sm">Alert Time</Typography>
                                            <Typography level="body-sm" noWrap>
                                                {problemState.logonDateTime ? problemState.logonDateTime : alert.createdDateTime}
                                            </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemDecorator>
                                            <NewReleases />
                                        </ListItemDecorator>
                                        <ListItemContent>
                                            <Typography level="title-sm">IP Address</Typography>
                                            <Typography level="body-sm" noWrap>
                                                {problemState.logonIp || "No ip address recorded."}
                                            </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                </List>
                            </Box>
                        </Box>
                    </DialogContent>
                    <Divider sx={{ mt: 2 }} />
                    <Typography startDecorator={
                        <Box>
                            <l-quantum
                                size="20"
                                speed="2"
                                color={theme.palette.neutral.solidColor}
                            ></l-quantum>
                        </Box>
                    } level="title-md">AI Insights</Typography>
                    <DialogContent>
                        <Box>
                            {
                                bedrockResponse ?
                                    <Typography>
                                        {bedrockResponse.trim().split("\n").map(paragraph => {
                                            return (
                                                <Typography>{paragraph}<br></br></Typography>
                                            )
                                        })}
                                    </Typography> :
                                    <Typography>Generating, please wait...</Typography>
                            }
                        </Box>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button sx={{ width: "50%" }} color="primary" onClick={() => {
                            setOpen(false);
                        }}>
                            Back
                        </Button>
                        <Button disabled sx={{ width: "50%" }} color="danger" onClick={() => {
                            console.log("click")
                        }}>
                            It's not me (Report)
                        </Button>
                        <Button disabled sx={{ width: "50%" }} color="success" onClick={() => {
                            console.log("click")
                        }}>
                            It's me (Dismiss)
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
            <Card variant="solid" color={alert.severity === "high" ? "danger" : "warning"} invertedColors sx={{
                width: "100%",
                transition: "transform .2s",
                ":hover": {
                    transform: "scale(1.03)"
                },
                cursor: "pointer",
                mt: 2
            }} onClick={
                () => {
                    setOpen(true);
                }
            }>
                <CardContent orientation="horizontal">
                    <CircularProgress size="lg" determinate value={100}>
                        <l-ripples
                            size="100"
                            speed="5"
                            color={theme.palette.neutral.solidColor}
                        ></l-ripples>
                    </CircularProgress>
                    <CardContent>
                        <Typography level="h4">{alert.title}</Typography>
                        <Typography level="body-md">{new Date(problemState.logonDateTime ? problemState.logonDateTime : alert.createdDateTime).toUTCString()}</Typography>
                    </CardContent>
                </CardContent>
            </Card>
        </>
    )
}

export default function SecurityDashboard() {
    const { securityAlerts } = useGlobalContext();
    // eslint-disable-next-line
    const [hash, setHash] = useHash();

    useEffect(() => {
        if (hash.includes("security")) {
            setHash("/security");
        }
    }, [hash]);

    return (
        <Box
            sx={{
                flex: 1,
                pl: 2,
                pr: 2,
                pt: 0,
                maxWidth: 1200,
                width: '100%',
                mx: 'auto'
            }}>
            <Box sx={{
                width: {
                    xs: "90%"
                }
            }}>
                <Header icon={<Shield />} title={"Security Dashboard"}></Header>
                <Typography mb={2}>An overview of your recent activity and any alerts that may be active on your account.</Typography>
            </Box>
            <IconButton
                sx={{
                    display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                    },
                    position: "absolute",
                    top: 0,
                    right: 0,
                    pt: 0.5,
                    m: 2,
                    zIndex: 5
                }}
                onClick={() => toggleSidebar()}
            >
                <MoreVert sx={{ color: "white" }} />
            </IconButton>
            <Tabs
                value={0}
                sx={{ bgcolor: 'transparent' }}>
                <TabList
                    sticky="top"
                    variant="plain"
                    sx={tabTheme}
                >
                    <Tab value={0} key={0}>
                        <Box>Overview <sup style={{ color: "#bf4526" }}>Beta</sup></Box>
                    </Tab>
                </TabList>
                <TabPanel sx={{
                    padding: 0
                }}
                    value={0}>
                    <Box
                        //#region Page Layout
                        sx={{
                            pt: 3,
                            pb: 10,
                            display: 'grid',
                            gridTemplateColumns: {
                                xs: '100%',
                                sm: '100%'
                            },
                            columnGap: { xs: 2, sm: 3, md: 4 },
                            rowGap: { xs: 2, sm: 1 },
                            '& > hr': {
                                gridColumn: '1/-1',
                            },
                        }}
                    //#endregion
                    >
                        <AccordionGroup transition={"0.2s"} sx={{
                            p: 0,
                            m: 0
                        }}>
                            <Accordion defaultExpanded={true} sx={{
                                p: 0,
                                m: 0
                            }}>
                                <AccordionSummary key={`as_drs`} variant="plain">
                                    <Typography level="h4" startDecorator={<Person />}>Active Alerts</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>These alerts are currently active on your account, please review them and take action as soon as possible.</Typography>
                                    <Box
                                        key={"drs"}
                                        sx={{
                                            padding: 0,
                                            display: 'grid',
                                            justifyContent: "space-between",
                                            gridTemplateColumns: {
                                                xs: '100%',
                                                sm: '50% 50%',
                                                lg: 'repeat(auto-fit, 32%) 32%',
                                            },

                                            mb: 4,
                                            '& > hr': {
                                                gridColumn: '1/-1',
                                            },
                                        }}
                                    >
                                        {
                                            !securityAlerts &&
                                            <Card variant="soft" color="neutral" invertedColors sx={{
                                                width: "100%",
                                                transition: "transform .2s",
                                                ":hover": {
                                                    transform: "scale(1.03)"
                                                },
                                                mt: 2
                                            }}>
                                                <CardContent orientation="horizontal">
                                                    <CircularProgress size="lg"><Pending /></CircularProgress>
                                                    <CardContent>
                                                        <Typography level="h4">
                                                            <Skeleton animation="wave">Placeholder event</Skeleton>
                                                        </Typography>
                                                        <Typography level="body-md"><Skeleton animation="wave">Placeholder location</Skeleton></Typography>
                                                    </CardContent>
                                                </CardContent>
                                            </Card>

                                        }
                                        {(securityAlerts && securityAlerts.length > 0) &&
                                            securityAlerts.map(alert => {
                                                return (
                                                    <AlertCard alert={alert}></AlertCard>
                                                )
                                            })
                                        }
                                        {
                                            (securityAlerts && securityAlerts.length === 0) &&
                                            <Typography>Looks like there are no alerts on your account, so all good!</Typography>
                                        }
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionGroup>
                    </Box>
                </TabPanel>
            </Tabs>
        </Box>
    )
}