import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import Chip from "@mui/joy/Chip";

const getExpiryDateString = (user: AccountInfo | null | undefined) => {
    if (user?.Basic?.contractEnd) {
        if (["myteam_ef", "myteam_hult"].includes(user?.Basic?.integrationPlatform || "none")) {
            return ("Active Account");
        } else {
            return (`Expires ${new Date(user?.Basic?.contractEnd || "").toDateString()}`);
        }
    } else {
        return (`Invalid Expiry Date`);
    }
}

export const getExpiryDateChip = (profile: AccountInfo | undefined | null, simple?: boolean) => {
    const diff = Math.ceil((new Date(profile?.Basic?.contractEnd || "").getTime() - new Date().getTime()) / (1000 * 3600 * 24));
    if (profile?.Protected?.forceLockout) {
        return (
            <Chip
                size="lg"
                variant="soft"
                color="danger"
                sx={{
                    width: "100%",
                    mt: -4,
                    mb: 1,
                    pt: "5px",
                    pb: "5px",
                    border: '2px solid',
                    borderColor: 'background.surface',
                }}
            >
                Quarantined
            </Chip>
        )
    }
    if (diff > 14 && profile?.accountEnabled) {
        return (
            <Chip
                size="lg"
                variant="soft"
                color="success"
                sx={{
                    width: "100%",
                    mt: -4,
                    mb: 1,
                    pt: "5px",
                    pb: "5px",
                    border: '2px solid',
                    borderColor: 'background.surface',
                }}
            >
                {simple ? "Active Account" : getExpiryDateString(profile)}
            </Chip>
        );
    } else if (diff > 0 && profile?.accountEnabled) {
        return (
            <Chip
                size="lg"
                variant="soft"
                color="warning"
                sx={{
                    width: "100%",
                    mt: -4,
                    mb: 1,
                    pt: "5px",
                    pb: "5px",
                    border: '2px solid',
                    borderColor: 'background.surface',
                }}
            >
                {simple ? "Active Account" : getExpiryDateString(profile)}
            </Chip>
        );
    } else if (diff <= 0 && profile?.accountEnabled) {
        return (
            <Chip
                size="lg"
                variant="soft"
                color="warning"
                sx={{
                    width: "100%",
                    mt: -4,
                    mb: 1,
                    pt: "5px",
                    pb: "5px",
                    border: '2px solid',
                    borderColor: 'background.surface',
                }}
            >
                {simple ? "Expires Soon" : getExpiryDateString(profile)}
            </Chip>
        );
    } else {
        return (
            <Chip
                size="lg"
                variant="soft"
                color="danger"
                sx={{
                    width: "100%",
                    mt: -4,
                    mb: 1,
                    pt: "5px",
                    pb: "5px",
                    border: '2px solid',
                    borderColor: 'background.surface',
                }}
            >
                Account Disabled
            </Chip>
        );
    }
}