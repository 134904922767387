import { AccountInfo, NewUserProps } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { Badge, InfoOutlined, Payment, Person, SupervisorAccount } from "@mui/icons-material";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress, Modal, ModalDialog, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../Context";
import { Profile } from "../../utilities/identity";
import FormSubmittedModal from "../AccountProfile/FormSubmittedModal";

interface CreateConfirmationModalProps {
    isOpen: boolean;
    setIsOpen: any;
    createPayload: NewUserProps;

}

export function CreateConfirmationModal({ isOpen, setIsOpen, createPayload }: CreateConfirmationModalProps) {
    const { core } = useGlobalContext();
    const [upn, setUpn] = useState(null);
    const [createResponse, setCreateResponse] = useState<any>();

    const [submittedModalOpen, setSubmittedModalOpen] = useState(false);

    const getUpn = () => {
        const { givenName, surname, accountType } = createPayload;

        if (core) {
            Profile.retrieveUpn(core, { givenName, surname, accountType } as unknown as AccountInfo).then(({ statusCode, body }) => {
                if (statusCode === 200) {
                    setUpn((body as any).userPrincipalName);
                }
            })
        }
    }
    const setModal = (modalStatus: boolean) => {
        setSubmittedModalOpen(modalStatus);
    }

    const createUser = () => {
        if (core) {
            setSubmittedModalOpen(true);
            Profile.createAccount(core, { ...createPayload, "integrationPlatform": "none" }).then((createResponse) => {
                setCreateResponse(createResponse)
            });
        }
    }

    useEffect(() => {
        setUpn(null);
        getUpn();
    }, []);

    useEffect(() => {
        if (createResponse && submittedModalOpen === false) {
            if (createResponse.statusCode !== 200) {
                setIsOpen(false);
            }
        }
    }, [createResponse, isOpen, submittedModalOpen]);

    return (
        <>{
            submittedModalOpen ?
                <FormSubmittedModal setIsOpen={setModal} isOpen={submittedModalOpen}
                    openModal={createResponse !== null}
                    user={`${createPayload.givenName} ${createPayload.surname}`}
                    returnInformation={createResponse} modalType="create" />
                :
                <Modal open={isOpen} onClose={() => setIsOpen(false)} >
                    <ModalDialog color='primary' role="dialog" >
                        <DialogTitle >
                            <InfoOutlined />
                            Account Details
                        </DialogTitle>
                        <Divider />

                        {upn ?
                            <>
                                <DialogContent>
                                    <Typography>
                                        We've generate a username and cost string for the account based on your input, please verify they are correct before you continue.
                                        <br />By clicking "Confirm", you are agreeing to create a new employee account with the above details.
                                    </Typography>

                                    <Box sx={{
                                        display: "flex",
                                        mt: 4
                                    }}>
                                        <Box sx={{
                                            p: 2
                                        }} mr={2}><Badge /></Box>
                                        <Box>
                                            <Typography level="title-lg">Account Type</Typography>
                                            <Typography level="body-md">{createPayload.accountType}</Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{
                                        display: "flex"
                                    }}>
                                        <Box sx={{
                                            p: 2
                                        }} mr={2}><Person /></Box>
                                        <Box>
                                            <Typography level="title-lg">Username</Typography>
                                            <Typography level="body-md">{upn}</Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{
                                        display: "flex"
                                    }}>
                                        <Box sx={{
                                            p: 2
                                        }} mr={2}><SupervisorAccount /></Box>
                                        <Box>
                                            <Typography level="title-lg">Manager</Typography>
                                            <Typography level="body-md">{createPayload.managerUpn}</Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{
                                        display: "flex"
                                    }}>
                                        <Box sx={{
                                            p: 2
                                        }} mr={2}><Payment /></Box>
                                        <Box>
                                            <Typography level="title-lg">Cost String</Typography>
                                            <Typography level="body-md">{`${createPayload.company}.0.${createPayload.product}.${createPayload.salesMarket}.${createPayload.productMarket}.${createPayload.costCenter}.0.0.0`}</Typography>
                                        </Box>
                                    </Box>

                                </DialogContent><DialogActions>
                                    <Button
                                        onClick={() => createUser()}
                                        variant="plain" color="primary">
                                        Confirm
                                    </Button>
                                    <Button variant="plain" color="neutral" onClick={() => setIsOpen(false)}>
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </>
                            :
                            <DialogContent>
                                <Typography>Please wait while we verify the information provided.</Typography>
                                <LinearProgress />
                            </DialogContent>
                        }
                    </ModalDialog>
                </Modal>}
        </>
    );
}