
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Button from '@mui/joy/Button';
import DialogActions from '@mui/joy/DialogActions';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';

interface InvalidFormModalProps {
    message: string;
    isOpen: boolean;
    setIsOpen: any
}
export default function InvalidFormModal({ message, isOpen, setIsOpen }: InvalidFormModalProps) {
    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <ModalDialog color='danger' variant="outlined" role="alertdialog">
                <DialogTitle >
                    <WarningRoundedIcon />
                    Invalid Form Information
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {message}
                </DialogContent>
                <DialogActions>
                    <Button variant="plain" color="neutral" onClick={() => setIsOpen(false)}>
                        OK
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}