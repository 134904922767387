import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Box, Button, DialogContent, DialogTitle, Divider, Modal, ModalDialog } from "@mui/joy";


interface WarningModalProps {
    warningModalOpen: boolean;
    setWarningModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    email: string;
}


export default function WarningModal({ warningModalOpen, setWarningModalOpen, email }: WarningModalProps) {
    return (
        <Modal open={warningModalOpen} onClose={() => setWarningModalOpen(false)}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <WarningRoundedIcon />
                    Warning
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {`The address ${email} is already being used.`}
                </DialogContent>
                <Box
                    id="warning-modal-buttons"
                    sx={{
                        justifyContent: 'flex-end',
                        gap: 1,
                        display: 'flex'
                    }}
                >
                    <Button
                        variant="solid" color="primary"
                        onClick={() => {
                            setWarningModalOpen(false);
                        }}>OK</Button>
                </Box>
            </ModalDialog>
        </Modal>
    )
}