import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { Lock, Tune } from "@mui/icons-material";
import { Button, Chip, FormControl, FormLabel, Input, Tooltip, useTheme } from "@mui/joy";
import { useState } from "react";
import ProxyModal from "./ProxyModal";
import { FormInputProps } from "./constructs";

export default function MenuFormInput({ label, name, value, type, isLocked, userProfile }: FormInputProps) {
    const [proxyModalOpen, setProxyModalOpen] = useState<boolean>(false);
    const theme = useTheme();

    return (
        <>
            <Tooltip title="Locked" placement="right" size="sm" arrow sx={{
                display: !isLocked ? "none" : undefined
            }}>
                <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                    <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{label}</FormLabel>
                    <Input
                        type={type}
                        name={name}
                        disabled={isLocked}
                        value={value}
                        startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{label}</Chip>}
                        endDecorator={!isLocked ?
                            <Button
                                variant="plain"
                                size="sm"
                                sx={{ color: "grey" }}
                                onClick={() => {
                                    setProxyModalOpen(true);
                                }}
                                endDecorator={<Tune sx={theme.palette.mode === "dark" ? { color: "#CFD8DC" } : { color: "#636b74" }} />}
                            ></Button> : <Lock sx={theme.palette.mode === "dark" ? { color: "#CFD8DC" } : { color: "#636b74" }} />
                        }
                    />
                </FormControl>
            </Tooltip>
            <ProxyModal proxyModalOpen={proxyModalOpen} setProxyModalOpen={setProxyModalOpen}
                userProfile={userProfile as AccountInfo} />
        </>
    )
}