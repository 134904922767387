import { Typography } from "@mui/joy";

interface HeaderProps {
    title: string;
    icon: any;
}

export default function Header({ title, icon }: HeaderProps) {
    return (
        <Typography startDecorator={icon} level="h1" fontSize="xl2" sx={{ mb: 1, paddingTop: 2}}>
            {title}
        </Typography>
    )
}