import { AccountInfo as MsalAccountInfo } from '@azure/msal-browser';
import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { Core } from "../core";


export interface IsUsedResponse {
    used: boolean;
    description: string;
}

export interface DirectReportsResponse {
    skipToken?: string;
    value: AccountInfo[];
}

export class Profile {
    public account!: MsalAccountInfo;
    public profile!: AccountInfo;
    public id!: string;
    private core: Core;

    constructor(core: Core, account?: MsalAccountInfo, uuid?: string) {
        this.core = core;
        this.id = account ? account.localAccountId : uuid ? uuid : "";
    }

    public retrieve = async () => {
        this.profile = ((await this.core.invokeIntegrationHubRequest('GET', `/identity/users/${this.id}`, undefined, {
            "Cache-Control": "no-cache"
        })).body) as AccountInfo;
    }


    public static queryLookup = async (core: Core, input: string): Promise<AccountInfo[]> => {
        if (input && input.length > 0) {
            const possibleUsers = (await core.invokeIntegrationHubRequest('GET', `/identity/users/search/${input}`)).body as AccountInfo[];
            return possibleUsers;
        } else {
            return [];
        }
    }

    public static getUserByID = async (core: Core, id: string): Promise<AccountInfo[]> => {
        if (id && id.length > 0) {
            const possibleUsers = (await core.invokeIntegrationHubRequest('GET', `/identity/users/${id}`)).body as AccountInfo;
            return [possibleUsers];
        } else {
            return [];
        }
    }

    public static retrieveProfile = async (core: Core, account: MsalAccountInfo): Promise<AccountInfo> => {
        const profile = new Profile(core, account);
        await profile.retrieve();
        return profile.profile;
    }

    public static retrieveDirectReports = async (core: Core, account: MsalAccountInfo): Promise<AccountInfo[]> => {
        const profile = new Profile(core, account);
        const endpoint = `/identity/users/${profile.id}/direct-reports`;
        const directReports = await this.retrieveAllDirectReports(core, endpoint);
        return directReports;
    }

    public static reloadDirectReports = async (core: Core, rootUser: AccountInfo): Promise<AccountInfo[]> => {
        const endpoint = `/identity/users/${rootUser.id}/direct-reports`;
        const directReports = await this.retrieveAllDirectReports(core, endpoint);
        return directReports;
    }

    private static retrieveAllDirectReports = async (core: Core, endpoint: string): Promise<AccountInfo[]> => {
        let allDirectReports: AccountInfo[] = [];
        let currentEndpoint = endpoint;
        let skipToken = true;

        while (skipToken) {
            const IHResponse = await core.invokeIntegrationHubRequest("GET", currentEndpoint);
            if (IHResponse.statusCode === 200) {
                const responseBody = IHResponse.body as DirectReportsResponse;
                allDirectReports = allDirectReports.concat(responseBody.value);
                if (responseBody.skipToken) {
                    currentEndpoint = `${endpoint}?skipToken=${responseBody.skipToken}`
                } else {
                    skipToken = false;
                }
            } else {
                throw new Error(`Unable to retrieve direct reports for user with endpoint: ${currentEndpoint}`);
            }
        }
        return allDirectReports;
    }

    public static reloadProfile = async (core: Core, rootUser: AccountInfo): Promise<AccountInfo> => {
        return ((await core.invokeIntegrationHubRequest('GET', `/identity/users/${rootUser.id}`, undefined, {
            "Cache-Control": "no-cache"
        })).body) as AccountInfo;
    }

    public static isEmailUsed = async (core: Core, email: string): Promise<boolean> => {
        const IHResponse = await core.invokeIntegrationHubRequest('POST', `/identity/users/validate/email`, {
            "email": email
        });
        if (IHResponse.statusCode === 200) {
            const isUsedResponse = IHResponse.body as IsUsedResponse;
            return isUsedResponse.used;
        } else {
            throw new Error(`Unable to validate email ${email}.`)
        }
    }

    public static updateAccount = async (core: Core, user: string, updatePayload: any): Promise<{
        statusCode: number, body: string | object;
    }> => {
        const updateResponse = await core.invokeIntegrationHubRequest("PATCH", `/identity/users/${user}`, updatePayload, {
            "Content-Type": "application/json"
        });
        return updateResponse;
    }

    public static createAccount = async (core: Core, createPayload: any): Promise<{
        statusCode: number, body: string | object;
    }> => {

        const createResponse = await core.invokeIntegrationHubRequest("POST", `/identity/users`, createPayload, {
            "Content-Type": "application/json"
        });

        return createResponse;
    }

    public static expireAccount = async (core: Core, user: string, message?: string): Promise<{
        statusCode: number, body: string | object;
    }> => {
        const updateResponse = await core.invokeIntegrationHubRequest("POST", `/identity/users/expire/${user}`, {
            "message": message
        });

        return updateResponse;
    }

    public static deleteAccount = async (core: Core, user: string): Promise<{
        statusCode: number, body: string | object;
    }> => {
        const deleteResponse = await core.invokeIntegrationHubRequest("DELETE", `/identity/users/${user}`);
        return deleteResponse;
    }

    public static recertifyAccount = async (core: Core, user: string): Promise<{ statusCode: number, body: string | object; }> => {
        const today = new Date();
        const sixMonthsInFuture = new Date(new Date().setDate(today.getDate() + 185));
        const updateResponse = await core.invokeIntegrationHubRequest("PATCH", `/identity/users/${user}`, {
            contractEnd: sixMonthsInFuture.toISOString()
        }, {
            "Content-Type": "application/json"
        });

        return updateResponse;
    }

    public static retrieveUpn = async (core: Core, user: AccountInfo): Promise<{ statusCode: number, body: string | object; }> => {
        const upnResponse = await core.invokeIntegrationHubRequest("POST", "/identity/users/generate-upn", user, {
            "Content-Type": "application/json"
        });

        return upnResponse;
    }

    public static resetMfa = async (core: Core, user: string): Promise<{ statusCode: number, body: string | object; }> => {
        const mfaResponse = await core.invokeIntegrationHubRequest("POST", `/identity/users/${user}/reset-mfa`, {
            "Content-Type": "application/json"
        });
        return mfaResponse;
    }

    //#region 365 Resources
    public static getOwnedApps = async (core: Core, user: string): Promise<{ statusCode: number, body: string | object; }> => {
        const appResponse = await core.invokeIntegrationHubRequest("GET", `/identity/users/${user}/apps`);
        return appResponse;
    }
    public static getOwnedDevices = async (core: Core, user: string): Promise<{ statusCode: number, body: string | object; }> => {
        const appResponse = await core.invokeIntegrationHubRequest("GET", `/identity/users/${user}/devices`);
        return appResponse;
    }
    //#endregion

    //#region Role requests.
    public static requestSearchRole = async (core: Core, searchRoleRequest: any): Promise<{
        statusCode: number, body: string | object;
    }> => {
        const updateResponse = await core.invokeIntegrationHubRequest("POST", `/helpdesk/sr/iaa/roles/search`, searchRoleRequest, {
            "Content-Type": "application/json"
        });
        return updateResponse;
    }

    public static requestCreateRole = async (core: Core, createRoleRequest: any): Promise<{
        statusCode: number, body: string | object;
    }> => {
        const updateResponse = core.invokeIntegrationHubRequest("POST", `/helpdesk/sr/iaa/roles/create`, createRoleRequest, {
            "Content-Type": "application/json"
        });
        return updateResponse;
    }

    public static getOpenRequests = async (core: Core, rootUser: AccountInfo): Promise<{
        statusCode: number, body: string | object;
    }> => {
        const updateResponse = await core.invokeIntegrationHubRequest("GET", `/helpdesk/sr/iaa/roles?type=Service+Request&email=${rootUser.userPrincipalName}&filter=new_and_my_open`);
        return updateResponse;
    }
    //#endregion
}