import { Error, Lock } from "@mui/icons-material";
import { Chip, FormControl, FormLabel, Input, Skeleton, Tooltip, useTheme } from "@mui/joy";
import { useState } from "react";
import { useGlobalContext } from "../../Context";
import { FormInputProps } from "./constructs";
import { verifyEmail, verifyPhoneNumber } from "./utils";


export default function GenericFormInput({ label, name, value, type, isLocked }: FormInputProps) {
    const { profile } = useGlobalContext();
    const [valid, setValid] = useState(true);
    const theme = useTheme();

    const checkEmailValid = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValid(verifyEmail(event.target.value));
    }

    const checkPhoneValid = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValid(verifyPhoneNumber(event.target.value));
    }

    return (
        <Tooltip title="Locked" placement="right" size="sm" arrow sx={{
            display: !isLocked ? "none" : undefined
        }}>
            <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{label}</FormLabel>
                <Input
                    onChange={label === "Personal Email" ?
                        checkEmailValid :
                        ["Personal Phone", "Work Phone"].includes(label)
                            ? checkPhoneValid :
                            undefined}
                    required={
                        ["First Name", "Last Name", "Personal Email", "Job Title"].includes(label) ? true : false
                    }
                    type={type}
                    name={name}
                    defaultValue={value && value.trim()}
                    disabled={isLocked}
                    autoComplete="none"
                    endDecorator={
                        !valid ?
                            <Tooltip variant="outlined" title={`Not a valid ${label}`} arrow>
                                <Error sx={{
                                    color: "red"
                                }} />
                            </Tooltip>
                            :
                            isLocked ? <Lock sx={theme.palette.mode === "dark" ? { color: "#CFD8DC" } : { color: "#636b74" }} />
                                : undefined}
                    startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{label}</Chip>}
                />
                <Skeleton loading={profile ? false : true} animation="wave"></Skeleton>
            </FormControl>
        </Tooltip>
    )

}