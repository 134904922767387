import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { Assessment, BusinessCenter, DoDisturb, Flight, MoreVert, RecentActors, SearchSharp, Visibility } from '@mui/icons-material';
import { Autocomplete, AutocompleteOption, Avatar, Box, Checkbox, Chip, Divider, FormControl, FormLabel, Grid, IconButton, Input, LinearProgress, ListItemContent, ListItemDecorator, Radio, RadioGroup, TabList, TabPanel, Tabs, Textarea, Theme, Typography, chipClasses } from '@mui/joy';
import Tab, { tabClasses } from "@mui/joy/Tab";
import { useEffect, useState } from 'react';
import { useHash } from 'react-use';
import { useGlobalContext } from '../../Context';
import { Core } from '../../utilities/core';
import { Profile } from '../../utilities/identity';
import Header from '../Home/Header';
import { toggleSidebar } from '../utils';

import AvatarCard from '../AccountProfile/AvatarCard';

const tabTheme = (theme: Theme) => {
    return {
        '--Chip-minHeight': '20px',
        '--ListItem-minHeight': '48px',
        top: 'calc(-1 * (var(--main-paddingTop, 0px) - var(--Header-height, 0px)))',
        zIndex: 10,
        width: '100%',
        overflow: 'auto hidden',
        alignSelf: 'flex-start',
        scrollSnapType: 'inline',
        '&::after': {
            pointerEvents: 'none',
            display: { xs: 'block', sm: 'none' },
            content: '""',
            position: 'sticky',
            top: 0,
            width: 40,
            flex: 'none',
            zIndex: 1,
            right: 0,
            borderBottom: '1px solid transparent',
            backgroundClip: 'content-box',
        },
        '&::-webkit-scrollbar': {
            width: 0,
            display: 'none',
        },
        [`& .${tabClasses.root}`]: {
            '--focus-outline-offset': '-2px',
            scrollSnapAlign: 'start',
            bgcolor: 'transparent',
            flex: 'none',
            '&:hover': {
                bgcolor: 'transparent',
            },
            [`&.${tabClasses.selected}`]: {
                color: 'primary.plainColor',
                bgcolor: 'transparent',
                [`& .${chipClasses.root}`]: theme.variants.solid.primary,
            },
        },
    }
}

interface EmergencyInfo {
    label: string
    office: string
    phone: string
}

export default function FlightMapper() {
    // eslint-disable-next-line
    const [hash, setHash] = useHash();
    const [profiles, setProfiles] = useState([] as AccountInfo[]);
    const [selectedUpn, setSelectedUpn] = useState<string>();
    const [selectedProfile, setSelectedProfile] = useState<AccountInfo>();
    const { core, rootUser } = useGlobalContext();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ending, setEnding] = useState<string>("SB");
    const [gender, setGender] = useState<string>("");
    const [kickoff, setKickoff] = useState<string>("");
    const [reason, setReason] = useState<number>(5311);
    const [emergency, setEmergency] = useState<EmergencyInfo>();
    const [dob, setDOB] = useState<string>();

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [middleName, setMiddleName] = useState<string>("");

    const [manager, setManager] = useState<boolean>(false);
    const [freeFlight, setFreeFlight] = useState<boolean>(false);
    const [semicolon, setSemicolon] = useState<boolean>(true);

    const [amadeus, setAmadeus] = useState<string>("");

    useEffect(() => {
        if (core && selectedUpn) {
            setIsLoading(true);
            setSelectedProfile(undefined);
            Profile.getUserByID(core, selectedUpn).then((profileResp) => {
                setIsLoading(false);
                setFirstName(profileResp[0].givenName);
                setLastName(profileResp[0].surname);
                setSelectedProfile(profileResp[0]);
            });
        }
    }, [selectedUpn])

    useEffect(() => {
        setHash("#/travel/mapper");
    }, []);

    useEffect(() => {
        setAmadeus(generateAmadeusText());
    }, [
        ending,
        gender,
        kickoff,
        reason,
        selectedProfile,
        emergency,
        manager,
        freeFlight,
        semicolon,
        dob,
        firstName,
        lastName,
        middleName
    ]);

    const profileLookup = async (core: Core, name: string): Promise<AccountInfo[]> => {
        const nameList = await Profile.queryLookup(core, name);
        return nameList;
    }

    let timeout: NodeJS.Timeout;
    const handleProfileLookup = async (event: any) => {
        if (core) {
            const searchTerm = event.target ? (event.target as HTMLTextAreaElement).value : "";
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                if (searchTerm && searchTerm.length > 0) {
                    profileLookup(core, searchTerm).then((nameList) => {
                        setProfiles(nameList);
                    });
                } else {
                    setProfiles([]);
                }
            }, 500);
        }
    }

    const generateAmadeusText = () => {
        if (selectedProfile) {
            const fullProfile = (selectedProfile as any as AccountInfo);
            let dobString = "";
            if (dob) {
                const dobDate = new Date(dob);
                dobString = `${dobDate.getDay()}${dobDate.toLocaleString('default', { month: 'long' })}${dobDate.getFullYear().toString().substr(-2)}`
            }
            const dobField = `SR DOCSYYHK1-----${dobString}-M—${lastName.toUpperCase()}/${firstName.toUpperCase()}${middleName ? ` ${middleName.toUpperCase()}` : ""}/P1`;
            return `
        NM1${lastName.toUpperCase()}/${firstName.toUpperCase()} ${gender.toUpperCase()}${semicolon ? ";" : ""}${dob ? `\n${dobField}${semicolon ? ";" : ""}` : ""}
        APE-${fullProfile.mail.toUpperCase()}${semicolon ? ";" : ""}${manager ? `\nAPE-${fullProfile.manager.mail.toUpperCase()}${semicolon ? ";" : ""}` : ""}
        AP EF TRAVEL ${firstName.toUpperCase()} ${lastName.toUpperCase()}${semicolon ? ";" : ""}
        AP STAFF TRAVEL +1-617-619-2636${semicolon ? ";" : ""}
        FM0${semicolon ? ";" : ""}
        FPCHECK${semicolon ? ";" : ""}
        RII EMERGENCY CONTACT STAFF TRAVEL ${emergency?.phone}${semicolon ? ";" : ""}
        RM*ACECRM-CA1-${reason}/CA2-${fullProfile.Finance?.productMarket}/CA3-${fullProfile.Finance?.salesMarket}/CA4-${fullProfile.Finance?.costCenter}${semicolon ? ";" : ""}
        RM*ACECRM-SA1-${reason}/SA2-${fullProfile.Finance?.productMarket}/SA3-${fullProfile.Finance?.salesMarket}/SA4-${fullProfile.Finance?.costCenter}${semicolon ? ";" : ""}
        RM*ACECLN-${fullProfile.Finance?.company}${ending}${semicolon ? ";" : ""}
        RM*ACECRM-AMM-STAFF${semicolon ? ";" : ""}${kickoff ? "\nRM*ACECRM-KO-" + kickoff + (semicolon ? ";" : "") : ""}${freeFlight ? `\nRM*ACECRM-CA8-FF${reason}${semicolon ? ";" : ""}` : ""}${freeFlight ? `\nRM*ACECRM-SA8-FF${reason}${semicolon ? ";" : ""}` : ""}                
        SR*CTCM-16176191600${semicolon ? ";" : ""}
        SR*CTCE- ${fullProfile.mail.toUpperCase().split("@").join("//")}${semicolon ? ";" : ""}
        RU1AHK1${emergency?.office}22MAR/HOLDPNR${semicolon ? ";" : ""}
        `.trim().split("  ").join("")
        } else {
            return "";
        }
    }

    // const generateSabreText = () => {
    //     const dobField = `SR DOCSYYHK1-----28May81-M—LAST NAME/FIRST NAME SECOND NAME/P1`;
    //     if (selectedProfile) {
    //         const fullProfile = (selectedProfile as any as AccountInfo);
    //         return `
    //     NM1${fullProfile.surname.toUpperCase()}/${fullProfile.givenName.toUpperCase()} ${gender.toUpperCase()}${semicolon ? ";" : ""}
    //     APE-${fullProfile.mail.toUpperCase()}${semicolon ? ";" : ""}${manager ? `\nAPE-${fullProfile.manager.mail.toUpperCase()}${semicolon ? ";" : ""}` : ""}
    //     AP EF TRAVEL ${fullProfile.givenName.toUpperCase()} ${fullProfile.surname.toUpperCase()}${semicolon ? ";" : ""}
    //     AP STAFF TRAVEL +1-617-619-2636${semicolon ? ";" : ""}
    //     FM0${semicolon ? ";" : ""}
    //     FPCHECK${semicolon ? ";" : ""}
    //     RII EMERGENCY CONTACT STAFF TRAVEL ${emergency?.phone}${semicolon ? ";" : ""}
    //     RM*ACECRM-CA1-${reason}/CA2-${fullProfile.Finance?.productMarket}/CA3-${fullProfile.Finance?.salesMarket}/CA4-${fullProfile.Finance?.costCenter}${semicolon ? ";" : ""}
    //     RM*ACECRM-SA1-${reason}/SA2-${fullProfile.Finance?.productMarket}/SA3-${fullProfile.Finance?.salesMarket}/SA4-${fullProfile.Finance?.costCenter}${semicolon ? ";" : ""}
    //     RM*ACECLN-${fullProfile..Finance?.company}${ending}${semicolon ? ";" : ""}
    //     RM*ACECRM-AMM-STAFF${semicolon ? ";" : ""}${kickoff ? "\nRM*ACECRM-KO-" + kickoff + (semicolon ? ";" : "") : ""}${freeFlight ? `\nRM*ACECRM-CA8-FF${reason}${semicolon ? ";" : ""}` : ""}${freeFlight ? `\nRM*ACECRM-SA8-FF${reason}${semicolon ? ";" : ""}` : ""}                
    //     SR*CTCM-16176191600${semicolon ? ";" : ""}
    //     SR*CTCE- ${fullProfile.mail.toUpperCase().split("@").join("//")}${semicolon ? ";" : ""}
    //     RU1AHK1${emergency?.office}22MAR/HOLDPNR${semicolon ? ";" : ""}
    //     `.trim().split("  ").join("")
    //     } else {
    //         return "";
    //     }
    // }

    return (
        (((rootUser?.RBAC?.iaaRole || []) as any).includes("staffTravel") || (rootUser?.RBAC?.iaaRole || []).includes('globalAdmin')) ?
            <Box
                sx={{
                    flex: 1,
                    pl: 2,
                    pr: 2,
                    pt: 0,
                    maxWidth: 1200,
                    width: '100%',
                    mx: 'auto',
                }}>
                <Header icon={<RecentActors />} title={'Staff Travel'}></Header>
                <Typography mb={2}>
                    This section provides utilities for staff travel to obtain and manage information on staff accounts across EF.
                </Typography>
                <IconButton
                    sx={{
                        display: {
                            xs: "block",
                            sm: "block",
                            md: "none",
                            lg: "none",
                        },
                        position: "absolute",
                        top: 0,
                        right: 0,
                        pt: 0.5,
                        m: 2,
                        zIndex: 5
                    }}
                    onClick={() => toggleSidebar()}
                    color="neutral"
                >
                    <MoreVert sx={{ color: "white" }} />
                </IconButton>
                <Tabs
                    value={0}
                    sx={{ bgcolor: 'transparent' }}>
                    <TabList
                        //#region Tab Theme
                        sticky="top"
                        variant="plain"
                        sx={tabTheme}
                    //#endregion
                    >
                        <Tab value={0} key={0}>
                            <Box>Flight Mapper <sup style={{ color: "#bf4526" }}>Beta</sup></Box>
                        </Tab>
                    </TabList>
                    <Box
                        //#region Container Theme
                        sx={{
                            height: 0,
                            position: 'sticky',
                            top: 'calc(48px - var(--main-paddingTop, 0px) + var(--Header-height, 0px) - (var(--_shadow-height) / 2))',
                            zIndex: 1,
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'relative',
                                zIndex: 1,
                                height: 'var(--_shadow-height)',
                            },
                        }}
                    //#endregion
                    >
                        <TabPanel
                            sx={{
                                padding: 0
                            }}
                            key={0}
                            value={0}>
                            <Box sx={{
                                display: { xs: 'grid', sm: 'grid' },
                                flexWrap: "wrap",
                                gap: 2,
                                pb: 3
                            }}>
                                <Autocomplete
                                    sx={{
                                        mt: 3
                                    }}
                                    placeholder="Search here..."
                                    variant="soft"
                                    size="lg"
                                    endDecorator={<SearchSharp />}
                                    options={profiles}
                                    getOptionLabel={option => option.displayName}
                                    onKeyUp={handleProfileLookup}
                                    onChange={(evt, val, reason) => {
                                        if (reason === "selectOption") {
                                            setSelectedUpn((val as any).id);
                                        }
                                    }}
                                    isOptionEqualToValue={() => {
                                        return true;
                                    }}
                                    renderOption={(props, option) => (
                                        <AutocompleteOption {...props}>
                                            <ListItemDecorator>
                                                <Avatar />
                                            </ListItemDecorator>
                                            <ListItemContent sx={{ fontSize: 'sm' }}>
                                                {option.displayName}
                                                <Typography level="body-xs">
                                                    {option.userPrincipalName}
                                                </Typography>
                                            </ListItemContent>
                                        </AutocompleteOption>
                                    )}
                                />
                            </Box>
                            {
                                isLoading &&
                                <LinearProgress />
                            }
                            {
                                (selectedProfile) &&
                                <Box sx={{
                                    display: { xs: 'grid', sm: 'grid' },
                                    flexWrap: "wrap",
                                    gap: 2,
                                    pb: 3
                                }}>
                                    <Divider role="presentation" sx={{ gridColumn: "1/-1" }}>
                                        <Chip size='lg' startDecorator={<Visibility />}>
                                            Overview
                                        </Chip>
                                    </Divider>
                                    <AvatarCard profile={selectedProfile as any as AccountInfo}></AvatarCard>
                                    <Divider role="presentation" sx={{ gridColumn: "1/-1" }}>
                                        <Chip size='lg' startDecorator={<RecentActors />}>
                                            Additional Information
                                        </Chip>
                                    </Divider>
                                    <Grid mt={2} container
                                        spacing={{ xs: 2, md: 2 }}
                                        columns={{ xs: 4, sm: 6, md: 12 }}
                                        sx={{ flexGrow: 1 }}
                                    >
                                        <Grid xs={6} md={6} key={0}>
                                            <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                                                <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{"Gender"}</FormLabel>
                                                <Autocomplete
                                                    startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{"Gender"}</Chip>}
                                                    options={[
                                                        "Mr", "Ms", "Mrs"
                                                    ]}
                                                    isOptionEqualToValue={(opt, val) => {
                                                        setGender(val);
                                                        return true;
                                                    }}
                                                ></Autocomplete>
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={6} md={6} key={1}>
                                            <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                                                <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{"First Name"}</FormLabel>
                                                <Input
                                                    startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{"First Name"}</Chip>}
                                                    defaultValue={selectedProfile.givenName}
                                                    onInput={e => {
                                                        if ((e.target as any).value.length > 0) {
                                                            setFirstName((e.target as any).value);
                                                        } else {
                                                            setFirstName(selectedProfile.givenName);
                                                        }
                                                    }}
                                                ></Input>
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={6} md={6} key={2}>
                                            <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                                                <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{"Last Name"}</FormLabel>
                                                <Input
                                                    startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{"Last Name"}</Chip>}
                                                    defaultValue={selectedProfile.surname}
                                                    onInput={e => {
                                                        if ((e.target as any).value.length > 0) {
                                                            setLastName((e.target as any).value);
                                                        } else {
                                                            setLastName(selectedProfile.surname);
                                                        }
                                                    }}
                                                ></Input>
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={6} md={6} key={3}>
                                            <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                                                <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{"Middle Name"}</FormLabel>
                                                <Input
                                                    startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{"Middle Name"}</Chip>}
                                                    defaultValue={""}
                                                    onInput={e => {
                                                        if ((e.target as any).value.length > 0) {
                                                            setMiddleName((e.target as any).value);
                                                        } else {
                                                            setMiddleName("");
                                                        }
                                                    }}
                                                ></Input>
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={6} md={6} key={4}>
                                            <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                                                <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{"Trip Reason"}</FormLabel>
                                                <Autocomplete
                                                    startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{"Trip Reason"}</Chip>}
                                                    defaultValue={{
                                                        label: "Business",
                                                        value: 5311
                                                    }}
                                                    isOptionEqualToValue={(opt, val) => {
                                                        setReason(val.code || 5311);
                                                        return true;
                                                    }}
                                                    renderOption={(props, option) => (
                                                        <AutocompleteOption {...props}>
                                                            <ListItemDecorator>
                                                                <Flight />
                                                            </ListItemDecorator>
                                                            <ListItemContent sx={{ fontSize: 'sm' }}>
                                                                {option.label}
                                                                <Typography level="body-xs">
                                                                    {option.code}
                                                                </Typography>
                                                            </ListItemContent>
                                                        </AutocompleteOption>
                                                    )}
                                                    options={[
                                                        {
                                                            label: "Agent incentive",
                                                            code: 7821
                                                        },
                                                        {
                                                            label: "Business",
                                                            code: 5311
                                                        },
                                                        {
                                                            label: "External Consultant",
                                                            code: 6599
                                                        },
                                                        {
                                                            label: "Recruitment - Field Staff",
                                                            code: 4396
                                                        },
                                                        {
                                                            label: "Recruitment - Staff",
                                                            code: 5913
                                                        },
                                                        {
                                                            label: "Relocation",
                                                            code: 5912
                                                        }
                                                    ]}
                                                ></Autocomplete>
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={6} md={6} key={5}>
                                            <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                                                <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{"Ending"}</FormLabel>
                                                <Autocomplete
                                                    startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{"Ending"}</Chip>}
                                                    defaultValue={"SB"}
                                                    isOptionEqualToValue={(opt, val) => {
                                                        setEnding(val);
                                                        return true;
                                                    }}
                                                    options={[
                                                        "SB", "SP", "TR"
                                                    ]}
                                                ></Autocomplete>
                                            </FormControl>
                                        </Grid>

                                        <Grid xs={6} md={6} key={6}>
                                            <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                                                <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{"Kick-Off"}</FormLabel>
                                                <Autocomplete
                                                    startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{"Kick-Off"}</Chip>}
                                                    isOptionEqualToValue={(opt, val) => {
                                                        setKickoff(val);
                                                        return true;
                                                    }}
                                                    options={[
                                                        "ADYEN", "ACADSTAFF", "SUMMIT24"
                                                    ]}
                                                ></Autocomplete>
                                            </FormControl>
                                        </Grid>

                                        <Grid xs={6} md={6} key={7}>
                                            <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                                                <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{"Session and Emergency office"}</FormLabel>
                                                <Autocomplete
                                                    startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{"Session and Emergency office"}</Chip>}
                                                    defaultValue={{
                                                        label: "BOS",
                                                        office: "BOS",
                                                        phone: "+1 877 638 5538"
                                                    }}
                                                    renderOption={(props, option) => (
                                                        <AutocompleteOption {...props}>
                                                            <ListItemDecorator>
                                                                <BusinessCenter />
                                                            </ListItemDecorator>
                                                            <ListItemContent sx={{ fontSize: 'sm' }}>
                                                                {option.label}
                                                                <Typography level="body-xs">
                                                                    {option.office} ({option.phone})
                                                                </Typography>
                                                            </ListItemContent>
                                                        </AutocompleteOption>
                                                    )}
                                                    isOptionEqualToValue={(opt, val) => {
                                                        setEmergency(val);
                                                        return true;
                                                    }}
                                                    options={[
                                                        {
                                                            label: "BOS",
                                                            office: "BOS",
                                                            phone: "+1 877 638 5538"
                                                        },
                                                        {
                                                            label: "QLJ",
                                                            office: "QLJ",
                                                            phone: "+41 079 881 5031"
                                                        },
                                                        {
                                                            label: "YYZ",
                                                            office: "BOS",
                                                            phone: "+1 877 638 5538"
                                                        },
                                                        {
                                                            label: "HKG",
                                                            office: "HKG",
                                                            phone: "+852 5726 8110"
                                                        },
                                                        {
                                                            label: "SHA",
                                                            office: "SHA",
                                                            phone: "+86 136 7169 1940"
                                                        }
                                                    ]}
                                                ></Autocomplete>
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={6} md={6} key={8}>
                                            <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                                                <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{"Kick-Off"}</FormLabel>
                                                <Input
                                                    startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{"Date of Birth"}</Chip>}
                                                    type='date'
                                                    onInput={e => setDOB((e.target as any).value)}
                                                >
                                                </Input>
                                            </FormControl>
                                        </Grid>

                                        <Grid xs={6} md={6} key={9}>
                                            <Box sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' }, display: "inline-block" }}>
                                                <Box sx={{ display: 'flex', gap: 1, p: 1 }}>
                                                    <Checkbox checked={manager} label="Manager" onChange={() => setManager(!manager)} />
                                                    <Checkbox checked={freeFlight} label="Free Flight" onChange={() => setFreeFlight(!freeFlight)} />
                                                    <Checkbox checked={semicolon} label="Semicolon" onChange={() => setSemicolon(!semicolon)} defaultChecked />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {/* <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                                        <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{"Comments"}</FormLabel>
                                        <Textarea
                                            minRows={4}
                                            startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{"Comments"}</Chip>}
                                        ></Textarea>
                                    </FormControl> */}
                                    <Divider role="presentation" sx={{ gridColumn: "1/-1" }}>
                                        <Chip size='lg' startDecorator={<Assessment />}>
                                            Output
                                        </Chip>
                                    </Divider>
                                    <Grid xs={6} md={6} key={10}>
                                        <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' }, display: "inline-block" }}>
                                            <RadioGroup orientation="horizontal" defaultValue="amadeus" name="radio-buttons-group">
                                                <Radio value="amadeus" label="Amadeus" />
                                                <Radio value="sabre" disabled label="Sabre" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}>
                                        <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{"Output"}</FormLabel>
                                        <Textarea
                                            readOnly
                                            minRows={8}
                                            value={amadeus}
                                            startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{"Output"}</Chip>}
                                        ></Textarea>
                                    </FormControl>
                                </Box>
                            }
                        </TabPanel>
                    </Box>
                </Tabs>
            </Box > :
            <Box
                sx={{
                    flex: 1,
                    pl: 2,
                    pr: 2,
                    pt: 0,
                    maxWidth: 1200,
                    width: '100%',
                    mx: 'auto',
                }}>
                <Header icon={<DoDisturb />} title={'Access Denied'}></Header>
                <Typography mb={2}>
                    We are sorry, but you do not have access to this resource.
                </Typography>
                <IconButton
                    sx={{
                        display: {
                            xs: "block",
                            sm: "block",
                            md: "none",
                            lg: "none",
                        },
                        position: "absolute",
                        top: 0,
                        right: 0,
                        pt: 0.5,
                        m: 2,
                        zIndex: 5
                    }}
                    onClick={() => toggleSidebar()}
                    color="neutral"
                >
                    <MoreVert sx={{ color: "white" }} />
                </IconButton>

            </Box>
    )
}