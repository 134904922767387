
import { AccountInfo, AccountType } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';

import { SnackbarOrigin } from "@mui/joy";

export type Labels = "Product" | "Product Market" | "Company" | "Sales Market" | "Cost Center" | "Office Location" | "Department" | "Manager" | string;

export interface FormInputProps {
    label: Labels;
    name: string;
    value: string;
    isLocked: boolean;
    type: "list" | "free" | "tel" | "email" | "modal";
    userProfile?: AccountInfo;
}

export interface SiteListItem {
    displayName: string;
    country: string;
    countryCode: string;
    officeCode: string;
}

export interface ManagerListItem {
    userPrincipalName: string;
    image: string;
    displayName: string;
    label: string;
}
export interface ProductDivisionItem {
    product: string;
    divisions: string[];
}
export interface AccountProfileProps {
    userProfile: AccountInfo | null | undefined;
    locked: boolean;
}

export interface IHResponse {
    statusCode: number;
    body: string | object;
}

export interface EmailFormProps {
    primaryDomain: string;
    secondaryEmails: string[];
    username: string;
}

export interface SnackBarState extends SnackbarOrigin {
    open: boolean;
    content: string;
    success: boolean
}

export interface FormSubmission {
    accountType: AccountType;
    primaryDomain: string;
    personalPhone: string;
    personalEmail: string;
    workPhone: string;
    workEmail: string;
    jobTitle: string;
    department: string;
    managerUpn: string;
    officeLocation: string;
    contractEnd: string;
    contractStart: string;
    hrSystem: string;
    product: string;
    productMarket: string;
    company: string;
    salesMarket: string;
    costCenter: string;
    givenName: string;
    surname: string;
    username: string;
}

export interface ProfileContent {
    work?: ProfileFormInfo[];
    finance?: ProfileFormInfo[];
    contact?: ProfileFormInfo[];
    personal?: ProfileFormInfo[];
}

export interface ProfileFormInfo {
    key: string;
    label: string;
    name: string;
    value: string | null | undefined;
    type: string;
    isLocked: boolean;
    userProfile?: AccountInfo;
}

export const dummyUser = {
    work: [
        {
            key: "Job Title",
            label: "Job Title",
            name: "jobTitle",
            value: "",
            isLocked: true,
            type: "free"
        },
        {
            key: "Department",
            label: "Department",
            name: "department",
            value: "",
            isLocked: true,
            type: "free"
        },
        {
            key: "Manager",
            label: "Manager",
            name: "managerUpn",
            value: "",
            isLocked: true,
            type: "free"
        },
        {
            key: "Office Location",
            label: "Office Location",
            name: "officeLocation",
            value: "",
            isLocked: true,
            type: "free"
        },
        {
            key: "Account Expires",
            label: "Account Expires",
            name: "contractEnd",
            value: "",
            isLocked: true,
            type: "free"
        },
        {
            key: "HR System",
            label: "HR System",
            name: "hrSystem",
            value: "",
            isLocked: true,
            type: "free"
        }
    ],
    finance: [
        {
            key: "Product",
            label: "Product",
            name: "product",
            value: "",
            isLocked: true,
            type: "free"
        },
        {
            key: "Product Market",
            label: "Product Market",
            name: "productMarket",
            value: "",
            isLocked: true,
            type: "free"
        },
        {
            key: "Company",
            label: "Company",
            name: "company",
            value: "",
            isLocked: true,
            type: "free"
        },
        {
            key: "Sales Market",
            label: "Sales Market",
            name: "salesMarket",
            value: "",
            isLocked: true,
            type: "free"
        },
        {
            key: "Cost Center",
            label: "Cost Center",
            name: "costCenter",
            value: "",
            isLocked: true,
            type: "free"
        },
        {
            key: "Cost String",
            label: "Cost String",
            name: "costString",
            value: "",
            isLocked: true,
            type: "free"
        }
    ],
    contact: [
        {
            key: "Personal Phone",
            label: "Personal Phone",
            name: "personalPhone",
            value: "",
            type: "free",
            isLocked: true,
        },
        {
            key: "Work Phone",
            label: "Work Phone",
            name: "workPhone",
            value: "",
            isLocked: true,
            type: "free"
        },
        {
            key: "Personal Email",
            label: "Personal Email",
            name: "personalEmail",
            value: "",
            isLocked: true,
            type: "free"
        },
        {
            key: "Work Email",
            label: "Work Email",
            name: "workEmail",
            value: "",
            isLocked: true,
            type: "free"
        }
    ]
}