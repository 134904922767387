import { Person2, Report, Update, VerifiedUser } from '@mui/icons-material';
import { Box, LinearProgress, List, ListItem, ModalClose, Typography } from '@mui/joy';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import * as React from 'react';
import { useHash } from 'react-use';
import { useGlobalContext } from '../../Context';
import { AccountTabsContext } from '../../Context/TabContextProvider';
import { Profile } from '../../utilities/identity';
import { IHResponse } from './constructs';

interface FormSubmittedModalProps {
    openModal: boolean;
    user: string;
    setIsOpen: (value: boolean) => void;
    isOpen: boolean;
    returnInformation?: IHResponse;
    modalType: "update" | "create";
}
let currentProgress = 0;

export default function FormSubmittedModal({ user, returnInformation, isOpen, modalType, setIsOpen }: FormSubmittedModalProps) {
    const [successful, setSuccessful] = React.useState<boolean>(false);
    const [progress, setProgress] = React.useState(0);
    const { setProfile, setDirectReports, rootUser, core } = useGlobalContext();
    const { currentTab, setCurrentTab } = React.useContext(AccountTabsContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hash, setHash] = useHash();

    React.useEffect(() => {
        setInterval(() => {
            if (currentProgress < 100) {
                currentProgress++;
                setProgress(currentProgress);
            }
        }, 15 * 20); // Change the first value to a desired number of seconds 
    }, []);

    React.useEffect(() => {
        setSuccessful(false);
        currentProgress = 0;
    }, []);

    React.useEffect(() => {
        if (returnInformation) {
            setSuccessful(returnInformation.statusCode === 200);
            currentProgress = 100;
            setProgress(currentProgress);
            if (rootUser && core) {
                Profile.reloadProfile(core, rootUser).then((profile) => {
                    Profile.reloadDirectReports(core, rootUser).then((directReport) => {
                        setProfile(profile);
                        setDirectReports(directReport);
                        setCurrentTab(currentTab);
                    });

                });
            }
        }
    }, [returnInformation]);


    const handleCreated = () => {
        if (returnInformation && returnInformation.statusCode === 200) {
            setHash("#/created");
        } else {
            setIsOpen(false);
        }
    }

    const getModalTitle = (): string => {
        switch (returnInformation?.statusCode) {
            case 200:
                return `Account Successfully ${modalType === "create" ? "Created" : "Updated"}`;
            case 400 || 409:
                return `Account failed to ${modalType}`;
            default:
                return `${modalType === "create" ? "Creating" : "Updating"} Account`;
        }
    }

    const getModalMessage = () => {
        switch (returnInformation?.statusCode) {
            case 409:
                return (
                    <Box>
                        <Typography> {(returnInformation as any).body.message}</Typography>
                        <List size='md'>
                            {(returnInformation as any).body?.accountMatches?.map((account: any) => {
                                return (
                                    <ListItem >
                                        <Person2 />
                                        {account}
                                    </ListItem>)
                            })}
                        </List>
                    </Box>
                );
            case 400:
                return returnInformation.body ? returnInformation.body : (returnInformation as any).message

            default:
                return `Account successfully ${modalType === "create" ? "created" : "updated"}`
        }
    }


    const handleCloseModal = () => {
        if (modalType === "create") {
            handleCreated();
        } else {
            setIsOpen(false);
        }
    };

    const getDialogTitleIcon = () => {
        if (progress === 100 && !successful) return <Report />;
        if (progress === 100 && successful) return <VerifiedUser />;
        return <Update />;
    };

    const getDialogContent = () => {
        if (returnInformation) {
            return <DialogContent>{getModalMessage()}</DialogContent>;
        } else {
            return (
                <DialogContent>
                    Please wait while we {modalType} '{user}'.
                </DialogContent>
            );
        }
    };

    const getModalItemColor = () => {
        if (progress === 100 && !successful) return "danger";
        if (progress === 100 && successful) return "success";
        return "primary";
    };

    return (
        <Modal open={isOpen} onClose={progress === 100 ? handleCloseModal : undefined}>
            <ModalDialog
                variant="outlined"
                role="contentinfo"
                color={getModalItemColor()}
                size='sm'
            >

                <DialogTitle sx={{ mr: 4 }}>
                    {getDialogTitleIcon()}
                    {getModalTitle()}
                </DialogTitle>
                <ModalClose color='neutral' variant='plain' disabled={currentProgress !== 100} sx={{ m: 1 }} />
                <Divider />
                <Box>{getDialogContent()}</Box>
                <Box alignContent={"center"}>
                    <LinearProgress
                        color={getModalItemColor()}
                        determinate
                        value={progress}
                    />
                </Box>
            </ModalDialog>
        </Modal>
    );
}